import RequestHandler from '../request/index'

class TarifasAPI {
  static getTarifas() {
    const { fetch, generateHeaders } = RequestHandler.sharedInstance()

    return fetch('tarifas', {
      method: 'GET',
      headers: generateHeaders({ headers: {} }),
    })
  }
  static getTarifa({ tarifaId }) {
    const { fetch, generateHeaders } = RequestHandler.sharedInstance()

    return fetch(`tarifas/${tarifaId}`, {
      method: 'GET',
      headers: generateHeaders({ headers: {} }),
    })
  }
}

export default TarifasAPI
