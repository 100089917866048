import { generatePath, Outlet, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Text } from '../../../../../../components'
import Logo from '../../../../../../components/atoms/logo/Logo'
import { Header } from '../../../../../../components/molecules/header/Header'
import { StatusBar } from '../../../../../../components/molecules/statusBar/StatusBar'
import { withDownloadModal } from '../../../../../../hooks/useDownload'
import { useStoredReport } from '../../../../../../modules/reports/hooks/useReports'
import { useTarifas } from '../../../../../../modules/tarifas/hooks/useTarifas'
import { DEVICE_SIZE } from '../../../../../../theme/theme'
import { Sidebar } from './components/sidebar/Sidebar'

const Container = styled.div`
  width: 100%;
  height: calc(100% - 24px);
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;

  @media (max-width: ${DEVICE_SIZE.TABLET}) {
    flex-direction: column;
  }
`

const Content = styled.div`
  position: relative;
  flex: 1;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  border-left: ${({ theme }) => `solid 1px ${theme.colors.ntrl_light.main}`};

  @media (max-width: ${DEVICE_SIZE.TABLET}) {
    width: 100%;
    border-left: none;
  }
`

const ContentWrapper = styled.article`
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: auto;
  width: 100%;
  overflow-x: hidden;
`

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  gap: 18px;
  width: 100%;
`

const CommentsWrapper = styled.div``

export const CompanyRegularCreationReportLayout = withDownloadModal(({ handleDownload }) => {
  const { reportId } = useParams()
  const { isLoading: isReportLoading } = useStoredReport(reportId)
  useTarifas()

  return (
    <Container>
      <Sidebar
        items={[
          {
            label: 'Información general',
            path: generatePath('/creation/:reportId/c/r/informacion-general', { reportId }),
          },
          {
            label: 'Destino del riesgo',
            path: generatePath('/creation/:reportId/c/r/destino-del-riesgo', { reportId }),
          },
          {
            label: 'Tipo de siniestro',
            path: generatePath('/creation/:reportId/c/r/tipo-de-siniestro', { reportId }),
          },
          {
            label: 'Medidas de seguridad',
            path: generatePath('/creation/:reportId/c/r/medidas-de-seguridad', { reportId }),
          },
          {
            label: 'Análisis de riesgo',
            path: generatePath('/creation/:reportId/c/r/analisis-de-riesgo', { reportId }),
          },
          {
            label: 'Responsables',
            path: generatePath('/creation/:reportId/c/r/responsables', { reportId }),
          },
          {
            label: 'Perjudicados',
            path: generatePath('/creation/:reportId/c/r/perjudicados', { reportId }),
          },
          {
            label: 'Descripción del siniestro',
            path: generatePath('/creation/:reportId/c/r/descripcion', { reportId }),
          },
          {
            label: 'Análisis de capitales',
            path: generatePath('/creation/:reportId/c/r/analisis-de-capitales', { reportId }),
          },
          {
            label: 'Valoración',
            path: generatePath('/creation/:reportId/c/r/valoracion', { reportId }),
          },
          {
            label: 'Propuesta final',
            path: generatePath('/creation/:reportId/c/r/propuesta-final', { reportId }),
          },
          {
            label: 'Anexos',
            path: generatePath('/creation/:reportId/c/r/anexos', { reportId }),
          },
          // {
          //   label: 'Factura',
          //   path: generatePath('/creation/:reportId/c/r/factura', { reportId }),
          // },
        ]}
      />

      <Content>
        <Header />
        <ContentWrapper>
          {isReportLoading ? (
            <Loader>
              <Logo />
              <Text variant="black">Sincronizando datos del informe...</Text>
            </Loader>
          ) : (
            <Outlet />
          )}
          <CommentsWrapper id="comments-content" />
        </ContentWrapper>
      </Content>
      <StatusBar />
    </Container>
  )
})
