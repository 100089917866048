import React, { memo } from 'react'
import styled from 'styled-components/macro'
import { Box } from '../../../../components/atoms/box/Box'
import { Text } from '../../../../components/atoms/text/Text'
import { DEVICE_SIZE } from '../../../../theme/theme'
import bg from './assets/background.jpg'

const Container = styled.section`
  display: flex;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white.main};
`

const LeftSide = styled(Box)`
  width: 50%;
  box-sizing: border-box;
  background: ${`url(${bg}) no-repeat center center / cover`};

  @media (max-width: ${DEVICE_SIZE.MOBILE_L}) {
    display: none;
  }
`

const RigthSide = styled.div`
  width: 50%;
  box-sizing: border-box;

  @media (max-width: ${DEVICE_SIZE.MOBILE_L}) {
    width: 100%;
  }
`

export const AuthLayout = memo(({ children, ...props }) => {
  return (
    <Container {...props}>
      <LeftSide direction="column" vAlign="center" padding="64px" gap="16px">
        <Text size="xxl" weight="600">
          Gestiona tus informes de una manera sencilla y eficaz
        </Text>

        <Text size="xl" weight="300">
          Tus informes en un único lugar
        </Text>
      </LeftSide>

      <RigthSide>{children}</RigthSide>
    </Container>
  )
})

AuthLayout.displayName = 'AuthLayout'
