import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../../../modules/auth'
import { STATUS } from '../../../modules/auth/reducer'
import { Splash } from './components/splash'

export const RequireAuth = ({ children }) => {
  const auth = useAuth()
  const location = useLocation()

  if (auth.status === STATUS.CHECKING) {
    return <Splash />
  }

  if (!auth.user) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children
}
