import React from 'react'
import { Arrow, Circle, Rect, Transformer } from 'react-konva'

const MAP_TYPE_TP_SHAPE = {
  circle: Circle,
  rect: Rect,
  arrow: Arrow,
}

const MAP_PROP_TO_SHAPE = {
  circle: ['radius'],
  rect: ['width', 'height'],
  arrow: ['points'],
}

const Y_AXIS_PROPS = new Set(['height'])

export const Shape = React.memo(({ isSelected, onSelect, onChange, ...props }) => {
  const shapeRef = React.useRef()
  const trRef = React.useRef()

  const handleTransformEnd = () => {
    // transformer is changing scale of the node
    // and NOT its width or height
    // but in the store we have only width and height
    // to match the data better we will reset scale on transform end
    const node = shapeRef.current
    const scaleX = node.scaleX()
    const scaleY = node.scaleY()

    // we will reset it back
    node.scaleX(1)
    node.scaleY(1)
    onChange({
      ...props,
      x: node.x(),
      y: node.y(),
      // set minimal value
      ...MAP_PROP_TO_SHAPE[props.type].reduce((acc, prop) => {
        const values = node[prop]()
        // Arrow case
        if (Array.isArray(values)) {
          acc[prop] = values.map((value) => value * (Y_AXIS_PROPS.has(prop) ? scaleY : scaleX))
          return acc
        }
        acc[prop] = node[prop]() * (Y_AXIS_PROPS.has(prop) ? scaleY : scaleX)
        return acc
      }, {}),
    })
  }

  React.useEffect(() => {
    if (isSelected) {
      trRef.current.nodes([shapeRef.current])
      trRef.current.getLayer().batchDraw()
    }
  }, [isSelected])

  const Component = MAP_TYPE_TP_SHAPE[props.type]
  return (
    <>
      <Component
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...props}
        draggable
        onTransformEnd={handleTransformEnd}
      />
      {isSelected && <Transformer ref={trRef} />}
    </>
  )
})
