import React from 'react'
import { AiOutlineDelete } from 'react-icons/ai'
import styled, { useTheme } from 'styled-components/macro'
import Utils from '../../../../../services/Utils'
import { VARIANT } from '../../../../../theme/theme'
import { ButtonIcon } from '../../../../atoms/buttonIcon/ButtonIcon'
import Text from '../../../../atoms/text/Text'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme?.colors?.ntrl_lighter?.main};
  padding: 24px 12px 12px;
  border-radius: 6px;
  position: relative;
  border: ${({ theme }) => `solid 1px ${theme.colors.ntrl_light.main}`};
`

const Avatar = styled(Text)`
  background-color: ${({ theme }) => theme?.colors?.primary?.main};
  border-radius: 50%;
  width: 36px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  position: absolute;
  top: -18px;
  left: 12px;
`

const Date = styled(Text)`
  font-style: italic;
  align-self: flex-end;
  margin-top: 6px;
`

const Actions = styled.div`
  position: absolute;
  top: -13px;
  right: 12px;
  transition: opacity 0.3s ease;
  opacity: 0;

  ${Container}:hover & {
    opacity: 1;
  }
`

const Action = styled(ButtonIcon)`
  width: 26px;
  height: 26px;
  border-radius: 6px;
`

export const Comment = React.memo(({ comment, onRemove }) => {
  const theme = useTheme()

  return (
    <Container>
      <Avatar>{comment.user}</Avatar>
      <Text size="sm" variant="black" truncate>
        {comment.content}
      </Text>

      <Date size="s" variant="gray">
        {Utils.getTimeAgo(comment.timestamp)}
      </Date>

      <Actions>
        <Action
          variant={VARIANT.NEGATIVE}
          bordered={false}
          icon={<AiOutlineDelete size={16} color={theme.colors.white.main} />}
          onClick={onRemove}
        />
      </Actions>
    </Container>
  )
})
