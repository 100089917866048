import React from 'react'
import styled from 'styled-components/macro'
import { ExternalLink, Text } from '../../../../components'
import { Headline } from '../components/headline'
import { useParams } from 'react-router-dom'

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Content = styled.div`
  max-width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 18px;
`

export const ClosedReportScreen = React.memo(() => {
  const { reportId } = useParams()
  return (
    <Container>
      <Content>
        <Headline size="l" weight="500" variant="black">
          ¡Un momento!
        </Headline>
        <Text variant="black" align="center">
          Este expediente está cerrado y la pericial no se hizo utilizando esta plataforma del formulario. Si el informe
          pericial se elaboró por otro medio es posible que no dispongamos de la información necesaria en esta
          plataforma del formulario
        </Text>

        <Text variant="black" align="center">
          Si crees que es un error, por favor, contacta con el soporte técnico.
        </Text>

        <ExternalLink
          href={`mailto:soporte@ferimer.es?subject=${encodeURI(`SOPORTE - Expediente ${reportId}`)}`}
          target="_blank"
        >
          soporte@ferimer.es
        </ExternalLink>
      </Content>
    </Container>
  )
})
