import React from 'react'
import { AiOutlineCheck, AiOutlineDelete, AiOutlineStop } from 'react-icons/ai'
import { BiCircle, BiRectangle, BiUpArrowAlt } from 'react-icons/bi'
import { BsBorderWidth } from 'react-icons/bs'
import styled, { css, useTheme } from 'styled-components/macro'
import { ButtonIcon } from '../../../../atoms/buttonIcon/ButtonIcon'
import { Input } from '../../../../atoms/input/Input'
import Text from '../../../../atoms/text/Text'
import { BRUSHES, COLORS, useImageEditor } from '../../context'

const Container = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme?.colors?.white?.main};
  padding: 0 12px;
  gap: 12px;
  box-sizing: border-box;
`

const Box = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  height: 100%;
`

const Separator = styled.span`
  border-left: solid 1px ${({ theme }) => theme?.colors?.ntrl_light?.main};
  height: 100%;
`

const StyledButtonIcon = styled(ButtonIcon)`
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme?.colors?.primary?.main : theme?.colors?.ntrl_lighter?.main};

  &:not([disabled]):hover,
  &:not([disabled]):focus,
  &:not([disabled]):active {
    background-color: ${({ theme, isSelected }) =>
      isSelected ? theme?.colors?.primary?.main : theme?.colors?.ntrl_light?.main};
  }
`

const StyledInput = styled(Input)`
  width: 60px;

  input {
    padding-left: 6px;
    padding-right: 3px;
  }
`

const Colors = styled(Box)`
  gap: 12px;
  padding: 0 12px;
`

const Color = styled.button`
  all: unset;
  width: 28px;
  height: 28px;
  transform: scale(1);
  transition: transform 100ms ease 0s;

  background: transparent;
  cursor: pointer;
  position: relative;
  outline: none;
  border-radius: 50%;
  box-shadow: ${({ color }) => `${color} 0px 0px 0px 15px inset`};
  transition: box-shadow 100ms ease 0s, box-shadow 100ms ease 0s;

  &:active,
  &:focus {
    box-shadow: ${({ color }) => `${color} 0px 0px 0px 15px inset, ${color} 0px 0px 5px`};
  }

  &:hover {
    transform: scale(1.2);
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      box-shadow: ${({ color }) => `${color} 0px 0px 0px 3px inset`};

      &:active,
      &:focus {
        box-shadow: ${({ color }) => `${color} 0px 0px 0px 3px inset, ${color} 0px 0px 5px`};
      }
    `}
`

const Actions = styled(Box)`
  flex: 1;
  justify-content: flex-end;
`

const ConfirmButton = styled(ButtonIcon)`
  background-color: ${({ theme }) => theme?.colors?.positive?.main};

  &:not([disabled]):hover,
  &:not([disabled]):focus,
  &:not([disabled]):active {
    background-color: ${({ theme }) => theme?.colors?.positive?.main};
    opacity: 0.6;
  }
`

const CancelButton = styled(ButtonIcon)`
  background-color: ${({ theme }) => theme?.colors?.negative?.main};

  &:not([disabled]):hover,
  &:not([disabled]):focus,
  &:not([disabled]):active {
    background-color: ${({ theme }) => theme?.colors?.negative?.main};
    opacity: 0.6;
  }
`

export const Toolbar = React.memo(({ headline, onColorSelected, onRemoveShape, onCancel, onConfirm }) => {
  const theme = useTheme()
  const [{ selectedId, selectedBrush, selectedColor, selectedStrokeWidth }, dispatch] = useImageEditor()
  const isShapeSelected = !!selectedId

  const handleSelectCircle = () => {
    const nextBrush = selectedBrush === BRUSHES.CIRCLE ? null : BRUSHES.CIRCLE
    dispatch({
      type: 'SET_SELECTED_BRUSH',
      payload: {
        selectedBrush: nextBrush,
      },
    })
  }

  const handleSelectRectangle = () => {
    const nextBrush = selectedBrush === BRUSHES.RECTANGLE ? null : BRUSHES.RECTANGLE
    dispatch({
      type: 'SET_SELECTED_BRUSH',
      payload: {
        selectedBrush: nextBrush,
      },
    })
  }

  const handleSelectArrow = () => {
    const nextBrush = selectedBrush === BRUSHES.ARROW ? null : BRUSHES.ARROW
    dispatch({
      type: 'SET_SELECTED_BRUSH',
      payload: {
        selectedBrush: nextBrush,
      },
    })
  }

  const handleSelectStrokeWidth = (evt) => {
    let sanitizedValue = Number(evt.target.value)
    if (isNaN(sanitizedValue)) {
      sanitizedValue = 3
    }

    if (sanitizedValue < 0) {
      sanitizedValue = 3
    }
    if (selectedId) {
      dispatch({
        type: 'UPDATE_SHAPE',
        payload: {
          id: selectedId,
          props: {
            strokeWidth: sanitizedValue,
          },
        },
      })
    }
    dispatch({
      type: 'SET_SELECTED_STROKE_WIDTH',
      payload: {
        selectedStrokeWidth: sanitizedValue,
      },
    })
  }

  const handleColorSelected = (color) => {
    if (selectedId) {
      dispatch({
        type: 'UPDATE_SHAPE',
        payload: {
          id: selectedId,
          props: {
            stroke: color,
          },
        },
      })
    }
    dispatch({
      type: 'SET_SELECTED_COLOR',
      payload: {
        selectedColor: color,
      },
    })
  }

  return (
    <Container>
      <Text variant="black">{headline}</Text>
      <Separator />
      <Box>
        <StyledButtonIcon
          icon={
            <BiCircle
              size={24}
              color={selectedBrush === BRUSHES.CIRCLE ? theme?.colors.white?.main : theme?.colors.ntrl_dark?.main}
            />
          }
          tabIndex={-1}
          aria-label="Circle"
          title="Circle"
          isSelected={selectedBrush === BRUSHES.CIRCLE}
          onClick={handleSelectCircle}
        />

        <StyledButtonIcon
          icon={
            <BiRectangle
              size={24}
              color={selectedBrush === BRUSHES.RECTANGLE ? theme?.colors.white?.main : theme?.colors.ntrl_dark?.main}
            />
          }
          tabIndex={-1}
          aria-label="Rectangle"
          title="Rectangle"
          isSelected={selectedBrush === BRUSHES.RECTANGLE}
          onClick={handleSelectRectangle}
        />

        <StyledButtonIcon
          icon={
            <BiUpArrowAlt
              size={24}
              color={selectedBrush === BRUSHES.ARROW ? theme?.colors.white?.main : theme?.colors.ntrl_dark?.main}
            />
          }
          tabIndex={-1}
          aria-label="Rectangle"
          title="Rectangle"
          isSelected={selectedBrush === BRUSHES.ARROW}
          onClick={handleSelectArrow}
        />
      </Box>
      <Separator />
      <Box>
        <BsBorderWidth size={20} color={theme?.colors.ntrl_dark?.main} />
        <StyledInput type="number" step={0.5} value={selectedStrokeWidth} onChange={handleSelectStrokeWidth} />
        <Colors>
          {COLORS.map((color) => {
            return (
              <Color
                key={color}
                color={color}
                isSelected={selectedColor === color}
                onClick={() => handleColorSelected(color)}
              />
            )
          })}
        </Colors>
      </Box>
      <Actions>
        {isShapeSelected && (
          <>
            <StyledButtonIcon
              icon={<AiOutlineDelete size={24} color={theme?.colors.ntrl_dark?.main} />}
              tabIndex={-1}
              aria-label="Delete"
              title="Delete"
              onClick={onRemoveShape}
            />
            <Separator />
          </>
        )}

        <CancelButton
          icon={<AiOutlineStop size={24} color={theme?.colors.white?.main} />}
          tabIndex={-1}
          aria-label="Cancel"
          title="Cancel"
          onClick={onCancel}
        />

        <ConfirmButton
          icon={<AiOutlineCheck size={24} color={theme?.colors.white?.main} />}
          tabIndex={-1}
          aria-label="Confirm"
          title="Confirm"
          onClick={onConfirm}
        />
      </Actions>
    </Container>
  )
})
