import { transparentize } from 'polished'
import { forwardRef, useMemo } from 'react'
import RSelect from 'react-select'
import AsyncSelect from 'react-select/async'
import styled, { css } from 'styled-components/macro'
import { Box } from '../box/Box'
import { Label } from '../label/Label'

const Container = styled(Box)`
  position: relative;
`

const StyledSelect = styled(RSelect)`
  height: 48px;
  width: 100%;

  & .select__control {
    height: 100%;
    background-color: ${({ theme }) => theme?.colors?.white?.main};
    border: solid 1px ${({ theme }) => theme?.colors?.ntrl_light?.main};
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
  }

  & .select__control.select__control--is-focused {
    border-color: ${({ theme }) => theme?.colors?.black?.main};
    box-shadow: none;
  }

  & .select__control.select__control--menu-is-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  & .select__menu {
    margin: 0;
  }

  & .select__menu-list {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-sizing: border-box;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    padding: 0;
    margin: 0;
    max-height: 150px;
    overflow: auto;

    ${({ theme }) => css`
      border: solid 1px ${theme?.colors?.black?.main};
      border-top: 0;
      background: ${({ theme }) => theme?.colors?.white?.main};
    `};
  }

  & .select__option {
    height: 48px;
    padding: 14px 16px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    transition: background-color 0.3s ease;
    cursor: pointer;
    background-color: ${({ theme }) => transparentize(0.8, theme.colors.white.main)};

    &:hover,
    &:focus,
    &:active {
      background-color: ${({ theme }) => transparentize(0.8, theme.colors.ntrl_light.main)};
    }

    &.select__option--is-selected {
      background-color: ${({ theme }) => transparentize(0.8, theme.colors.ntrl_light.main)};
      font-weight: 600;
      color: initial;
    }
  }
`

export const Select = forwardRef(
  (
    { className, label, id, name, control, options, onChange, value, placeholder = 'Elige...', async = false, ...rest },
    ref,
  ) => {
    const handleChange = (option) => {
      onChange?.(option.value)
    }

    const selectedOption = useMemo(() => {
      return options?.find((option) => option.value === value)
    }, [options, value])

    return (
      <Container direction="column" className={className}>
        {label && <Label htmlFor={id ?? name}>{label}</Label>}
        <StyledSelect
          as={async ? AsyncSelect : RSelect}
          innerRef={ref}
          className="basic-single"
          classNamePrefix="select"
          name={name}
          options={options}
          value={selectedOption}
          placeholder={placeholder}
          {...rest}
          onChange={handleChange}
        />
      </Container>
    )
  },
)
