import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { BiCommentAdd, BiCommentDetail } from 'react-icons/bi'
import { BsArrowBarRight } from 'react-icons/bs'
import { useParams } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import styled, { css, useTheme } from 'styled-components/macro'
import { useAuth } from '../../../modules/auth'
import { useStoredComments } from '../../../modules/reports/hooks/useReports'
import { useAddCommentMutation, useRemoveCommentMutation } from '../../../modules/reports/mutations'
import { VARIANT } from '../../../theme/theme'
import { ButtonIcon } from '../../atoms/buttonIcon/ButtonIcon'
import Text from '../../atoms/text/Text'
import { Comment } from './components/comment/Comment'

const enterTimeout = 500
const exitTimeout = 250
// this value is for desktop
const enterWidth = '240px'
const exitWidth = '0'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 260px;
  height: 100%;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.white.main};
  border-left: ${({ theme }) => `solid 1px ${theme.colors.ntrl_light.main}`};
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 6px 12px;
  border-bottom: ${({ theme }) => `solid 1px ${theme.colors.ntrl_light.main}`};
`

const MessageInput = styled.span`
  width: 100%;
  min-height: 48px;
  border-top: ${({ theme }) => `solid 1px ${theme.colors.ntrl_light.main}`};
  padding: 12px;
  box-sizing: border-box;
  outline: none;

  &:empty:before {
    content: attr(placeholder);
    opacity: 0.6;
  }
`

const Messages = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 18px 12px;
  gap: 30px;
  overflow: auto;
`

const StyledButtonIcon = styled(ButtonIcon)`
  position: absolute;
  bottom: 24px;
  right: 24px;
  border-radius: 50%;
  width: 48px;
  height: 48px;

  ${({ hasComments }) =>
    hasComments &&
    css`
      &:after {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: ${({ theme }) => theme.colors.warning.main};
        position: absolute;
        top: 3px;
        right: 0;
      }
    `}

  ${({ opened }) =>
    opened &&
    css`
      display: none;
    `}
`

const WrapperDetails = styled.div`
  position: static;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  flex-shrink: 0;
  width: 0;
  transform: translateX(100%);
  height: 100%;

  &.wrapper-details-enter {
    transform: translateX(100%);
  }

  &.wrapper-details-enter-active {
    transform: translateX(0%);
    transition: transform ${enterTimeout}ms ease-in;
  }

  &.wrapper-details-enter-done {
    transform: translateX(0%);
  }

  &.wrapper-details-exit {
    transform: translateX(0%);
  }

  &.wrapper-details-exit-active {
    transform: translateX(100%);
    transition: transform ${exitTimeout}ms ease-out;
  }

  &.wrapper-details-exit-done {
    transform: translateX(100%);
  }

  ${Content} {
    width: ${enterWidth};
  }

  &.wrapper-details-enter {
    transform: unset;
    width: ${exitWidth};
  }

  &.wrapper-details-enter-active {
    transform: unset;
    width: ${enterWidth};
    transition: width ${enterTimeout}ms ease-in;
  }

  &.wrapper-details-enter-done {
    transform: unset;
    width: ${enterWidth};
  }

  &.wrapper-details-exit {
    transform: unset;
    width: ${enterWidth};
  }

  &.wrapper-details-exit-active {
    transform: unset;
    width: ${exitWidth};
    transition: width ${exitTimeout}ms ease-out;
  }

  &.wrapper-details-exit-done {
    transform: unset;
    width: ${exitWidth};
  }
`

const Sidebar = ({ opened, children }) => {
  return (
    <CSSTransition
      in={opened}
      timeout={{
        enter: enterTimeout,
        exit: exitTimeout,
      }}
      classNames={{
        enter: 'wrapper-details-enter',
        enterActive: 'wrapper-details-enter-active',
        enterDone: 'wrapper-details-enter-done',
        exit: 'wrapper-details-exit',
        exitActive: 'wrapper-details-exit-active',
        exitDone: 'wrapper-details-exit-done',
      }}
    >
      <WrapperDetails>{children}</WrapperDetails>
    </CSSTransition>
  )
}

export const Comments = React.memo(({ step, ...rest }) => {
  const theme = useTheme()
  const { reportId } = useParams()
  const { data: comments } = useStoredComments(reportId)
  const { user } = useAuth()
  const [opened, setOpened] = useState(false)
  const hasComments = comments?.[step]?.length > 0
  const Icon = hasComments ? BiCommentDetail : BiCommentAdd
  const [, setTimestamp] = useState(Date.now())
  const timestampInterval = useRef()
  const list = useRef()
  const [mounted, setMounted] = useState(false)

  const addCommentMutation = useAddCommentMutation()
  const removeCommentMutation = useRemoveCommentMutation()

  const toggleCommentsPanel = () => {
    setOpened((prev) => !prev)
  }

  const handleKeyPress = (evt) => {
    if (evt.keyCode === 13) {
      console.log('Enter key is pressed')
      evt.preventDefault()
      evt.stopPropagation()
      if (!String(evt.target.innerHTML ?? '')) {
        return
      }

      const commentsCopy = structuredClone(comments ?? {})
      addCommentMutation.mutate({
        reportId,
        step,
        comments: commentsCopy,
        comment: {
          status: 'PENDING',
          content: String(evt.target.innerHTML ?? ''),
          user: (user.nombre.charAt(0) ?? 'U').toUpperCase() + (user.apellidos.charAt(0) ?? 'U').toUpperCase(),
          timestamp: Date.now(),
        },
      })
      // addComment({
      //   step,
      //   comment: {
      //     status: 'PENDING',
      //     content: String(evt.target.innerHTML ?? ''),
      //     user: (user.nombre.charAt(0) ?? 'U').toUpperCase() + (user.apellidos.charAt(0) ?? 'U').toUpperCase(),
      //     timestamp: Date.now(),
      //   },
      // })
      evt.target.innerHTML = ''
    }
  }

  useEffect(() => {
    if (timestampInterval.current) {
      clearInterval(timestampInterval.current)
    }

    timestampInterval.current = setInterval(() => {
      setTimestamp(Date.now())
    }, 30000)

    return () => clearInterval(timestampInterval.current)
  }, [])

  const stepComments = comments?.[step] ?? []

  useEffect(() => {
    list.current?.scroll({ top: list.current?.scrollHeight })
  }, [stepComments?.length])

  useLayoutEffect(() => {
    setMounted(true)
  }, [])

  return (
    <>
      <StyledButtonIcon
        bordered
        variant={VARIANT.GRAY}
        icon={<Icon size={24} color={theme.colors.gray.main} />}
        hasComments={hasComments}
        opened={opened}
        onClick={toggleCommentsPanel}
      />
      {mounted &&
        ReactDOM.createPortal(
          <Sidebar opened={opened}>
            <Content>
              <Header>
                <Text size="m" weight="500" variant="black">
                  Comentarios
                </Text>

                <ButtonIcon
                  variant={VARIANT.TRANSPARENT}
                  icon={<BsArrowBarRight size={24} color={theme.colors.black.main} />}
                  onClick={toggleCommentsPanel}
                />
              </Header>
              <Messages ref={list}>
                {stepComments.map((comment, idx) => {
                  if (!comment) {
                    return null
                  }
                  const handleRemove = () => {
                    removeCommentMutation.mutate({
                      reportId,
                      step,
                      comments: structuredClone(comments),
                      commentIdx: idx,
                    })
                  }
                  return <Comment key={`${comment?.timestamp}`} comment={comment} onRemove={handleRemove} />
                })}
              </Messages>
              <MessageInput contentEditable placeholder="Escribe aquí tu comentario" onKeyDown={handleKeyPress} />
            </Content>
          </Sidebar>,
          document.getElementById('comments-content'),
        )}
    </>
  )
})
