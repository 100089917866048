import styled from 'styled-components/macro'
import { Text } from '../../../../../components'

export const Headline = styled(Text)`
  padding-top: 12px;
  position: relative;
  font-family: 'Karla';
  letter-spacing: 0.8px;
  &:before {
    content: '';
    width: 48px;
    height: 4px;
    background: ${({ theme }) => theme.colors.primary.main};
    position: absolute;
    top: 0;
    left: 0;
  }
`
