import { fetchTarifa, fetchTarifas } from '../../queries'
import { useQuery } from '@tanstack/react-query'

export const useTarifas = (options) => {
  return useQuery(['tarifas'], () => fetchTarifas(), options)
}

export const useTarifa = (tarifaId, options) => {
  return useQuery(['tarifas', tarifaId], () => fetchTarifa(tarifaId), options)
}
