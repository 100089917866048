import React from 'react'
import styled, { css } from 'styled-components'

export const Logo = styled.h1.attrs(() => ({
  children: 'Montes Peritos',
}))`
  display: inline-block;
  margin: 0;
  padding: 0;
  font-weight: 700;
  line-height: 32px;

  ${({ theme, variant = 'primary' }) => css`
    color: ${theme.colors[variant].main};
  `};

  ${({ theme, size = 'm' }) => css(theme.components?.logo?.sizes?.[size] ?? '')}
`

export default React.memo(Logo)
