import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Input, Radio, Text } from '../../../../../../components'
import { CheckboxGroup } from '../../../../../../components/molecules/checkboxGroup/CheckboxGroup'
import { Comments } from '../../../../../../components/molecules/comments/Comments'
import { useAutoSave } from '../../../../../../hooks/useAutoSave'
import { useIsClosed, useSaveReport, useStoredReport } from '../../../../../../modules/reports/hooks/useReports'
import { showBlockedReportdWarning } from '../../../../../../modules/reports/utils'
import { Headline } from '../../../components/headline'
import Actions from '../../components/actions/Actions'

const Container = styled.form`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const StyledInput = styled(Input)`
  width: 260px;
`

const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Inputs = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const STEP = 'analisisDeRiesgo'
export const AnalisisDeRiesgoScreen = () => {
  const navigate = useNavigate()
  const { reportId } = useParams()
  const isClosed = useIsClosed(reportId)
  const { data: report } = useStoredReport(reportId)

  const {
    register,
    watch,
    getValues,
    resetField,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      estadoInstalaciones: report?.estadoInstalaciones ?? '',

      ubicacion: report?.ubicacion ?? '',

      caracteristicasRiesgo_antiguedad: report?.caracteristicasRiesgo_antiguedad ?? '',
      caracteristicasRiesgo_metros: report?.caracteristicasRiesgo_metros ?? '',
      caracteristicasRiesgo_coeficienteParticipacion: report?.caracteristicasRiesgo_coeficienteParticipacion ?? '',

      tipoRiesgo: report?.tipoRiesgo ?? '',
      tipoRiesgo_otros_especificar: report?.tipoRiesgo_otros_especificar ?? '',
    },
    mode: 'onBlur',
  })
  const watchOtros = watch('tipoRiesgo', false)
  const handleSaveReport = useSaveReport()

  const { saveData } = useAutoSave()

  const handleFormChange = useCallback(() => {
    if (isClosed) {
      showBlockedReportdWarning()
      return
    }

    saveData({ id: STEP, reportId, data: getValues() })
  }, [getValues, isClosed, reportId, saveData])

  const handleGoBack = useCallback(() => {
    handleSaveReport({ data: getValues() })
    navigate(-1)
  }, [handleSaveReport, getValues, navigate])

  const onSubmit = (values) => {
    if (isClosed) {
      navigate(generatePath('/creation/:reportId/c/r/responsables', { reportId }))
      return
    }

    return new Promise((resolve, reject) => {
      handleSaveReport({
        data: values,
        onSuccess: () => {
          resolve()
          navigate(generatePath('/creation/:reportId/c/r/responsables', { reportId }))
        },
        onError: () => {
          reject()
        },
      })
    })
  }

  useEffect(() => {
    if (watchOtros !== 'otros') {
      resetField('tipoRiesgo_otros_especificar', { defaultValue: '' })
    }
  }, [watchOtros, resetField])

  useEffect(() => {
    return () => {
      console.info('Unmounting AnalisisDeRiesgoScreen')
    }
  }, [getValues])

  return (
    <Container onChange={handleFormChange} onSubmit={handleSubmit(onSubmit)}>
      <Header>
        <Headline size="l" weight="500" variant="black">
          Análisis de riesgo
        </Headline>

        <Text variant="black">Vamos a analizar el riesgo del informe.</Text>
      </Header>

      <Block>
        <Text weight="500" size="sm" variant="black">
          Estado de las instalaciones
        </Text>

        <CheckboxGroup>
          <Radio value="bueno" {...register('estadoInstalaciones')}>
            Bueno
          </Radio>

          <Radio value="regular" {...register('estadoInstalaciones')}>
            Regular
          </Radio>

          <Radio value="malo" {...register('estadoInstalaciones')}>
            Malo
          </Radio>
        </CheckboxGroup>
      </Block>

      <Inputs>
        <StyledInput
          {...register('caracteristicasRiesgo_antiguedad')}
          label="Antigüedad"
          placeholder="Escribe aquí"
          type="text"
        />

        <StyledInput
          {...register('caracteristicasRiesgo_metros')}
          label="Metros"
          placeholder="Escribe aquí"
          type="number"
        />

        <StyledInput
          {...register('caracteristicasRiesgo_coeficienteParticipacion')}
          label="Coeficiente participación"
          placeholder="Escribe aquí"
          type="number"
        />
      </Inputs>

      <Block>
        <Text weight="500" size="sm" variant="black">
          Ubicación
        </Text>

        <CheckboxGroup>
          <Radio value="cascoUrbano" {...register('ubicacion')}>
            Casco urbano
          </Radio>

          <Radio value="rural" {...register('ubicacion')}>
            Rural
          </Radio>

          <Radio value="zonaDesploblada" {...register('ubicacion')}>
            Zona despoblada
          </Radio>
        </CheckboxGroup>
      </Block>

      <Block>
        <Text weight="500" size="sm" variant="black">
          Tipo de riesgo
        </Text>

        <CheckboxGroup>
          <Radio value="construccion" {...register('tipoRiesgo')}>
            Construcción
          </Radio>

          <Radio value="naveIndustrial" {...register('tipoRiesgo')}>
            Nave Industrial
          </Radio>

          <Radio value="comercio" {...register('tipoRiesgo')}>
            Comercio
          </Radio>

          <Radio value="responsabilidadCivil" {...register('tipoRiesgo')}>
            Responsabilidad civil
          </Radio>

          <Radio value="otros" {...register('tipoRiesgo')}>
            Otros
          </Radio>
        </CheckboxGroup>
      </Block>

      {watchOtros === 'otros' && (
        <Input
          {...register('tipoRiesgo_otros_especificar')}
          label="Especificar"
          placeholder="Escribe aquí"
          type="text"
        />
      )}

      <Actions isSubmitting={isSubmitting} onBackClick={handleGoBack} />

      <Comments step={STEP} />
    </Container>
  )
}
