import React from 'react'
import styled from 'styled-components/macro'
import Text from '../text/Text'

const Container = styled.div`
  display: flex;
  align-items: center;
  border-radius: 6px;
  width: 100%;
  background-color: ${({ theme }) => theme?.colors?.white?.main};
  padding: 12px;
  box-sizing: border-box;

  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  &:before {
    content: '';
    display: block;
    height: 100%;
    width: 4px;
    border-radius: 4px;
    background-color: ${({ theme, variant }) => theme.colors[variant]?.main ?? theme.colors.ntrl_darkest.main};
    margin-right: 18px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Alert = React.memo(({ headline, body, ...rest }) => {
  return (
    <Container {...rest}>
      <Content>
        {headline && (
          <Text size="m" weight="500" variant="black">
            {headline}
          </Text>
        )}
        {body && (
          <Text size="sm" variant="dark">
            {body}
          </Text>
        )}
      </Content>
    </Container>
  )
})
