import React from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { AiOutlineDelete } from 'react-icons/ai'
import { BiBookAdd } from 'react-icons/bi'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled, { useTheme } from 'styled-components/macro'
import { Button, Input, Text } from '../../../../components'
import { useAutoSave } from '../../../../hooks/useAutoSave'
import { useIsClosed, useReportCapitales } from '../../../../modules/reports/hooks/useReports'
import { useDeleteCapital, useUpdateCapitales } from '../../../../modules/reports/mutations'
import { showBlockedReportdWarning } from '../../../../modules/reports/utils'
import { Headline } from '../components/headline'

const Container = styled.form`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const Inputs = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
`

const StyledInput = styled(Input)`
  width: 260px;
`

const StyledLargeInput = styled(Input)`
  width: 400px;
`

const AddButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 0;
  height: initial;
  color: ${({ theme }) => theme.colors.secondary.main};
  gap: 6px;
  font-weight: 400;
`

const RemoveButton = styled(Button)`
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: center;
  padding: 0;
  color: ${({ theme }) => theme.colors.negative.main};
  gap: 6px;
  font-weight: 400;
  width: 48px;
`

const Actions = styled.nav`
  margin-top: 24px;
  display: flex;
  gap: 12px;
`

const STEP = 'editCapitales'
export const EditCapitalesScreen = React.memo(() => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { reportId } = useParams()
  const { data: capitalesAsegurados } = useReportCapitales(reportId)
  const { register, control, handleSubmit, formState, getValues } = useForm({
    defaultValues: {
      capitales: Object.keys(capitalesAsegurados ?? {}).map((key) => {
        return {
          id: key,
          value: capitalesAsegurados[key],
          oldId: key,
        }
      }),
    },
    mode: 'onBlur',
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'capitales',
  })

  const isClosed = useIsClosed(reportId)
  const { saveData } = useAutoSave()
  const { mutate: deleteCapitalMutation } = useDeleteCapital()
  const { mutate: updateCapitalesMutation } = useUpdateCapitales()

  const getCapitales = () => {
    const capitales = getValues()?.capitales ?? []
    return capitales.reduce((acc, { id, value, oldId }, idx) => {
      acc[oldId] = {
        updatedId: id,
        value: isNaN(Number(value)) ? 0 : Number(value),
      }
      return acc
    }, {})
  }

  const handleBackClick = () => {
    navigate(-1)
  }

  const handleAddCapital = () => {
    append({ id: '', value: '', oldId: '' })
  }

  const handleRemoveCapital = (index) => {
    const values = getValues()
    const capital = values.capitales[index]
    remove(index)
    deleteCapitalMutation({
      reportId,
      capital: capital.id,
    })
  }

  const handleFormChange = () => {
    if (isClosed) {
      showBlockedReportdWarning()
      return
    }

    saveData({ id: STEP, reportId, data: getCapitales() })
  }

  const onSubmit = (values) => {
    return new Promise((resolve, reject) => {
      updateCapitalesMutation(
        {
          reportId,
          capitales: getCapitales(),
        },
        {
          onSuccess: () => {
            resolve()
            navigate(-1)
          },
          onError: () => {
            reject()
            toast.error('Oops! No hemos podido actualizar los capitales')
          },
        },
      )
    })
  }

  const { isSubmitting } = formState
  return (
    <Container onChange={handleFormChange} onSubmit={handleSubmit(onSubmit)}>
      <Header>
        <Headline size="l" weight="500" variant="black">
          Editar capitales del expediente
        </Headline>
        <Text variant="black">Aquí puedes editar los capitales que estan asignados a este expediente</Text>
      </Header>

      {fields.map((field, idx) => {
        return (
          <Inputs key={idx}>
            <StyledLargeInput
              {...register(`capitales.${idx}.id`)}
              label="Capital"
              placeholder="Escribe aquí"
              type="text"
            />

            <StyledInput
              {...register(`capitales.${idx}.value`)}
              label="Cuantía"
              placeholder="Escribe aquí"
              type="number"
            />

            <RemoveButton
              type="button"
              variant="negative"
              onClick={() => {
                handleRemoveCapital(idx)
              }}
            >
              <AiOutlineDelete size={20} color={theme?.colors?.white?.main} />
            </RemoveButton>
          </Inputs>
        )
      })}

      <AddButton type="button" variant="transparent" onClick={handleAddCapital}>
        <BiBookAdd size={20} color={theme?.colors?.secondary?.main} />
        Añadir nuevo capital
      </AddButton>

      <Actions>
        <Button bordered type="button" variant="ntrl_dark" onClick={handleBackClick} disabled={isSubmitting}>
          Atrás
        </Button>
        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? 'Guardando...' : 'Guardar'}
        </Button>
      </Actions>
    </Container>
  )
})
