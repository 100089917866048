import React from 'react'
import styled from 'styled-components'
import Text from '../../atoms/text/Text'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 120px 60px;
  box-sizing: border-box;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  max-width: 1200px;
  width: 100%;
`

export const ErrorPage = React.memo(({ headline = 'Oops!', message }) => {
  return (
    <Container>
      <Content>
        <Text variant="primary" element="h1" size="xxl" weight="600">
          Error
        </Text>

        <Text variant="ntrl_darkest">{message}</Text>
      </Content>
    </Container>
  )
})
