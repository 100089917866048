import { memo } from 'react'
import styled, { css } from 'styled-components/macro'

export const DropdownOption = memo(styled.li.attrs(() => ({
  role: 'option',
}))`
  list-style: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;

  &:last-child {
    border: 0;
  }

  ${({ theme }) => css`
    border-bottom: solid 1px ${theme.colors.ntrl_light.main};
    background: ${theme.colors.white.main};
  `};
`)
