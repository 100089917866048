import React, { forwardRef, memo } from 'react'
import styled, { css } from 'styled-components/macro'

const Checkmark = styled.input`
  box-sizing: border-box;

  appearance: none;
  background-color: #fff;
  margin: 0;

  font: inherit;
  color: ${({ theme }) => theme?.colors?.ntrl_light?.main};
  width: 20px;
  height: 20px;
  border: 2px solid ${({ theme }) => theme?.colors?.ntrl_light?.main};
  border-radius: 3px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  &:before {
    content: '';
    width: 18px;
    height: 18px;
    border-radius: 2px;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 8px 8px ${({ theme }) => theme?.colors?.primary?.main};
  }

  &:checked {
    color: ${({ theme }) => theme?.colors?.primary?.main};
    border-color: ${({ theme }) => theme?.colors?.primary?.main};
  }

  &:checked:before {
    transform: scale(1);
  }
`

const Label = styled.label`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
    `}
`

export const Checkbox = memo(
  forwardRef(({ className, id, name, value, children, disabled, ...props }, ref) => {
    return (
      <Label className={className} disabled={disabled}>
        <Checkmark ref={ref} type="checkbox" id={id} name={name} value={value} disabled={disabled} {...props} />
        {children}
      </Label>
    )
  }),
)

Checkbox.displayName = 'Checkbox'
