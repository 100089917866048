import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import Text from '../../atoms/text/Text'
import { AiOutlineSave } from 'react-icons/ai'
import { useAutoSave } from '../../../hooks/useAutoSave'
import { Link } from '../../atoms/link/Link'

const Container = styled.div`
  height: 24px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.ntrl_lighter.main};
  border-top: ${({ theme }) => `solid 1px ${theme.colors.ntrl_light.main}`};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  box-sizing: border-box;
  gap: 12px;
  z-index: 9999;
`

const Indicators = styled.div`
  display: flex;
  flex: 1;
  gap: 6px;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
`

export const StatusBar = React.memo(() => {
  const { isSaving } = useAutoSave()
  return ReactDOM.createPortal(
    <Container>
      <Link to="/version">{`v${process.env.REACT_APP_VERSION}`}</Link>

      <Indicators>
        <AiOutlineSave size={18} />
        <Text size="s" variant="dark">
          {isSaving ? 'Guardando...' : 'Guardado'}
        </Text>
      </Indicators>
    </Container>,
    document.querySelector('#root'),
  )
})
