import React from 'react'
import { HiDownload, HiOutlineRefresh } from 'react-icons/hi'
import styled, { useTheme } from 'styled-components'
import { ButtonIcon, CircleLoader, Text } from '../../../components'
import { useReportPreviewUrl } from '../../../modules/reports/hooks/useReports'
import { Headline } from '../../dashboard/creation/components/headline'
import { transparentize } from 'polished'
import { withDownloadModal } from '../../../hooks/useDownload'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Preview = styled.iframe`
  border: 0;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
`

const Content = styled.div`
  flex: 1;
  position: relative;
`

const FloatingContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  position: absolute;
  bottom: 36px;
  right: 36px;
`

const FloatingButton = styled(ButtonIcon)`
  box-shadow: ${({ theme }) => `0 2px 4px ${transparentize(0.2, theme.colors.black.main)}`};

  &:not([disabled]):hover,
  &:not([disabled]):focus,
  &:not([disabled]):active {
    box-shadow: ${({ theme }) => `0 2px 4px ${transparentize(0.4, theme.colors.black.main)}`};
  }

  &:not([disabled]):hover {
    transform: translateY(-2px);
  }
`

const LoadingReport = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white.main};
`

export const ReportPreviewScreen = withDownloadModal(({ navigate, handleDownload }) => {
  const theme = useTheme()
  const previewUrl = useReportPreviewUrl()
  const iframeRef = React.useRef()
  const [isLoadingReport, setIsLoadingReport] = React.useState(true)

  const handleReloadClick = () => {
    if (!iframeRef.current) {
      return
    }
    setIsLoadingReport(true)
    iframeRef.current.src = `${previewUrl}&t=${Date.now()}`
  }

  const handleIframeLoadEnd = () => {
    setIsLoadingReport(false)
  }

  if (!previewUrl) {
    return null
  }

  return (
    <Container>
      <Info>
        <Headline size="l" weight="500" variant="black">
          Vista previa del informe
        </Headline>
        <Text variant="black">
          Aquí puedes ver cómo se verá el informe una vez que se haya generado. Sin embargo,{' '}
          <Text as="strong" variant="black" weight="600">
            no podrás consultar los anexos
          </Text>{' '}
          hasta que el informe se haya descargado.
        </Text>
      </Info>
      <Content>
        {isLoadingReport ? (
          <LoadingReport>
            <CircleLoader variant="primary" />
            <Text variant="black" weight="600" align="center">
              Cargando informe...
            </Text>
          </LoadingReport>
        ) : null}
        <Preview ref={iframeRef} src={previewUrl} onLoad={handleIframeLoadEnd} />
      </Content>

      <FloatingContainer>
        <FloatingButton
          variant="white"
          bordered={false}
          icon={<HiOutlineRefresh color={theme.colors.black.main} size={18} />}
          onClick={handleReloadClick}
        />

        <FloatingButton
          variant="primary"
          bordered={false}
          icon={<HiDownload color={theme.colors.white.main} size={18} />}
          onClick={handleDownload}
        />
      </FloatingContainer>
    </Container>
  )
})
