import React, { memo, useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { Collapsible } from '../collapsible/Collapsible'
import { Icon } from '../icon/Icon'
import { Box } from '../box/Box'

const DropdownButton = styled.button`
  border: 0;
  outline: 0;
  background: transparent;
  cursor: pointer;
`

const CollapsibleStyled = styled(Collapsible)`
  position: absolute;
  top: 40px;
`

const Options = styled.ul`
  width: 200px;
  padding: 8px 0;
  margin: 0;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);

  ${({ theme }) => css`
    border: solid 1px ${theme.colors.ntrl_light.main};
    background: ${theme.colors.white.main};
  `};
`

export const Dropdown = memo(function Dropdown({ id, className, children, expanded: givenExpanded, align }) {
  const [{ expanded }, setState] = useState({ expanded: givenExpanded })

  const handleClick = () => {
    setState((old) => ({ ...old, expanded: !expanded }))
  }

  const handleClickOption = () => {
    setState((old) => ({ ...old, expanded: !expanded }))
  }

  useEffect(() => {
    if (givenExpanded !== expanded) {
      setState((old) => ({ ...old, expanded: givenExpanded }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [givenExpanded])

  return (
    <Box direction="column" hAlign={align}>
      <DropdownButton type="button" onClick={handleClick} aria-haspopup="listbox" aria-labelledby={id}>
        <Icon name="options" />
      </DropdownButton>
      <CollapsibleStyled
        id={id}
        className={className}
        aria-expanded={expanded}
        expanded={expanded}
        onClick={handleClickOption}
      >
        <Options role="listbox">{children}</Options>
      </CollapsibleStyled>
    </Box>
  )
})
