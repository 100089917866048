import { transparentize } from 'polished'
import React, { memo } from 'react'
import styled, { css } from 'styled-components/macro'
import { Icon, Text } from '../../../../../../../../components'

const Button = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: ${({ theme }) => theme?.colors?.white?.main};
  border: none;
  border-radius: 4px;
  width: 100%;
  min-height: 196px;
  box-sizing: border-box;
  padding: 0 24px;
  outline: none;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;

  ${({ theme }) => {
    return css`
      &:not([disabled]):hover,
      &:not([disabled]):focus,
      &:not([disabled]):active {
        opacity: 1;
        background-color: ${transparentize(0.8, theme.colors.ntrl_light.main)};
      }
    `
  }}

  ${({ theme, disabled }) => {
    return (
      disabled &&
      css`
        pointer-events: none;
        &:after {
          content: '';
          display: block;
          position: absolute;
          inset: 0;
          background-color: ${transparentize(0.3, theme.colors.white.main)};
        }
      `
    )
  }}
`

export const UploadButton = memo(({ headline = 'Haz click o arrastra para subir los ficheros', ...props }) => {
  return (
    <Button type="button" {...props}>
      <Icon name="add" size="m" />
      <Text size="m" weight="500" variant="primary" align="center">
        {headline}
      </Text>
    </Button>
  )
})
