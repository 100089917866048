import { DateTime } from 'luxon'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Input, Select, Text } from '../../../../../../components'
import { Comments } from '../../../../../../components/molecules/comments/Comments'
import { useAutoSave } from '../../../../../../hooks/useAutoSave'
import {
  useIsClosed,
  useReportById,
  useSaveReport,
  useStoredReport,
} from '../../../../../../modules/reports/hooks/useReports'
import { showBlockedReportdWarning } from '../../../../../../modules/reports/utils'
import { Headline } from '../../../components/headline'
import Actions from '../../components/actions/Actions'

const TIPO_OPTIONS = [
  { label: 'Definitivo', value: 'Definitivo' },
  { label: 'Preliminar', value: 'Preliminar' },
  { label: 'Avance', value: 'Avance' },
  { label: 'Ampliatorio', value: 'Ampliatorio' },
]

const SUBTIPO_OPTIONS = [
  { label: 'T.R.C.', value: 'T.R.C.' },
  { label: 'Resp. Civil', value: 'Resp. Civil' },
  { label: 'Pyme', value: 'Pyme' },
  { label: 'Maquinaria', value: 'Maquinaria' },
  { label: 'Modalidad', value: 'Modalidad' },
  { label: 'Hogar', value: 'Hogar' },
  { label: 'Comercio', value: 'Comercio' },
  { label: 'Inmuebles', value: 'Inmuebles' },
  { label: 'Protecc. Alquileres', value: 'Protecc. Alquileres' },
  { label: 'Oficinas', value: 'Oficinas' },
  { label: 'Otros', value: 'Otros' },
]

const Container = styled.form`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const StyledSelect = styled(Select)`
  width: 260px;
`

const Inputs = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
`

const StyledInput = styled(Input)`
  width: 260px;
  flex-shrink: 0;
`

const ReportInformation = styled.article`
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.colors.ntrl_lighter.main};
  padding: 24px;
`

const ReportInformationWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const STEP = 'informacionGeneral'
export const InformacionGeneralScreen = () => {
  const navigate = useNavigate()
  const { reportId } = useParams()
  const isClosed = useIsClosed(reportId)
  const { data: expediente } = useReportById(reportId)
  const { data: report } = useStoredReport(reportId)
  const {
    register,
    watch,
    control,
    getValues,
    setValue,
    resetField,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      informacionGeneral_TipoReporte: report?.informacionGeneral_TipoReporte ?? '',
      informacionGeneral_Subtipo: report?.informacionGeneral_Subtipo ?? '',
      informacionGeneral_Subtipo_Modalidad: report?.informacionGeneral_Subtipo_Modalidad ?? 0,
      informacionGeneral_Subtipo_Otro: report?.informacionGeneral_Subtipo_Otro ?? '',
      informacionGeneral_FechaVisita: report?.informacionGeneral_FechaVisita ?? '',
      informacionGeneral_FechaSiniestro: report?.informacionGeneral_FechaSiniestro ?? expediente?.fecha_siniestro ?? '',
      informacionGeneral_Lugar: report?.informacionGeneral_Lugar ?? expediente?.lugar ?? '',
    },
    mode: 'onBlur',
  })
  const watchSubtipo = watch('informacionGeneral_Subtipo')
  const { saveData } = useAutoSave()
  const handleSaveReport = useSaveReport()

  const handleFormChange = React.useCallback(() => {
    if (isClosed) {
      showBlockedReportdWarning()
      return
    }

    saveData({ id: STEP, reportId, data: getValues() })
  }, [getValues, isClosed, reportId, saveData])

  const onSubmit = (values) => {
    if (isClosed) {
      navigate(generatePath('/creation/:reportId/c/r/destino-del-riesgo', { reportId }))
      return
    }

    return new Promise((resolve, reject) => {
      handleSaveReport({
        data: values,
        onSuccess: () => {
          resolve()
          navigate(generatePath('/creation/:reportId/c/r/destino-del-riesgo', { reportId }))
        },
        onError: () => {
          reject()
        },
      })
    })
  }

  useEffect(() => {
    if (watchSubtipo !== 'Modalidad') {
      resetField('informacionGeneral_Subtipo_Modalidad')
    }

    if (watchSubtipo !== 'Otros') {
      resetField('informacionGeneral_Subtipo_Otro')
    }
  }, [resetField, watchSubtipo])

  useEffect(() => {
    if (!expediente) {
      return
    }

    const { informacionGeneral_FechaSiniestro, informacionGeneral_Lugar } = getValues()

    if (!informacionGeneral_FechaSiniestro) {
      setValue('informacionGeneral_FechaSiniestro', expediente?.fecha_siniestro ?? '')
    }

    if (!informacionGeneral_Lugar) {
      setValue('informacionGeneral_Lugar', expediente?.lugar ?? '')
    }
  }, [expediente, getValues, report, setValue])

  return (
    <Container onChange={handleFormChange} onSubmit={handleSubmit(onSubmit)}>
      <Header>
        <Headline size="l" weight="500" variant="black">
          Información general del expediente
        </Headline>

        <Text variant="black">
          Aquí encontrarás el resumen de los datos del expediente y las caracterísitcas del informe que vas a generar.
        </Text>
      </Header>

      <Inputs>
        <Controller
          name="informacionGeneral_TipoReporte"
          control={control}
          render={({ field: { value, onChange, onBlur } }) => {
            return (
              <StyledSelect
                label="Tipo de informe"
                name="informacionGeneral_TipoReporte"
                options={TIPO_OPTIONS}
                onChange={(value) => {
                  onChange(value)
                  handleFormChange()
                }}
                onBlur={onBlur}
                value={value}
              />
            )
          }}
        />

        <Controller
          name="informacionGeneral_Subtipo"
          control={control}
          render={({ field: { value, onChange, onBlur, ...rest } }) => {
            return (
              <StyledSelect
                label="Tipo de poliza"
                name="informacionGeneral_Subtipo"
                options={SUBTIPO_OPTIONS}
                onChange={(value) => {
                  onChange(value)
                  handleFormChange()
                }}
                onBlur={onBlur}
                value={value}
                {...rest}
              />
            )
          }}
        />

        {watchSubtipo === 'Otros' && (
          <StyledInput
            {...register('informacionGeneral_Subtipo_Otro')}
            label="Especifícalo"
            placeholder="Escribe aquí"
            type="text"
          />
        )}

        {watchSubtipo === 'Modalidad' && (
          <StyledInput
            {...register('informacionGeneral_Subtipo_Modalidad')}
            label="Especifícalo"
            placeholder="Escribe aquí"
            type="number"
          />
        )}

        <StyledInput
          {...register('informacionGeneral_FechaVisita')}
          label="Fecha de visita"
          placeholder="Escribe aquí"
          type="text"
        />
      </Inputs>

      <ReportInformationWrapper>
        <Text variant="black" weight="600" size="m">
          Datos del expediente
        </Text>
        <ReportInformation>
          <StyledInput label="Oficina" defaultValue={expediente?.oficina} disabled />
          <StyledInput label="Nº Siniestro (MP)" defaultValue={expediente?.numero_siniestro} disabled />
          <StyledInput
            label="F. Encargo"
            defaultValue={
              expediente?.fecha_encargo ? DateTime.fromISO(expediente.fecha_encargo).toFormat('dd-MM-yyyy') : ''
            }
            disabled
          />
          <StyledInput label="Compañia" defaultValue={expediente?.compania?.nombre} disabled />
          <StyledInput
            {...register('informacionGeneral_FechaSiniestro')}
            label="F. Siniestro"
            placeholder="Escribe aquí"
            type="text"
          />
          <StyledInput
            label="F. Efecto"
            defaultValue={
              expediente?.fecha_efecto ? DateTime.fromISO(expediente.fecha_efecto).toFormat('dd-MM-yyyy') : ''
            }
            disabled
          />
          <StyledInput label="Poliza" defaultValue={expediente?.poliza} disabled />
          <StyledInput label="Expediente" defaultValue={expediente?.expediente} disabled />
          <StyledInput label="Asegurado" defaultValue={expediente?.asegurado} disabled />
          <StyledInput {...register('informacionGeneral_Lugar')} label="Lugar" placeholder="Escribe aquí" type="text" />
        </ReportInformation>
      </ReportInformationWrapper>

      <Actions isSubmitting={isSubmitting} />

      <Comments step={STEP} />
    </Container>
  )
}
