import { memo } from 'react'
import styled, { css } from 'styled-components/macro'

const mapAlignWithFlexValue = {
  left: 'flex-start',
  right: 'flex-end',
  center: 'center',
  top: 'flex-start',
  bottom: 'flex-end',
}

export const Box = memo(styled.div`
  display: flex;

  ${({
    gap,
    direction = 'row',
    hAlign = 'left',
    vAlign = 'left',
    margin = '0',
    padding = '0',
    fitWidth = false,
    fit = false,
  }) => css`
    width: ${fitWidth ? '100%' : 'auto'};
    flex: ${fit ? '1' : '0 1 auto'};
    gap: ${gap};
    margin: ${margin};
    padding: ${padding};
    flex-direction: ${direction};
    justify-content: ${mapAlignWithFlexValue[direction === 'column' ? vAlign : hAlign]};
    align-items: ${mapAlignWithFlexValue[direction === 'column' ? hAlign : vAlign]};
  `};
`)
