import React from 'react'
import styled from 'styled-components'
import { Button } from '../../../../../../components'

const Container = styled.nav`
  align-self: flex-start;
  margin-top: 24px;
  display: flex;
  gap: 12px;
`

const Actions = ({
  className,
  isSubmitting,
  backLabel = 'Atrás',
  onBackClick,
  nextLabel = 'Siguiente',
  nextDisabled = false,
  onNextClick,
}) => {
  return (
    <Container>
      {onBackClick ? (
        <Button disabled={isSubmitting} bordered type="button" variant="ntrl_dark" onClick={onBackClick}>
          {backLabel}
        </Button>
      ) : null}
      <Button disabled={isSubmitting || nextDisabled} type="submit" onClick={onNextClick}>
        {isSubmitting ? 'Guardando...' : nextLabel}
      </Button>
    </Container>
  )
}

export default React.memo(Actions)
