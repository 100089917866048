import React, { memo } from 'react'
import icons from './assets/icons.svg'
import styled, { css } from 'styled-components/macro'

const StyledSVG = styled.svg`
  ${({ theme, size }) => css(theme.components?.icon?.sizes?.[size] ?? '')}
`

export const Icon = memo(({ name, ...props }) => {
  return (
    <StyledSVG {...props}>
      <use xlinkHref={`${icons}#${name}`}></use>
    </StyledSVG>
  )
})

Icon.defaultProps = {
  size: 'm',
}

Icon.displayName = 'Icon'
