import { transparentize } from 'polished'
import React from 'react'
import styled, { useTheme } from 'styled-components/macro'
import { Text } from '../../../../../../../../components'
import { AiOutlineFolderOpen } from 'react-icons/ai'

const Container = styled.div`
  position: absolute;
  inset: 0;
  z-index: 900;
  background-color: ${({ theme }) => transparentize(0.2, theme.colors.white.main)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: solid 2px ${({ theme }) => theme.colors.primary.main};
  border-style: dashed;
  border-radius: 12px;
  gap: 12px;
`

export const DropZone = React.memo(({ className, headline = 'Suelta aquí tus ficheros' }) => {
  const theme = useTheme()
  return (
    <Container className={className}>
      <AiOutlineFolderOpen size={60} color={theme.colors.primary.main} />
      <Text variant="black" align="center">
        {headline}
      </Text>
    </Container>
  )
})
