import styled from 'styled-components/macro'

const Options = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
`

export const RadioGroup = ({ children, ...rest }) => {
  return <Options {...rest}>{children}</Options>
}
