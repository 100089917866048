import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ThemeProvider as SCThemeProvider } from 'styled-components'
import { App } from './App'
import { AutoSaveProvider } from './hooks/useAutoSave'
import './index.css'
import { PeritosQueryClient } from './modules'
import { AuthProvider } from './modules/auth'
import reportWebVitals from './reportWebVitals'
import { GlobalCSS } from './theme/GlobalCSS'
import { theme } from './theme/theme'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <QueryClientProvider client={PeritosQueryClient}>
      <SCThemeProvider theme={theme}>
        <GlobalCSS />
        <BrowserRouter>
          <AuthProvider>
            <AutoSaveProvider>
              <App />
              <ToastContainer />
            </AutoSaveProvider>
          </AuthProvider>
        </BrowserRouter>
      </SCThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
