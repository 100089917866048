import { useForm } from 'react-hook-form'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Button, Radio, Text } from '../../../components'
import { useUpdateReportMutation } from '../../../modules/reports/mutations'

const Container = styled.section`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
`

const Content = styled.form`
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 42px;
`

const Block = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const Options = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`

const TARGETS = {
  CASER: 1,
  MURIMAR: 2,
  PARTICULARES: 3,
}

const TYPES = {
  EMPRESAS: 1,
  JURIDICO: 2,
  FRAUDE: 3,
}

const FORMAT = {
  SIMPLIFICADO: 1,
  EXTENDIDO: 2,
}

export const DashboardCreationScreen = () => {
  const { reportId } = useParams()
  const { register, handleSubmit } = useForm({
    defaultValues: {
      target: TARGETS.CASER,
      type: TYPES.EMPRESAS,
      format: FORMAT.SIMPLIFICADO,
    },
    mode: 'onBlur',
  })
  const navigate = useNavigate()
  const updateStoredReportMutation = useUpdateReportMutation()

  const onSubmit = (values) => {
    return new Promise((resolve, reject) => {
      updateStoredReportMutation.mutate(
        {
          id: reportId,
          ...values,
        },
        {
          onSuccess: () => {
            resolve()
            navigate(generatePath('/creation/:reportId/c/r/destino-del-riesgo', { reportId }))
          },
          onError: () => {
            reject()
          },
        },
      )
    })
  }

  return (
    <Container>
      <Text size="xl" weight="600" variant="black">
        Configura tu informe
      </Text>

      <Content onSubmit={handleSubmit(onSubmit)}>
        <Block>
          <Text size="ml" weight="500" variant="black">
            ¿Para quién es?
          </Text>

          <Options>
            <Radio value={TARGETS.CASER} {...register('target')}>
              Caser
            </Radio>

            <Radio value={TARGETS.MURIMAR} {...register('target')}>
              Murimar
            </Radio>

            <Radio value={TARGETS.PARTICULARES} {...register('target')}>
              Particulares
            </Radio>
          </Options>
        </Block>

        <Block>
          <Text size="ml" weight="500" variant="black">
            ¿Qué tipo de informe es?
          </Text>

          <Options>
            <Radio value={TYPES.EMPRESAS} {...register('type')}>
              Empresas
            </Radio>

            <Radio value={TYPES.JURIDICO} {...register('type')}>
              Defensa Jurídica
            </Radio>

            <Radio value={TYPES.FRAUDE} {...register('type')}>
              Fraude
            </Radio>
          </Options>
        </Block>

        <Block>
          <Text size="ml" weight="500" variant="black">
            ¿Qué formato necesitas?
          </Text>

          <Options>
            <Radio {...register('format')} value={FORMAT.SIMPLIFICADO}>
              Simplificado
            </Radio>

            <Radio {...register('format')} value={FORMAT.EXTENDIDO}>
              Extendido
            </Radio>
          </Options>
        </Block>

        <Button type="submit">Generar informe</Button>
      </Content>
    </Container>
  )
}
