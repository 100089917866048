import React, { memo } from 'react'
import styled, { css } from 'styled-components/macro'

const NavUl = styled.ul`
  display: flex;
  gap: 24px;
  margin: 0;
  list-style: none;
  padding: 0;

  ${({ vertical }) => css`
    flex-direction: ${vertical ? 'column' : 'row'};
  `};
`

export const Nav = memo(function Nav({ className, children, vertical, onClick }) {
  return (
    <nav className={className} onClick={onClick}>
      <NavUl vertical={vertical}>{children}</NavUl>
    </nav>
  )
})
