import React from 'react'

export const useDeleteKey = (cb) => {
  React.useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace' || event.key === 'Supr' || event.which === 46 || event.which === 67) {
        cb?.()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [cb])
}
