import { useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import { v4 as uuidv4 } from 'uuid'
import { Input } from '../../../../../../components/atoms/input/Input'
import Text from '../../../../../../components/atoms/text/Text'
import { useStoredReport } from '../../../../../../modules/reports/hooks/useReports'
import { useUpdatePartialReportMutation } from '../../../../../../modules/reports/mutations'
import { Headline } from '../../../components/headline'
import Actions from '../../components/actions/Actions'

const Container = styled.form`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const Content = styled.article`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  overflow: visible;
`

const StyledInput = styled(Input)`
  width: 260px;
`

const AddressField = styled(Input)`
  width: 550px;
`

export const AddPerjudicadoScreen = () => {
  const location = useLocation()
  const perjudicado = location.state?.initialData ?? {}
  const isEditionMode = location.state?.isEditionMode ?? false
  const editionIdx = location.state?.idx ?? -1
  const navigate = useNavigate()
  const { reportId } = useParams()
  const { data: report } = useStoredReport(reportId)
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      perjudicados_id: perjudicado?.perjudicados_id ?? uuidv4(),
      perjudicados_nombre: perjudicado?.perjudicados_nombre ?? '',
      perjudicados_telefono: perjudicado?.perjudicados_telefono ?? '',
      perjudicados_email: perjudicado?.perjudicados_email ?? '',
      perjudicados_nif: perjudicado?.perjudicados_nif ?? '',
      perjudicados_domicilioAfectado: perjudicado?.perjudicados_domicilioAfectado ?? '',
      perjudicados_aseguradora: perjudicado?.perjudicados_aseguradora ?? '',
      perjudicados_poliza: perjudicado?.perjudicados_poliza ?? '',
      perjudicados_expediente: perjudicado?.perjudicados_expediente ?? '',
      perjudicados_cuantiaAsegurada: perjudicado?.perjudicados_cuantiaAsegurada ?? 0,
    },
    mode: 'onBlur',
  })
  const updatePartialReportMutation = useUpdatePartialReportMutation()

  const onSubmit = (values) => {
    return new Promise((resolve, reject) => {
      const perjudicados = [...(report.perjudicados ?? [])]
      if (isEditionMode) {
        perjudicados[editionIdx] = values
      } else {
        perjudicados.push(values)
      }
      updatePartialReportMutation.mutate(
        {
          id: report.id,
          perjudicados,
        },
        {
          onSuccess: () => {
            resolve()
            navigate(-1)
          },
          onError: (error) => {
            reject(error)
            console.info(error)
          },
        },
      )
    })
  }

  const handleGoBack = () => {
    navigate(-1)
  }

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Header>
        <Headline size="l" weight="500" variant="black">
          {isEditionMode ? 'Edita el perjudicado' : 'Añadir perjudicado'}
        </Headline>

        <Text variant="black">
          {isEditionMode
            ? 'Actualiza los datos del perjudicado'
            : 'Completa los datos del perjudicado para añadirlo al informe'}
        </Text>
      </Header>

      <Content>
        <StyledInput {...register('perjudicados_nombre')} label="Nombre" placeholder="Escribe aquí" type="text" />

        <StyledInput
          {...register('perjudicados_telefono')}
          label="Teléfono"
          placeholder="Escribe aquí"
          type="tel"
          pattern="[0-9]{9}"
        />

        <StyledInput {...register('perjudicados_email')} label="Email" placeholder="Escribe aquí" type="email" />

        <StyledInput {...register('perjudicados_nif')} label="NIF" placeholder="Escribe aquí" type="text" />

        <AddressField
          {...register('perjudicados_domicilioAfectado')}
          label="Domicilio afectado"
          placeholder="Escribe aquí"
          type="text"
        />

        <StyledInput
          {...register('perjudicados_aseguradora')}
          label="Aseguradora"
          placeholder="Escribe aquí"
          type="text"
        />

        <StyledInput {...register('perjudicados_poliza')} label="Poliza" placeholder="Escribe aquí" type="text" />

        <StyledInput {...register('perjudicados_expediente')} label="Exp." placeholder="Escribe aquí" type="text" />

        <StyledInput
          {...register('perjudicados_cuantiaAsegurada')}
          label="Cuantía asegurada"
          placeholder="Escribe aquí"
          type="text"
        />
      </Content>

      <Actions
        isSubmitting={isSubmitting}
        nextLabel={isEditionMode ? 'Guardar' : 'Añadir'}
        onBackClick={handleGoBack}
      />
    </Container>
  )
}
