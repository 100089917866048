import React, { memo } from 'react'
import styled, { css } from 'styled-components/macro'
import { Text } from '../text/Text'

const TabContainer = styled.button`
  outline: none;
  padding: 8px 6px;
  border: 0;
  border-bottom: solid 1px ${({ theme }) => theme?.colors?.ntrl_light?.main};
  background-color: transparent;
  position: relative;
  flex: 1;
  align-self: stretch;
  cursor: pointer;

  ${({ isActive, theme }) =>
    isActive &&
    css`
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        position: absolute;
        bottom: -1px;
        left: 0;
        background-color: ${theme?.colors?.primary?.main};
      }
    `};
`

export const Tab = memo(({ children, isActive, ...props }) => {
  return (
    <TabContainer isActive={isActive} type="button" {...props}>
      <Text size="m" weight="400" align="center" variant={isActive ? 'black' : 'ntrl_dark'}>
        {children}
      </Text>
    </TabContainer>
  )
})

Tab.defaultProps = {
  isActive: false,
}

Tab.displayName = 'Tab'
