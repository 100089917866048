import { memo } from 'react'
import styled, { css } from 'styled-components/macro'

export const ButtonFlat = memo(styled.button`
  padding: 2px 6px;
  border-radius: 2px;
  margin: 0;
  outline: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  background: transparent;
  border: 0;
  cursor: pointer;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  ${({ theme, variant = 'primary' }) => css`
    background-color: ${theme.colors[variant].main};
    color: ${theme.colors[variant].contrast};
  `};

  &:hover {
    opacity: 0.6;
  }
`)
