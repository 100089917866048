import { PeritosQueryClient } from '..'
import { patchStoredReport, updateAnexos, updateCapitales } from '../reports/queries'

export const TaskManager = Object.seal({
  tasks: {},
  reportId: null,

  addTasks({ id, reportId, data }) {
    this.tasks[id] = data

    if (reportId && this.reportId !== reportId) {
      this.reportId = reportId
    }
  },

  async compileTasks() {
    const currentReport = PeritosQueryClient.getQueryData(['reports', this.reportId?.toString(), 'storedReport'])
    await PeritosQueryClient.cancelQueries(['reports', this.reportId?.toString(), 'storedReport'])

    if (this.tasks.anexos) {
      await PeritosQueryClient.cancelQueries(['reports', this.reportId?.toString(), 'storedAnexos'])
      const anexos = this.tasks.anexos
      delete this.tasks.anexos
      const updatedAnexos = await updateAnexos({ reportId: this.reportId, anexos }).catch((error) => {
        console.info(error)
      })
      if (updatedAnexos) {
        PeritosQueryClient.setQueryData(['reports', this.reportId?.toString(), 'storedAnexos'], updatedAnexos)
      }
    }

    if (this.tasks.editCapitales) {
      await PeritosQueryClient.cancelQueries(['reports', this.reportId?.toString(), 'capitales'])
      const capitales = this.tasks.editCapitales
      delete this.tasks.editCapitales
      const updatedCapitales = await updateCapitales({ reportId: this.reportId, capitales }).catch((error) => {
        console.info(error)
      })
      if (updatedCapitales) {
        PeritosQueryClient.setQueryData(['reports', this.reportId?.toString(), 'storedReport'], updatedCapitales.report)
        PeritosQueryClient.setQueryData(['reports', this.reportId?.toString(), 'capitales'], updatedCapitales.capitales)
      }
    }

    const toUpdateData = Object.keys(this.tasks).reduce(
      (toSaveData, task) => {
        const data = { ...toSaveData, ...(this.tasks[task] ?? {}) }
        // Flush task
        delete this.tasks[task]
        return data
      },
      { id: this.reportId },
    )
    console.info(toUpdateData, currentReport)
    const newReport = await patchStoredReport(toUpdateData)
    console.info(newReport)
    PeritosQueryClient.setQueryData(['reports', this.reportId?.toString(), 'storedReport'], newReport)
  },
})
