import RequestHandler from '../request/index'

class AppAPI {
  static getChangelog() {
    const { generateHeaders } = RequestHandler.sharedInstance()

    return fetch(`${process.env.PUBLIC_URL}/static/docs/VERSION.md`, {
      method: 'GET',
      headers: generateHeaders({ headers: {} }),
    })
  }
}

export default AppAPI
