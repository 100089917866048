import styled from 'styled-components'

const Block = styled.section`
  display: flex;
  flex-direction: column;
  gap: 18px;
`

export const HighlightedBlock = styled(Block)`
  padding: 12px;
  background-color: ${({ theme }) => theme?.colors?.ntrl_lighter?.main};
`
