import React, { useRef } from 'react'
import styled, { css } from 'styled-components/macro'

const CollapsibleWrapper = styled.div`
  box-sizing: border-box;
  overflow: hidden;
  transition: max-height 200ms;

  ${({ expanded, $height }) => css`
    max-height: ${expanded ? `${$height}px` : 0};
    background: ${({ theme }) => theme.colors.white.main};
    transition: max-height ${expanded ? '200ms' : '100ms'};
  `};
`

export const Collapsible = function Collapsible({ className, children, expanded, onClick }) {
  const contentRef = useRef()

  return (
    <CollapsibleWrapper
      className={className}
      aria-expanded={expanded}
      expanded={expanded}
      $height={contentRef?.current?.offsetHeight ?? 0}
    >
      {/*
          With flex the margin is added to element height
          calculated here: contentRef.current.offsetHeight.
          Using inline styles because it works faster than SC
      */}
      <div style={{ display: 'flex', flexDirection: 'column' }} ref={contentRef} onClick={onClick}>
        {children}
      </div>
    </CollapsibleWrapper>
  )
}
