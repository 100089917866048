import { Outlet, Route, Routes } from 'react-router-dom'
import PrivateRoute from './components/molecules/privateRoute/PrivateRoute'
import { SinglePageLayout } from './layouts/singlePage/SinglePageLayout'
import LoginScreen from './screens/auth/login/LoginScreen'
import { DashboardLayout } from './screens/dashboard/components/layout/DashboardLayout'
import { DashboardCreationScreen } from './screens/dashboard/creation/DashboardCreationScreen'
import { ClosedReportScreen } from './screens/dashboard/creation/closed/ClosedReportScreen'
import { AnalisisDeCapitalesScreen } from './screens/dashboard/creation/company/regular/analisisDeCapitales/AnalisisDeCapitalesScreen'
import { AnalisisDeRiesgoScreen } from './screens/dashboard/creation/company/regular/analisisDeRiesgo/AnalisisDeRiesgoScreen'
import { AnexosScreen } from './screens/dashboard/creation/company/regular/anexos/AnexosScreen'
import { AnexosSortScreen } from './screens/dashboard/creation/company/regular/anexosSort/AnexosSortScreen'
import { DescripcionScreen } from './screens/dashboard/creation/company/regular/descripcion/DescripcionScreen'
import { DestinoDelRiesgoScreen } from './screens/dashboard/creation/company/regular/destinoDelRiesgo/DestinoDelRiesgoScreen'
import { FacturaScreen } from './screens/dashboard/creation/company/regular/factura/FacturaScreen'
import { InformacionGeneralScreen } from './screens/dashboard/creation/company/regular/informacionGeneral/InformacionGeneralScreen'
import { CompanyRegularCreationReportLayout } from './screens/dashboard/creation/company/regular/layout'
import { MedidasDeSeguridadScreen } from './screens/dashboard/creation/company/regular/medidasDeSeguridad/MedidasDeSeguridadScreen'
import { AddPerjudicadoScreen } from './screens/dashboard/creation/company/regular/perjudicados/AddPerjudicadoScreen'
import { PerjudicadosScreen } from './screens/dashboard/creation/company/regular/perjudicados/PerjudicadosScreen'
import { PropuestaFinalScreen } from './screens/dashboard/creation/company/regular/propuestaFinal/PropuestaFinalScreen'
import { AddResponsableScreen } from './screens/dashboard/creation/company/regular/responsables/AddResponsableScreen'
import { ResponsablesScreen } from './screens/dashboard/creation/company/regular/responsables/ResponsablesScreen'
import { TipoDeSiniestroScreen } from './screens/dashboard/creation/company/regular/tipoDeSiniestro/TipoDeSiniestroScreen'
import { AddValoracionScreen } from './screens/dashboard/creation/company/regular/valoracion/AddValoracionScreen'
import { ValoracionScreen } from './screens/dashboard/creation/company/regular/valoracion/ValoracionScreen'
import { CopyReportScreen } from './screens/dashboard/creation/copy/CopyReportScreen'
import { EditCapitalesScreen } from './screens/dashboard/creation/editCapitales/EditCapitalesScreen'
import { DashboardHomeScreen } from './screens/dashboard/home/DashboardHomeScreen'
import { ReportDetailsScreen } from './screens/report/details/ReportDetailsScreen'
import { ReportPreviewScreen } from './screens/report/preview/ReportPreviewScreen'
import ChangelogScreen from './screens/version/ChangelogScreen'

export const App = () => {
  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route element={<DashboardLayout />}>
          <Route path="/" element={<DashboardHomeScreen />} />
          <Route path="/creation/:reportId" element={<DashboardCreationScreen />} />
          <Route path="/:reportId/clone" element={<CopyReportScreen />} />
          <Route path="/:reportId/closed" element={<ClosedReportScreen />} />
        </Route>

        <Route element={<CompanyRegularCreationReportLayout />}>
          {/* <Route path="/creation/:reportId/c/r/expediente" element={<SeleccionarExpedienteScreen />} /> */}
          <Route path="/creation/:reportId/c/r/informacion-general" element={<InformacionGeneralScreen />} />
          <Route path="/creation/:reportId/c/r/destino-del-riesgo" element={<DestinoDelRiesgoScreen />} />
          <Route path="/creation/:reportId/c/r/tipo-de-siniestro" element={<TipoDeSiniestroScreen />} />
          <Route path="/creation/:reportId/c/r/medidas-de-seguridad" element={<MedidasDeSeguridadScreen />} />
          <Route path="/creation/:reportId/c/r/analisis-de-riesgo" element={<AnalisisDeRiesgoScreen />} />
          <Route path="/creation/:reportId/c/r/responsables" element={<Outlet />}>
            <Route path="/creation/:reportId/c/r/responsables/add" element={<AddResponsableScreen />} />
            <Route index element={<ResponsablesScreen />} />
          </Route>
          <Route path="/creation/:reportId/c/r/perjudicados" element={<Outlet />}>
            <Route path="/creation/:reportId/c/r/perjudicados/add" element={<AddPerjudicadoScreen />} />
            <Route index element={<PerjudicadosScreen />} />
          </Route>
          <Route path="/creation/:reportId/c/r/descripcion" element={<DescripcionScreen />} />
          <Route path="/creation/:reportId/c/r/analisis-de-capitales" element={<AnalisisDeCapitalesScreen />} />
          <Route path="/creation/:reportId/c/r/capitales" element={<EditCapitalesScreen />} />
          <Route path="/creation/:reportId/c/r/valoracion" element={<Outlet />}>
            <Route path="/creation/:reportId/c/r/valoracion/add" element={<AddValoracionScreen />} />
            <Route index element={<ValoracionScreen />} />
          </Route>
          <Route path="/creation/:reportId/c/r/propuesta-final" element={<PropuestaFinalScreen />} />
          <Route path="/creation/:reportId/c/r/anexos" element={<AnexosScreen />} />
          <Route path="/creation/:reportId/c/r/anexos/sort" element={<AnexosSortScreen />} />
          <Route path="/creation/:reportId/c/r/factura" element={<FacturaScreen />} />
        </Route>

        <Route element={<SinglePageLayout />}>
          <Route path="/version" element={<ChangelogScreen />} />
          <Route path="/:reportId/detalles" element={<ReportDetailsScreen />} />
          <Route path="/:reportId/preview" element={<ReportPreviewScreen />} />
        </Route>
      </Route>
      <Route path="/login" element={<LoginScreen />} />
    </Routes>
  )
}
