import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Radio, Text } from '../../../../../../components'
import { Comments } from '../../../../../../components/molecules/comments/Comments'
import { RadioGroup } from '../../../../../../components/molecules/radioGroup/RadioGroup'
import { useAutoSave } from '../../../../../../hooks/useAutoSave'
import { useIsClosed, useSaveReport, useStoredReport } from '../../../../../../modules/reports/hooks/useReports'
import { showBlockedReportdWarning } from '../../../../../../modules/reports/utils'
import { Headline } from '../../../components/headline'
import Actions from '../../components/actions/Actions'

const Container = styled.form`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const STEP = 'destinoDeRiesgo'
export const DestinoDelRiesgoScreen = () => {
  const navigate = useNavigate()
  const { reportId } = useParams()
  const isClosed = useIsClosed(reportId)
  const { data: report } = useStoredReport(reportId)
  const {
    getValues,
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      destinoDeRiesgo: report?.destinoDeRiesgo ?? 'obra',
    },
    mode: 'onBlur',
  })
  const handleSaveReport = useSaveReport()
  const { saveData } = useAutoSave()

  const handleFormChange = useCallback(() => {
    if (isClosed) {
      showBlockedReportdWarning()
      return
    }

    saveData({ id: STEP, reportId, data: getValues() })
  }, [getValues, isClosed, reportId, saveData])

  const handleGoBack = useCallback(() => {
    handleSaveReport({ data: getValues() })
    navigate(-1)
  }, [handleSaveReport, getValues, navigate])

  const onSubmit = (values) => {
    if (isClosed) {
      navigate(generatePath('/creation/:reportId/c/r/tipo-de-siniestro', { reportId }))
      return
    }

    return new Promise((resolve, reject) => {
      handleSaveReport({
        data: values,
        onSuccess: () => {
          resolve()
          navigate(generatePath('/creation/:reportId/c/r/tipo-de-siniestro', { reportId }))
        },
        onError: () => {
          reject()
        },
      })
    })
  }

  return (
    <Container onChange={handleFormChange} onSubmit={handleSubmit(onSubmit)}>
      <Header>
        <Headline size="l" weight="500" variant="black">
          Selecciona el destino del riesgo
        </Headline>

        <Text variant="black">Indica el valor que aplique para continuar con tu informe.</Text>
      </Header>

      <Block>
        <Text variant="black" bold>
          Empresa
        </Text>
        <RadioGroup>
          <Radio value="obra" {...register('destinoDeRiesgo')}>
            Obra en construcción
          </Radio>
          <Radio value="pyme" {...register('destinoDeRiesgo')}>
            PYME - Industria
          </Radio>
          <Radio value="responsabilidadCivil" {...register('destinoDeRiesgo')}>
            Responsabilidad civil
          </Radio>
          <Radio value="maquinaria" {...register('destinoDeRiesgo')}>
            Maquinaria
          </Radio>
        </RadioGroup>
      </Block>

      <Block>
        <Text variant="black" bold>
          Particulares
        </Text>
        <RadioGroup>
          <Radio value="piso" {...register('destinoDeRiesgo')}>
            Piso
          </Radio>
          <Radio value="chalet" {...register('destinoDeRiesgo')}>
            Chalet
          </Radio>
          <Radio value="adosado" {...register('destinoDeRiesgo')}>
            Adosado
          </Radio>
          <Radio value="localComercial" {...register('destinoDeRiesgo')}>
            Local comercial
          </Radio>
          <Radio value="edificioViviendas" {...register('destinoDeRiesgo')}>
            Edificio de viviendas
          </Radio>
          <Radio value="alquiler" {...register('destinoDeRiesgo')}>
            Alquiler
          </Radio>
          <Radio value="alquilerTuristico" {...register('destinoDeRiesgo')}>
            Alquiler turístico
          </Radio>
        </RadioGroup>
      </Block>

      <Actions isSubmitting={isSubmitting} onBackClick={handleGoBack} />

      <Comments step={STEP} />
    </Container>
  )
}
