import React, { memo } from 'react'
import styled, { css } from 'styled-components'

const AvatarWrapper = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`

const AvatarName = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  ${({ theme, variant }) => css`
    color: ${theme.colors[variant]?.main ?? theme.colors.ntrl_darkest.main};
  `}
`

const AvatarWrapperImg = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 50%;
  object-fit: cover;
  box-sizing: border-box;

  ${({ theme }) => css`
    border: solid 1px ${theme.colors.white.main};
    background: ${theme.colors.ntrl_darkest.main};
  `}
`

const AvatarImg = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`

export const Avatar = memo(function Avatar({ variant = 'ntrl_darkest', name, src, ...rest }) {
  return (
    <AvatarWrapper role="avatar" {...rest}>
      {name && <AvatarName variant={variant}>{name}</AvatarName>}
      <AvatarWrapperImg>{src && <AvatarImg src={src} alt="avatar image" />}</AvatarWrapperImg>
    </AvatarWrapper>
  )
})
