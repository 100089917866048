import React from 'react'
import { Image, Layer, Stage } from 'react-konva'
import styled, { keyframes } from 'styled-components/macro'
import useImage from 'use-image'
import { v4 as uuidv4 } from 'uuid'
import { useDeleteKey } from '../../../hooks/useDeleteKey'
import Text from '../../atoms/text/Text'
import { SaveConfirmModal } from './components/saveConfirmModal/SaveConfirmModal'
import { Shape } from './components/shape/Shape'
import { Toolbar } from './components/toolbar/Toolbar'
import { BRUSHES, ImageEditorProvider, useImageEditor } from './context'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const ImageWrapper = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9;
  background-color: ${({ theme }) => theme?.colors?.ntrl_lighter?.main};
  display: flex;
  align-items: center;
  justify-content: center;
`

const LoadingEditor = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme?.colors?.ntrl_lighter?.main};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  position: absolute;
  inset: 0;
  z-index: 1;
`

const anim1 = keyframes`
  50%,100% {background-position: calc(100%/2) 0}
`

const anim2 = keyframes`
  0%,50% {transform:translateY(-80px)}
`

const FactoryLoader = styled.div`
  width: 90px;
  height: 14px;
  box-shadow: 0 3px 0 ${({ theme }) => theme?.colors?.ntrl_darker?.main};
  position: relative;
  display: grid;
  clip-path: inset(-60px 0 -5px);

  &:after {
    content: '';
    position: relative;
    background: repeating-linear-gradient(90deg, #0000 0 calc(50% - 8px), #ccc 0 calc(50% + 8px), #0000 0 100%) 0 0 /
      calc(100% / 3) 100%;
    animation: ${anim1} 1s infinite;
  }

  &:before {
    content: '';
    position: absolute;
    width: 14px;
    aspect-ratio: 1;
    left: calc(50% - 7px);
    bottom: 0;
    border-radius: 50%;
    background: ${({ theme }) => theme?.colors?.secondary?.main};
    animation: ${anim2} 1s infinite;
  }
`

const generateCircleShape = ({ x, y, color, strokeWidth }) => {
  return {
    id: uuidv4(),
    type: 'circle',
    x,
    y,
    radius: 50,
    isDragging: false,
    stroke: color,
    strokeWidth,
  }
}

const generateRectShape = ({ x, y, color, strokeWidth }) => {
  return {
    id: uuidv4(),
    type: 'rect',
    x,
    y,
    width: 100,
    height: 50,
    isDragging: false,
    stroke: color,
    strokeWidth,
  }
}

const generateArrowShape = ({ x, y, color, strokeWidth }) => {
  return {
    id: uuidv4(),
    type: 'arrow',
    x,
    y,
    points: [0, 0, 100, 100],
    isDragging: false,
    fill: color,
    stroke: color,
    strokeWidth,
  }
}

export const ImageEditor = React.memo((props) => {
  return (
    <ImageEditorProvider>
      <ImageEditorContent {...props} />
    </ImageEditorProvider>
  )
})

const ImageEditorContent = React.memo(
  ({ className, attachmentId, url, caption, onConfirm, onRequestClose, ...rest }) => {
    const wrapper = React.useRef(null)
    const stage = React.useRef(null)
    const [image, status] = useImage(url, 'Anonymous')
    const [dimensions, setDimensions] = React.useState(null)
    const [state, dispatch] = useImageEditor()

    const handleDragStart = (e) => {
      const id = e.target.id()
      dispatch({
        type: 'UPDATE_SHAPE',
        payload: {
          id,
          props: {
            isDragging: true,
          },
        },
      })
    }
    const handleDragEnd = (e) => {
      const id = e.target.id()
      dispatch({
        type: 'UPDATE_SHAPE',
        payload: {
          id,
          props: {
            isDragging: false,
          },
        },
      })
    }

    const handleDeleteShape = () => {
      if (!state.selectedId) {
        return
      }
      dispatch({
        type: 'DELETE_SELECTED_SHAPE',
      })
    }

    useDeleteKey(handleDeleteShape)

    const handleStageClick = (e) => {
      if (state?.selectedBrush) {
        const { x, y } = e.target.getRelativePointerPosition()
        let newShape
        switch (state.selectedBrush) {
          case BRUSHES.CIRCLE:
            newShape = generateCircleShape({ x, y, color: state.selectedColor, strokeWidth: state.selectedStrokeWidth })
            break

          case BRUSHES.RECTANGLE:
            newShape = generateRectShape({ x, y, color: state.selectedColor, strokeWidth: state.selectedStrokeWidth })
            break

          case BRUSHES.ARROW:
            newShape = generateArrowShape({ x, y, color: state.selectedColor, strokeWidth: state.selectedStrokeWidth })
            break

          default:
            break
        }

        if (!newShape) {
          return
        }

        dispatch({
          type: 'ADD_SHAPE',
          payload: {
            shape: newShape,
          },
        })
        return
      }

      // deselect when clicked on empty area
      const clickedOnEmpty = e.target === e.target.getStage()
      if (clickedOnEmpty) {
        dispatch({
          type: 'SET_SELECTED_ID',
          payload: {
            selectedId: null,
          },
        })
      }
    }

    const handleDoubleConfirmation = async () => {
      dispatch({
        type: 'SAVE_IMAGE',
      })
    }

    const handleSaveImage = async () => {
      if (typeof onConfirm === 'function') {
        const image = stage.current.toDataURL()
        await onConfirm({ attachmentId, base64: image })
      }
      onRequestClose?.()
    }

    const handleCancelSave = () => {
      dispatch({
        type: 'CANCEL_SAVE_IMAGE',
      })
    }

    React.useLayoutEffect(() => {
      if (!wrapper.current || status === 'loading') {
        return
      }

      const { width, height } = image
      const { offsetWidth, offsetHeight } = wrapper.current

      let stageWidth = width
      let stageHeight = height

      if (height > offsetHeight) {
        stageHeight = offsetHeight
        stageWidth = (offsetHeight / height) * width
      }

      if (stageWidth > offsetWidth) {
        stageWidth = offsetWidth
        stageHeight = (offsetWidth / width) * height
      }

      setDimensions({
        width: stageWidth,
        height: stageHeight,
      })
    }, [image, status])

    const isSettingUpEditor = status === 'loading' || !dimensions
    return (
      <Container className={className}>
        {isSettingUpEditor ? (
          <LoadingEditor>
            <FactoryLoader />
            <Text variant="black">Cargando editor...</Text>
          </LoadingEditor>
        ) : null}

        {state?.showSaveImageWarning ? (
          <SaveConfirmModal onConfirm={handleSaveImage} onCancel={handleCancelSave} />
        ) : null}

        <Toolbar
          headline={caption}
          onRemoveShape={handleDeleteShape}
          onCancel={onRequestClose}
          onConfirm={handleDoubleConfirmation}
        />
        <ImageWrapper ref={wrapper}>
          {dimensions ? (
            <Stage
              ref={stage}
              width={dimensions.width}
              height={dimensions.height}
              onMouseDown={handleStageClick}
              onTouchStart={handleStageClick}
            >
              <Layer>
                <Image image={image} width={dimensions.width} height={dimensions.height} listening={false} />
                {state.shapes.map((shape, idx) => {
                  console.info(shape)
                  return (
                    <Shape
                      key={shape.id}
                      {...shape}
                      isSelected={shape.id === state.selectedId}
                      onDragStart={handleDragStart}
                      onDragEnd={handleDragEnd}
                      onSelect={() => {
                        dispatch({
                          type: 'SET_SELECTED_ID',
                          payload: {
                            selectedId: shape.id,
                            selectedColor: shape.stroke,
                            selectedStrokeWidth: shape.strokeWidth,
                          },
                        })
                      }}
                      onChange={(newAttrs) => {
                        const updatedShapes = state.shapes.slice()
                        updatedShapes[idx] = newAttrs
                        dispatch({
                          type: 'SET_SHAPES',
                          payload: {
                            shapes: updatedShapes,
                          },
                        })
                      }}
                    />
                  )
                })}
              </Layer>
            </Stage>
          ) : null}
        </ImageWrapper>
      </Container>
    )
  },
)
