import styled from 'styled-components/macro'
import { Link, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Text } from '../../../components'
import { usePagination } from '../../../hooks/pagination'
import { useReports } from '../../../modules/reports/hooks/useReports'
import { DateTime } from 'luxon'
import { FiPlusCircle } from 'react-icons/fi'

const Container = styled.section`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
`

const EmptyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`

const TableWrapper = styled.div`
  min-width: 600px;
  flex: 1;
  overflow: auto;
  box-sizing: border-box;
`

const Header = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
`

const StyledIcon = styled(FiPlusCircle)`
  margin-right: 6px;
`

export const DashboardHomeScreen = () => {
  const { data: reports, isLoading: isLoadingReports } = useReports()
  const { paginatedData, currentPage, onPageChange } = usePagination(reports?.reverse() ?? [], {
    itemsPerPage: 20,
  })

  if (isLoadingReports) {
    return null
  }

  return (
    <Container>
      <Header>
        <Text size="xl" weight="600" variant="black">
          Mis informes
        </Text>

        <Link to="/creation">
          <StyledIcon color="black" />
          Nuevo Informe
        </Link>
      </Header>

      {reports.length === 0 && (
        <EmptyContent>
          <Text size="l" variant="black" align="center">
            Parece que no tienes informes
          </Text>
        </EmptyContent>
      )}

      {reports.length > 0 && (
        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow sticky>
                <TableCell>
                  <Text size="sm" weight="500" variant="black">
                    Referencia del expediente
                  </Text>
                </TableCell>
                <TableCell>
                  <Text size="sm" weight="500" variant="black">
                    Fecha
                  </Text>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.map((row) => {
                return (
                  <TableRow key={row.id}>
                    <TableCell>
                      <Text size="sm" variant="black" weight="400" truncate>
                        {row.expediente}
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text size="sm" variant="black" weight="400" truncate>
                        {DateTime.fromISO(row.fecha_encargo).toFormat('dd LLL yyyy')}
                      </Text>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          <TablePagination
            count={reports?.length ?? 0}
            rowsPerPage={20}
            page={currentPage}
            onPageChange={onPageChange}
          />
        </TableWrapper>
      )}
    </Container>
  )
}
