import TarifasAPI from './api'

export const fetchTarifas = async () => {
  const response = await TarifasAPI.getTarifas()
  if (response.status !== 200) {
    throw new Error('Bad Request')
  }
  return await response.json()
}

export const fetchTarifa = async (tarifaId) => {
  const response = await TarifasAPI.getTarifa({ tarifaId })
  if (response.status !== 200) {
    throw new Error('Bad Request')
  }
  return await response.json()
}
