import React from 'react'
import { useForm } from 'react-hook-form'
import { FaRegImages } from 'react-icons/fa6'
import { IoDocumentsOutline } from 'react-icons/io5'
import styled, { useTheme } from 'styled-components'
import { Button, Modal, Radio, Text } from '../../components'
import { useDownloadReportMutation } from '../../modules/reports/mutations'
import { Headline } from '../../screens/dashboard/creation/components/headline'
import { useModal } from '../useModal'
import { useParams } from 'react-router-dom'

const StyledModal = styled(Modal)`
  &&&.ReactModal__Content {
    height: auto;
  }
`

const Container = styled.form`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Options = styled.div`
  display: flex;
  gap: 12px;
`

const Option = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 12px;
  border-radius: 6px;
  border: ${({ theme }) => `solid 1px ${theme.colors.ntrl_light.main}`};
  background-color: #fff;
  cursor: pointer;
  transition: 120ms background-color ease-in-out;
  width: 160px;
  aspect-ratio: 4 / 3;
  position: relative;

  &:hover {
    background-color: ${({ theme }) => theme.colors.ntrl_lighter.main};
  }
`

const StyledRadio = styled(Radio)`
  position: absolute;
  top: 12px;
  left: 12px;
`

const Actions = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 60px;
  align-self: flex-end;
`

export const useDownload = ({ reportId }) => {
  const [isDownloading, setIsDownloading] = React.useState(false)
  const downloadReportMutation = useDownloadReportMutation()

  const handleDownloadReport = () => {
    if (!reportId || isDownloading) {
      return
    }
    setIsDownloading(true)
    downloadReportMutation.mutate(
      { reportId },
      {
        onSettled: () => setIsDownloading(false),
      },
    )
  }

  return [isDownloading, handleDownloadReport]
}

const MAP_DOWNLOAD_OPTIONS = {
  all: {
    informe: true,
    descripcionSiniestro: true,
    analisisCapitales: true,
    valoracion: true,
    propuesta: true,
    fotografias: true,
    attachments: true,
  },
  attachments: {
    informe: false,
    descripcionSiniestro: false,
    analisisCapitales: false,
    valoracion: false,
    propuesta: false,
    fotografias: true,
    attachments: true,
  },
}

export const withDownloadModal = (Component) => (props) => {
  const theme = useTheme()
  const { reportId } = useParams()
  const downloadReportMutation = useDownloadReportMutation()
  const [{ isOpen }, { showModal, closeModal }] = useModal()
  const {
    setValue,
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      downloadOption: 'all',
    },
    mode: 'onBlur',
  })

  const handlePress = () => {
    showModal()
  }

  const onSubmit = async (values) => {
    const { downloadOption } = values
    if (!reportId || isSubmitting) {
      return
    }
    await downloadReportMutation.mutateAsync({ reportId, options: MAP_DOWNLOAD_OPTIONS[downloadOption] })
    closeModal()
  }

  return (
    <>
      <Component {...props} handleDownload={handlePress} />
      <StyledModal isOpen={isOpen} onRequestClose={closeModal}>
        <Container onSubmit={handleSubmit(onSubmit)}>
          <Headline size="l" weight="500" variant="black">
            ¿Qué quieres descargar?
          </Headline>

          <Text variant="black">Puedes generar el informe completo o sólo aquellas partes que necesites</Text>

          <Options>
            <Option type="button" onClick={() => setValue('downloadOption', 'all')}>
              <StyledRadio value="all" {...register('downloadOption')}></StyledRadio>
              <IoDocumentsOutline size={30} color={theme.colors.primary.main} />

              <Text variant="black">Informe completo</Text>
            </Option>

            <Option type="button" onClick={() => setValue('downloadOption', 'attachments')}>
              <StyledRadio value="attachments" {...register('downloadOption')}></StyledRadio>
              <FaRegImages size={30} color={theme.colors.primary.main} />

              <Text variant="black">Adjuntos</Text>
            </Option>
          </Options>

          <Actions>
            <Button bordered type="button" variant="ntrl_dark" onClick={closeModal}>
              Cancelar
            </Button>
            <Button type="submit" disabled={!!isSubmitting}>
              {isSubmitting ? 'Preparando descarga...' : 'Descargar'}
            </Button>
          </Actions>
        </Container>
      </StyledModal>
    </>
  )
}
