import { useMutation } from '@tanstack/react-query'
import { PeritosQueryClient } from '..'
import {
  addAnexos,
  addCapital,
  addCapitales,
  addComment,
  cloneReport,
  closeReport,
  deleteCapital,
  downloadReport,
  editCapital,
  openReport,
  removeStoredAttachment,
  updateAnexos,
  updateCapitales,
  updateAnexosOrder,
  updatePhoto,
  updateStoredComments,
  updateStoredReport,
  patchStoredReport,
} from './queries'

export const useUpdateReportMutation = () => {
  return useMutation({
    mutationFn: updateStoredReport,
    onError: (error) => {
      console.info(error)
    },
    onSuccess: (data) => {
      console.info('mutation', data)
      PeritosQueryClient.setQueryData(['reports', data.id.toString(), 'storedReport'], data)
    },
  })
}

export const useUpdatePartialReportMutation = () => {
  return useMutation({
    mutationFn: patchStoredReport,
    onError: (error) => {
      console.info(error)
    },
    onSuccess: (data) => {
      PeritosQueryClient.setQueryData(['reports', data.id.toString(), 'storedReport'], data)
    },
  })
}

export const updateReportOnUnmount = async ({ report }) => {
  if (!report || !report?.id) {
    return
  }
  await updateStoredReport(report)
  PeritosQueryClient.invalidateQueries(['reports', report.id.toString(), 'storedReport'])
}

export const useUpdateCommentsMutation = () => {
  return useMutation({
    mutationFn: updateStoredComments,
    onError: (error) => {
      console.info(error)
    },
    onSuccess: (data) => {
      console.info('mutation comments', data)
    },
  })
}

export const useAddCommentMutation = () => {
  return useMutation({
    mutationFn: addComment,
    onMutate: async ({ reportId, step, comment }) => {
      await PeritosQueryClient.cancelQueries(['reports', reportId, 'storedComments'])
      const previousComments = PeritosQueryClient.getQueryData(['reports', reportId, 'storedComments'])

      PeritosQueryClient.setQueryData(['reports', reportId, 'storedComments'], (old) => {
        return {
          ...(old ?? {}),
          [step]: [...(old[step] ?? []), comment],
        }
      })

      return { previousComments }
    },
    onError: (error, { reportId }, context) => {
      console.info(error)
      PeritosQueryClient.setQueryData(['reports', reportId, 'storedComments'], context.previousComments)
    },
    onSuccess: (data) => {
      console.info('mutation comments', data)
    },
    onSettled: (_, error, { reportId }) => {
      PeritosQueryClient.invalidateQueries({ queryKey: ['reports', reportId, 'storedComments'] })
    },
  })
}

export const useRemoveCommentMutation = () => {
  return useMutation({
    mutationFn: addComment,
    onMutate: async ({ reportId, step, commentIdx }) => {
      await PeritosQueryClient.cancelQueries(['reports', reportId, 'storedComments'])
      const previousComments = PeritosQueryClient.getQueryData(['reports', reportId, 'storedComments'])

      PeritosQueryClient.setQueryData(['reports', reportId, 'storedComments'], (old) => {
        return {
          ...(old ?? {}),
          [step]: [...(old[step] ?? [])].filter((_, idx) => idx !== commentIdx),
        }
      })

      return { previousComments }
    },
    onError: (error, { reportId }, context) => {
      console.info(error)
      PeritosQueryClient.setQueryData(['reports', reportId, 'storedComments'], context.previousComments)
    },
    onSuccess: (data) => {
      console.info('mutation comments', data)
    },
    onSettled: (_, error, { reportId }) => {
      PeritosQueryClient.invalidateQueries({ queryKey: ['reports', reportId, 'storedComments'] })
    },
  })
}

export const useAddAnexosMutation = () => {
  return useMutation({
    mutationFn: addAnexos,
    onMutate: async ({ reportId }) => {
      await PeritosQueryClient.cancelQueries(['reports', reportId?.toString(), 'storedAnexos'])
    },
    onError: (error) => {
      console.info(error)
    },
    onSuccess: (data) => {
      console.info('mutation comments', data)
    },
    onSettled: (_, error, { reportId }) => {
      PeritosQueryClient.invalidateQueries({ queryKey: ['reports', reportId?.toString(), 'storedAnexos'], exact: true })
    },
  })
}

export const useUploadPhotosMutation = () => {
  return useMutation({
    mutationFn: addAnexos,
    onMutate: async ({ reportId, photos }) => {
      await PeritosQueryClient.cancelQueries({
        queryKey: ['reports', reportId?.toString(), 'storedAnexos'],
      })
    },
    onError: (error) => {
      console.info(error)
    },
    onSuccess: (data) => {
      console.info('mutation comments', data)
    },
    onSettled: (_, error, { reportId }) => {
      PeritosQueryClient.invalidateQueries({ queryKey: ['reports', reportId?.toString(), 'storedAnexos'] })
    },
  })
}

export const useUpdatePhotoMutation = () => {
  return useMutation({
    mutationFn: updatePhoto,
    onMutate: async ({ reportId, photos }) => {
      await PeritosQueryClient.cancelQueries({
        queryKey: ['reports', reportId?.toString(), 'storedAnexos'],
      })
    },
    onError: (error) => {
      console.info(error)
    },
    onSuccess: (data) => {
      console.info('mutation comments', data)
    },
    onSettled: (_, error, { reportId }) => {
      PeritosQueryClient.invalidateQueries({ queryKey: ['reports', reportId?.toString(), 'storedAnexos'] })
    },
  })
}

export const useUploadAttachmentsMutation = () => {
  return useMutation({
    mutationFn: addAnexos,
    onMutate: async ({ reportId, attachments }) => {
      await PeritosQueryClient.cancelQueries(['reports', reportId?.toString(), 'storedAnexos'])
    },
    onError: (error) => {
      console.info(error)
    },
    onSuccess: (data) => {
      console.info('mutation comments', data)
    },
    onSettled: (_, error, { reportId }) => {
      PeritosQueryClient.invalidateQueries({ queryKey: ['reports', reportId?.toString(), 'storedAnexos'], exact: true })
    },
  })
}

export const useRemoveStoredAttachment = () => {
  return useMutation({
    mutationFn: removeStoredAttachment,
    onMutate: async ({ reportId, key, attachmentId }) => {
      await PeritosQueryClient.cancelQueries(['reports', reportId?.toString(), 'storedAnexos'])
      const previousAnexos = PeritosQueryClient.getQueryData(['reports', reportId?.toString(), 'storedAnexos'])

      PeritosQueryClient.setQueryData(['reports', reportId?.toString(), 'storedAnexos'], (old) => {
        const newData = structuredClone(old[key] ?? [])
        return {
          ...(old ?? {}),
          [key]: newData.filter((file) => file.id !== attachmentId),
        }
      })

      return { previousAnexos }
    },
    onError: (error, { reportId }, context) => {
      console.info(error)
      PeritosQueryClient.setQueryData(['reports', reportId?.toString(), 'storedAnexos'], context.previousAnexos)
    },
    onSuccess: (data) => {
      console.info('mutation anexos', data)
    },
    onSettled: (_, error, { reportId }) => {
      PeritosQueryClient.invalidateQueries({ queryKey: ['reports', reportId?.toString(), 'storedAnexos'] })
    },
  })
}

export const useUpdateAnexos = () => {
  return useMutation({
    mutationFn: updateAnexos,
    onMutate: async ({ reportId, anexos }) => {
      await PeritosQueryClient.cancelQueries(['reports', reportId?.toString(), 'storedAnexos'])
      const previousAnexos = PeritosQueryClient.getQueryData(['reports', reportId?.toString(), 'storedAnexos'])

      PeritosQueryClient.setQueryData(['reports', reportId?.toString(), 'storedAnexos'], (old) => {
        const newData = structuredClone(old ?? {})
        for (const fileType in newData) {
          if (!newData[fileType]) {
            continue
          }
          newData[fileType] = newData[fileType].map((file) => {
            const newCaption = anexos[fileType]?.[file.id]?.caption
            return {
              ...file,
              caption: newCaption ? newCaption : file.caption,
            }
          })
        }
        return newData
      })

      return { previousAnexos }
    },
    onError: (error, { reportId }, context) => {
      console.info(error)
      if (!context.previousAnexos) {
        return
      }
      PeritosQueryClient.setQueryData(['reports', reportId?.toString(), 'storedAnexos'], context.previousAnexos)
    },
    onSuccess: (data) => {
      console.info('mutation anexos', data)
    },
    onSettled: (_, error, { reportId }) => {
      // PeritosQueryClient.invalidateQueries({ queryKey: ['reports', reportId?.toString(), 'storedAnexos'] })
    },
  })
}

export const useUpdateAnexosOrder = () => {
  return useMutation({
    mutationFn: updateAnexosOrder,
    onMutate: async ({ reportId, anexos }) => {
      await PeritosQueryClient.cancelQueries(['reports', reportId?.toString(), 'storedAnexos'])
    },
    onError: (error, { reportId }, context) => {
      console.info(error)
    },
    onSuccess: (data) => {
      console.info('mutation anexos', data)
    },
    onSettled: (_, error, { reportId }) => {
      PeritosQueryClient.invalidateQueries({ queryKey: ['reports', reportId?.toString(), 'storedAnexos'] })
    },
  })
}

export const useCloseReportMutation = () => {
  return useMutation({
    mutationFn: closeReport,
    onMutate: async ({ reportId }) => {
      await PeritosQueryClient.cancelQueries(['reports', reportId?.toString()])

      const previousStatus = PeritosQueryClient.getQueryData(['reports', reportId?.toString(), 'status'])

      PeritosQueryClient.setQueryData(['reports', reportId?.toString(), 'status'], (old) => {
        const newData = structuredClone(old ?? {})
        newData.isClosed = true
        return newData
      })

      return { previousStatus }
    },
    onError: (error, { reportId }, context) => {
      console.info(error)
      PeritosQueryClient.setQueryData(['reports', reportId?.toString(), 'status'], context.previousStatus)
    },
    onSuccess: (data) => {
      console.info('mutation close report', data)
    },
    onSettled: ({ reportId }) => {
      PeritosQueryClient.invalidateQueries({ queryKey: ['reports', reportId?.toString()] })
    },
  })
}

export const useOpenReportMutation = () => {
  return useMutation({
    mutationFn: openReport,
    onMutate: async ({ reportId }) => {
      await PeritosQueryClient.cancelQueries(['reports', reportId?.toString()])

      const previousStatus = PeritosQueryClient.getQueryData(['reports', reportId?.toString(), 'status'])

      PeritosQueryClient.setQueryData(['reports', reportId?.toString(), 'status'], (old) => {
        const newData = structuredClone(old ?? {})
        newData.isClosed = false
        return newData
      })

      return { previousStatus }
    },
    onError: (error, { reportId }, context) => {
      console.info(error)
      PeritosQueryClient.setQueryData(['reports', reportId?.toString(), 'status'], context.previousStatus)
    },
    onSuccess: (data) => {
      console.info('mutation open report', data)
    },
    onSettled: ({ reportId }) => {
      PeritosQueryClient.invalidateQueries({ queryKey: ['reports', reportId?.toString(), 'status'] })
    },
  })
}

export const useDownloadReportMutation = () => {
  return useMutation({
    mutationFn: downloadReport,
    onMutate: () => {},
    onError: (error) => {
      console.info(error)
    },
  })
}

export const useCloneReport = () => {
  return useMutation({
    mutationFn: cloneReport,
    onSuccess: (_, { to }) => {
      PeritosQueryClient.invalidateQueries({ queryKey: ['reports', to?.toString()] })
    },
    onError: (error) => {
      console.info(error)
    },
  })
}

export const useUpdateCapitales = () => {
  return useMutation({
    mutationFn: updateCapitales,
    onSuccess: ({ capitales, report }, { reportId }) => {
      PeritosQueryClient.setQueryData(['reports', reportId?.toString(), 'storedReport'], report)
      PeritosQueryClient.setQueryData(['reports', reportId?.toString(), 'capitales'], capitales)
    },
    onError: (error) => {
      console.info(error)
    },
  })
}

export const useAddCapitales = () => {
  return useMutation({
    mutationFn: addCapitales,
    onSuccess: (_, { reportId }) => {
      PeritosQueryClient.invalidateQueries({ queryKey: ['reports', reportId?.toString(), 'capitales'] })
    },
    onError: (error) => {
      console.info(error)
    },
  })
}

export const useAddCapital = () => {
  return useMutation({
    mutationFn: addCapital,
    onSuccess: (_, { reportId }) => {
      PeritosQueryClient.invalidateQueries({ queryKey: ['reports', reportId?.toString(), 'capitales'] })
    },
    onError: (error) => {
      console.info(error)
    },
  })
}

export const useEditCapital = () => {
  return useMutation({
    mutationFn: editCapital,
    onSuccess: (_, { reportId }) => {
      PeritosQueryClient.invalidateQueries({ queryKey: ['reports', reportId?.toString(), 'capitales'] })
    },
    onError: (error) => {
      console.info(error)
    },
  })
}

export const useDeleteCapital = () => {
  return useMutation({
    mutationFn: deleteCapital,
    onSuccess: (_, { reportId }) => {
      PeritosQueryClient.invalidateQueries({ queryKey: ['reports', reportId?.toString(), 'capitales'] })
    },
    onError: (error) => {
      console.info(error)
    },
  })
}
