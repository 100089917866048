import { memo } from 'react'
import { ToastContainer } from 'react-toastify'
import styled from 'styled-components/macro'

export const Toast = memo(styled(ToastContainer)`
  &&&.Toastify__toast-container {
    bottom: 0;
    left: 0;
    right: 0;
    transform: unset;
    padding: 0;
    width: 100%;
  }
  .Toastify__toast {
    margin: 0;
    border-radius: 0;
    min-height: 48px;
    padding: 14px;
    box-shadow: none;
  }
  .Toastify__toast-body {
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    justify-content: center;
  }
`)
