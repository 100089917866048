import React, { forwardRef, memo } from 'react'
import styled, { css } from 'styled-components/macro'
import { VARIANT } from '../../../theme/theme'
import { Label } from '../label/Label'
import { Text } from '../text/Text'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const InputWrapper = styled.div`
  display: flex;
  height: 48px;
  position: relative;
`

const StyledInput = styled.input`
  height: 48px;
  background-color: ${({ theme }) => theme?.colors?.[VARIANT.WHITE]?.main};
  border: solid 1px ${({ theme }) => theme?.colors?.[VARIANT.NTRL_LIGHT]?.main};
  border-radius: 4px;
  padding: 14px 16px;
  font-size: 14px;
  font-weight: 400;
  outline: none;
  box-sizing: border-box;
  width: 100%;

  ${({ hasIcon }) =>
    hasIcon &&
    css`
      padding-right: 58px;
    `}

  ${({ hasError }) =>
    hasError &&
    css`
      border-color: ${({ theme }) => theme?.colors?.[VARIANT.NEGATIVE]?.main};
    `}
`

const IconWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ErrorMessage = styled(Text)`
  margin-top: 4px;
`

export const Input = memo(
  forwardRef(({ className, id, label, name, icon, type, errorMessage, hasError, onChange, ...props }, ref) => {
    const extraProps = {}
    if (type === 'number') {
      extraProps.step = '.01'
    }

    const handleOnChange = React.useCallback(
      (evt) => {
        if (type === 'number') {
          // evt.target.value = evt.target.value?.replaceAll(',', '.') || ''
        }
        onChange?.(evt)
      },
      [onChange, type],
    )

    return (
      <Container className={className}>
        {label && <Label htmlFor={id ?? name}>{label}</Label>}
        <InputWrapper>
          <StyledInput
            ref={ref}
            id={id ?? name}
            name={name}
            hasIcon={React.isValidElement(icon)}
            hasError={hasError}
            type={type}
            onChange={handleOnChange}
            {...extraProps}
            {...props}
          />
          {React.isValidElement(icon) && <IconWrapper>{icon}</IconWrapper>}
        </InputWrapper>
        {hasError && errorMessage && (
          <ErrorMessage variant="negative" size="sm" weight="300">
            {errorMessage}
          </ErrorMessage>
        )}
      </Container>
    )
  }),
)

Input.defaultProps = {
  type: 'text',
  disabled: false,
  name: '',
  label: '',
  placeholder: '',
  hasError: false,
  errorMessage: '',
}

Input.displayName = 'Input'
