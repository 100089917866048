import React, { memo } from 'react'
import styled from 'styled-components/macro'

const Link = styled.a`
  outline: none;
  text-decoration: none;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary.main};
`

export const ExternalLink = memo((props) => {
  return <Link rel="noopener noreferrer" target="_blank" {...props} />
})

ExternalLink.displayName = 'ExternalLink'
