import React, { memo, useMemo } from 'react'
import styled, { css } from 'styled-components/macro'
import { Link as RNLink } from 'react-router-dom'

const StyledLink = styled.a`
  ${({ theme, $isActive }) => css`
    font-size: 16px;
    font-weight: ${$isActive ? 700 : 400};
    color: ${$isActive ? theme.colors.primary.main : theme.colors.ntrl_dark.main};
    transition: opacity 0.3s ease;

    display: flex;
    align-items: center;
    text-decoration: none;

    &:hover {
      opacity: 0.7;
    }
  `};
`

export const Link = memo(({ external, to, ...props }) => {
  const navigationProps = useMemo(() => {
    const navProps = {}
    if (external) {
      navProps.href = to
      navProps.rel = 'noopener noreferrer'
      navProps.target = '_blank'
    } else {
      navProps.to = to
    }
    return navProps
  }, [external, to])
  return <StyledLink as={external ? 'a' : RNLink} {...navigationProps} {...props} />
})

Link.defaultProps = {
  external: false,
}

Link.displayName = 'Link'
