import url from 'url'

class FetchHelper {
  _authtoken = null

  constructor({ authToken }) {
    this._authtoken = authToken
  }

  setAuthToken = ({ authToken }) => {
    this._authtoken = authToken
  }

  getAuthToken = () => {
    return this._authtoken
  }

  generateHeaders = ({ headers = {}, isAuthenticated = true, useDefaultHeaders = true }) => {
    const defaultHeaders = {
      'Content-Type': 'application/json',
      'x-language': 'es',
    }
    let generatedHeaders

    if (useDefaultHeaders) {
      generatedHeaders = {
        ...defaultHeaders,
      }
    }

    generatedHeaders = {
      ...generatedHeaders,
      ...headers,
    }

    if (isAuthenticated) {
      generatedHeaders['authorization'] = `Bearer ${this._authtoken}`
    }

    return generatedHeaders
  }

  fetch = (endpoint, { body = null, headers, ...rest }) => {
    const contentType = headers['content-type'] || headers['Content-Type']
    return fetch(url.resolve(window.MontesPeritos.config.REACT_APP_BASE_HOST, endpoint), {
      ...rest,
      headers,
      body: body && contentType === 'application/json' ? JSON.stringify(body) : body,
    })
  }
}

const RequestHandler = {
  sharedInstance: (function () {
    let singleton = null
    return function (options = {}) {
      if (!singleton) {
        singleton = new FetchHelper(options)
      }
      return singleton
    }
  })(),
}

export default RequestHandler
