import { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Input, Select, Text } from '../../../../../../components'
import { Comments } from '../../../../../../components/molecules/comments/Comments'
import ReportsAPI from '../../../../../../modules/reports/api'
import { useIsClosed, useStoredReport } from '../../../../../../modules/reports/hooks/useReports'
import { useUpdateReportMutation } from '../../../../../../modules/reports/mutations'
import { Headline } from '../../../components/headline'
import Actions from '../../components/actions/Actions'

const asistenciaOptions = [
  {
    label: '150',
    value: 150,
  },
  {
    label: '0',
    value: 0,
  },
  {
    label: '50',
    value: 50,
  },
]

const Container = styled.form`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const Inputs = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
`

const StyledInput = styled(Input)`
  width: 260px;
`

const StyledSelect = styled(Select)`
  width: 260px;
`

const Block = styled.article`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const STEP = 'factura'
export const FacturaScreen = () => {
  const navigate = useNavigate()
  const { reportId } = useParams()
  const { data: report } = useStoredReport(reportId)
  const isClosed = useIsClosed(reportId)
  const {
    getValues,
    control,
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      factura_Numero: report?.factura_Numero ?? '',
      factura_Fecha: report?.factura_Fecha ?? '',
      factura_CuantiaTasada_SinImpuestos: report?.factura_CuantiaTasada_SinImpuestos ?? 0,
      factura_AsistenciaJucio: report?.factura_AsistenciaJucio ?? '',
      factura_Otros: report?.factura_Otros ?? '',
      factura_HonorariosInvestigacionFraudes_CantidadDemostradaFehacientemente:
        report?.factura_HonorariosInvestigacionFraudes_CantidadDemostradaFehacientemente ?? 0,
      factura_HonorariosInvestigacionFraudes_APercibir: report?.factura_HonorariosInvestigacionFraudes_APercibir ?? 0,
      factura_HonorariosInvestigacionFraudes_TerminacionDesdeEncargo:
        report?.factura_HonorariosInvestigacionFraudes_TerminacionDesdeEncargo ?? 0,
    },
    mode: 'onBlur',
  })

  const updateStoredReportMutation = useUpdateReportMutation()

  const handleGoBack = useCallback(() => {
    !isClosed &&
      updateStoredReportMutation.mutate({
        ...report,
        ...getValues(),
      })
    navigate(-1)
  }, [updateStoredReportMutation, report, getValues, navigate, isClosed])

  const onSubmit = (values) => {
    setTimeout(() => {
      ReportsAPI.generateCaserReport({ report: report }).then(async (response) => {
        const blob = await response.blob()
        const filename = response.headers.get('Content-Disposition')
          ? response.headers.get('Content-Disposition').split('=')[1]
          : 'report.xls'

        const downloadUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'
        a.href = downloadUrl
        a.download = filename
        a.click()
        document.body.removeChild(a)
      })
    }, 1500)
  }

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Header>
        <Headline size="l" weight="500" variant="black">
          Factura
        </Headline>

        <Text variant="black">Especifíca los datos para la factura del siniestro.</Text>
      </Header>

      <Inputs>
        <StyledInput {...register('factura_Numero')} label="Número de factura" placeholder="Escribe aquí" type="text" />

        <StyledInput {...register('factura_Fecha')} label="Fecha" placeholder="Escribe aquí" type="date" />

        <StyledInput
          {...register('factura_CuantiaTasada_SinImpuestos')}
          label="Cuantía tasada"
          placeholder="Escribe aquí"
          type="number"
        />
      </Inputs>

      <Block>
        <Text variant="black">Gastos</Text>

        <Inputs>
          <Controller
            render={({ field }) => <StyledSelect label="Asistencia a juicio" options={asistenciaOptions} {...field} />}
            name="factura_AsistenciaJucio"
            control={control}
            defaultValue=""
          />

          <StyledInput {...register('factura_Otros')} label="Otros" placeholder="Escribe aquí" type="number" />
        </Inputs>
      </Block>

      <Block>
        <Text variant="black">Honorarios por investigación de fraudes</Text>

        <Inputs>
          <StyledInput
            {...register('factura_HonorariosInvestigacionFraudes_CantidadDemostradaFehacientemente')}
            label="Cantidad fraudulenta demostrada"
            placeholder="Escribe aquí"
            type="number"
          />

          <StyledInput
            {...register('factura_HonorariosInvestigacionFraudes_APercibir')}
            label="A percibir"
            placeholder="Escribe aquí"
            type="number"
          />

          <StyledInput
            {...register('factura_HonorariosInvestigacionFraudes_TerminacionDesdeEncargo')}
            label="Suplidos no sujetos a IVA o IGIC"
            placeholder="Escribe aquí"
            type="number"
          />
        </Inputs>
      </Block>

      <Actions isSubmitting={isSubmitting} nextLabel="Finalizar" onBackClick={handleGoBack} />

      <Comments step={STEP} />
    </Container>
  )
}
