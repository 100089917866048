import { memo } from 'react'
import styled, { css } from 'styled-components/macro'

export const Button = memo(styled.button`
  flex-shrink: 0;
  padding: 6px 24px;
  height: 48px;
  box-sizing: border-box;
  outline: none;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
  transition: opacity 0.3s ease;
  user-select: none;

  &:hover {
    opacity: 0.8;
  }

  ${({ theme, bordered, variant }) =>
    !bordered &&
    css`
      background-color: ${theme?.colors?.[variant]?.main};
      border: solid 1px transparent;
      color: ${theme?.colors?.[variant]?.contrast};
    `}

  ${({ theme, bordered, variant }) =>
    bordered &&
    css`
      background-color: transparent;
      border: solid 1px ${theme?.colors?.[variant]?.main};
      color: ${theme?.colors?.[variant]?.main};
    `}

  ${({ theme, disabled }) =>
    disabled &&
    css`
      background-color: ${theme?.colors?.ntrl_darker?.main};
      border: solid 1px ${theme?.colors?.ntrl_darker?.main};
      color: ${theme?.colors?.ntrl_darker?.contrast};
      pointer-events: none;
    `}
`)

Button.defaultProps = {
  bordered: false,
  disabled: false,
  type: 'button',
  variant: 'primary',
}

Button.displayName = 'Button'

export default Button
