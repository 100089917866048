import React from 'react'
import styled from 'styled-components'

const TagWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: ${({ theme, variant }) => theme.colors[variant]?.main};
  color: #fff;
`

const Tag = ({ className, variant, children }) => {
  return (
    <TagWrapper className={className} variant={variant}>
      {children}
    </TagWrapper>
  )
}

export default Tag
