import React from 'react'
import { Outlet } from 'react-router-dom'
import { RequireAuth } from './RequireAuth'

const PrivateRoute = () => {
  return (
    <RequireAuth>
      <Outlet />
    </RequireAuth>
  )
}

export default PrivateRoute
