import React, { useCallback, useEffect } from 'react'
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { AiOutlineDelete, AiOutlineEdit, AiOutlineFolderAdd } from 'react-icons/ai'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import styled, { useTheme } from 'styled-components/macro'
import { Alert, Button, Input, Select, Text } from '../../../../../../components'
import { Comments } from '../../../../../../components/molecules/comments/Comments'
import { useAutoSave } from '../../../../../../hooks/useAutoSave'
import {
  useIsClosed,
  useReportCapitales,
  useSaveReport,
  useStoredReport,
} from '../../../../../../modules/reports/hooks/useReports'
import { showBlockedReportdWarning } from '../../../../../../modules/reports/utils'
import { Headline } from '../../../components/headline'
import Actions from '../../components/actions/Actions'
import { HighlightedBlock } from '../../../../../../components/molecules/highlightedBlock/HighlightedBlock'

const Container = styled.form`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const Block = styled.section`
  display: flex;
  flex-direction: column;
  gap: 18px;
`

const StyledInput = styled(Input)`
  width: 260px;
`

const StyledSelect = styled(Select)`
  width: 260px;
`

const Inputs = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
`

const AddButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 0;
  height: initial;
  color: ${({ theme }) => theme.colors.secondary.main};
  gap: 6px;
  font-weight: 400;
`

const RemoveButton = styled(Button)`
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: center;
  padding: 0;
  color: ${({ theme }) => theme.colors.negative.main};
  gap: 6px;
  font-weight: 400;
  width: 48px;
`

const ReglaEquidadBlock = styled(Block)`
  flex-direction: row;
  align-items: center;
  gap: 12px;
`

const STEP = 'analisisCapitales'
export const AnalisisDeCapitalesScreen = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const { reportId } = useParams()
  const isClosed = useIsClosed(reportId)
  const { data: report } = useStoredReport(reportId)
  const { data: capitalesAsegurados, isLoading } = useReportCapitales(reportId)
  const {
    control,
    getValues,
    setValue,
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      analisisCapitales: report?.analisisCapitales ?? [],
      concurrenciaCapitales_Compania: report?.concurrenciaCapitales_Compania ?? '',
      concurrenciaCapitales_Poliza: report?.concurrenciaCapitales_Poliza ?? '',
      concurrenciaCapitales_Capitales: report?.concurrenciaCapitales_Capitales ?? '',
      concurrenciaCapitales_Porcentaje: report?.concurrenciaCapitales_Porcentaje ?? '',
      concurrenciaCapitales_GarantiaAfectada: report?.concurrenciaCapitales_GarantiaAfectada ?? '',
      compensacionCapitales: report?.compensacionCapitales ?? [],
      reglaProporcional: report?.reglaProporcional ?? [],
      reglaProporcional_DerogacionPorcentaje: report?.reglaProporcional_DerogacionPorcentaje ?? '0%',
      reglaProporcional_DerogacionPorcentaje_Custom: report?.reglaProporcional_DerogacionPorcentaje_Custom ?? 0,
      reglaProporcional_aplicada: report?.reglaProporcional_aplicada ?? 'valor_nuevo',
      reglaEquidad: report?.reglaEquidad ?? [],
    },
    mode: 'onBlur',
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'analisisCapitales',
  })
  const {
    fields: compensacionCapitalesFields,
    update: compensacionCapitalesUpdate,
    remove: compensacionCapitalesRemove,
  } = useFieldArray({
    control,
    name: 'compensacionCapitales',
  })
  const {
    fields: reglaProporcionalFields,
    update: reglaProporcionalUpdate,
    remove: reglaProporcionalRemove,
  } = useFieldArray({
    control,
    name: 'reglaProporcional',
  })
  const {
    fields: reglaEquidadFields,
    update: reglaEquidadUpdate,
    remove: reglaEquidadRemove,
  } = useFieldArray({
    control,
    name: 'reglaEquidad',
  })
  const analisisCapitalesWatch = useWatch({ control, name: 'analisisCapitales' })
  const infraseguroWatch = useWatch({ control, name: 'reglaProporcional_aplicada' })
  const derrogacionWatch = useWatch({ control, name: 'reglaProporcional_DerogacionPorcentaje' })

  const [reglaEquidad, setReglaEquidad] = React.useState(false)

  const handleAddCapital = useCallback(() => {
    append({
      capital: '',
    })
  }, [append])

  const handleSaveReport = useSaveReport()

  const { saveData } = useAutoSave()

  const handleFormChange = useCallback(() => {
    if (isClosed) {
      showBlockedReportdWarning()
      return
    }

    saveData({ id: STEP, reportId, data: getValues() })
  }, [getValues, isClosed, reportId, saveData])

  const handleGoBack = useCallback(() => {
    handleSaveReport({ data: getValues() })
    navigate(-1)
  }, [handleSaveReport, getValues, navigate])

  const onSubmit = (values) => {
    if (isClosed) {
      navigate(generatePath('/creation/:reportId/c/r/valoracion', { reportId }))
      return
    }

    return new Promise((resolve, reject) => {
      handleSaveReport({
        data: values,
        onSuccess: () => {
          resolve()
          navigate(generatePath('/creation/:reportId/c/r/valoracion', { reportId }))
        },
        onError: () => {
          reject()
        },
      })
    })
  }

  const handleEditCapitales = () => {
    navigate(generatePath('/creation/:reportId/c/r/capitales', { reportId }))
  }

  useEffect(() => {
    analisisCapitalesWatch?.forEach((capital, idx) => {
      const { depreciacion, valorNuevo, valorReal, asegurados } = capital

      if (!depreciacion || !valorNuevo) {
        return
      }

      const updatedValorReal = parseFloat(
        ((parseFloat(valorNuevo) ?? 0) * (1 - parseInt(depreciacion) / 100)).toFixed(2),
      )
      const isInfraseguro =
        parseFloat(asegurados) < parseFloat(valorNuevo) || parseFloat(asegurados) < parseFloat(updatedValorReal)
      if (parseFloat(valorReal) === updatedValorReal) {
        if (isInfraseguro) {
          setValue(`reglaProporcional.${idx}.asegurados`, asegurados)
        }
        return
      }
      setValue(`analisisCapitales.${idx}.valorReal`, updatedValorReal)

      let porcentajeTasaAplicada = 0

      if (parseFloat(asegurados) < parseFloat(valorNuevo) || parseFloat(asegurados) < parseFloat(updatedValorReal)) {
        const valor = infraseguroWatch === 'valor_nuevo' ? valorNuevo : updatedValorReal
        porcentajeTasaAplicada = parseFloat(
          (((parseFloat(valor) - parseFloat(asegurados)) * 100) / parseFloat(valor)).toFixed(2),
        )
      }

      const values = getValues()
      reglaProporcionalUpdate(idx, {
        ...(values?.reglaProporcional?.[idx] ?? {}),
        valorNuevo: parseFloat(valorNuevo) ?? 0,
        valorReal: updatedValorReal,
        porcentajeTasaAplicada,
      })

      setValue(`reglaProporcional.${idx}.valorReal`, updatedValorReal)
      setValue(`reglaProporcional.${idx}.valorNuevo`, valorNuevo)

      reglaEquidadUpdate(idx, {
        ...(values?.reglaEquidad?.[idx] ?? {}),
        valorNuevo: parseFloat(valorNuevo) ?? 0,
        valorReal: updatedValorReal,
      })
    })
  }, [analisisCapitalesWatch, infraseguroWatch, getValues, reglaProporcionalUpdate, reglaEquidadUpdate, setValue])

  useEffect(() => {
    const infraseguroAplicadoA = infraseguroWatch ?? 'valor_nuevo'
    const values = getValues()

    values.reglaProporcional?.forEach((reglaProporcional, idx) => {
      const { asegurados, valorNuevo, valorReal } = reglaProporcional
      const valor = infraseguroAplicadoA === 'valor_nuevo' ? valorNuevo : valorReal
      const infraseguroValue = parseFloat(
        (((parseFloat(valor) - parseFloat(asegurados)) * 100) / parseFloat(valor)).toFixed(2),
      )
      setValue(`reglaProporcional.${idx}.porcentajeTasaAplicada`, infraseguroValue)
    })
  }, [infraseguroWatch, setValue, getValues])

  useEffect(() => {
    if (derrogacionWatch !== 'CUSTOM') {
      setValue('reglaProporcional_DerogacionPorcentaje_Custom', 0)
    }
  }, [derrogacionWatch, setValue])

  const hasCapitalesAsegurados = Object.keys(capitalesAsegurados ?? {}).length !== 0
  const shouldRenderAddCapitalButton = analisisCapitalesWatch.length !== Object.keys(capitalesAsegurados ?? {}).length

  return (
    <Container onChange={handleFormChange} onSubmit={handleSubmit(onSubmit)}>
      <Header>
        <Headline size="l" weight="600" variant="black">
          Análisis de capitales
        </Headline>

        <Text variant="black">Recuerda que este apartado depende del informe pericial que hayas seleccionado</Text>

        {!isLoading ? (
          <AddButton type="button" variant="transparent" onClick={handleEditCapitales}>
            <AiOutlineEdit size={20} color={theme?.colors?.secondary?.main} />
            Modificar capitales
          </AddButton>
        ) : null}
      </Header>

      {isLoading && <Alert variant="info" headline="Cargando..." body="Estamos recuperando los capitales asegurados" />}

      {!isLoading && !hasCapitalesAsegurados && (
        <Alert variant="negative" headline="Oops!" body="Este expediente no tiene capitales asegurados..." />
      )}

      {!isLoading && hasCapitalesAsegurados && (
        <>
          {fields.map((item, idx) => {
            return (
              <HighlightedBlock key={`capital-${idx}`}>
                <Inputs>
                  <Controller
                    name={`analisisCapitales.${idx}.capital`}
                    control={control}
                    render={({ field: { value, onChange, onBlur } }) => {
                      return (
                        <StyledSelect
                          label="Capital"
                          name={`analisisCapitales.${idx}.capital`}
                          options={Object.keys(capitalesAsegurados).map((capital) => {
                            return {
                              label: capital,
                              value: capital,
                            }
                          })}
                          onChange={(value) => {
                            const capital = capitalesAsegurados[value]
                            setValue(`analisisCapitales.${idx}.asegurados`, capital, {
                              shouldDirty: true,
                              shouldTouch: true,
                            })

                            // Compensacion de capitales
                            compensacionCapitalesUpdate(idx, {
                              capital: value,
                              asegurados: capital,
                              valorNuevo: 0,
                              prima: 0,
                              capitalSobrante: 0,
                              primaCapitalSobrante: 0,
                              capitalACompensar: 0,
                            })

                            // Reglas proporcionales
                            reglaProporcionalUpdate(idx, {
                              capital: value,
                              asegurados: capital,
                              valorReal: 0,
                              valorNuevo: 0,
                              porcentajeTasaAplicada: 0,
                              tasaAplicada: 0,
                            })

                            // Reglas equidad
                            reglaEquidadUpdate(idx, {
                              capital: value,
                              asegurados: capital,
                              valorReal: 0,
                              valorNuevo: 0,
                              porcentajeTasaAplicable: 0,
                              tasaAplicable: 0,
                            })
                            onChange?.(value)
                            handleFormChange()
                          }}
                          onBlur={onBlur}
                          value={value}
                        />
                      )
                    }}
                  />

                  <StyledInput
                    {...register(`analisisCapitales.${idx}.asegurados`)}
                    label="Asegurados"
                    placeholder="Escribe aquí"
                    type="number"
                  />

                  <StyledInput
                    {...register(`analisisCapitales.${idx}.valorNuevo`)}
                    label="Valor nuevo"
                    placeholder="Escribe aquí"
                    type="number"
                  />

                  <StyledInput
                    {...register(`analisisCapitales.${idx}.depreciacion`)}
                    label="Depreciación %"
                    placeholder="Escribe aquí"
                    type="number"
                  />

                  <StyledInput
                    {...register(`analisisCapitales.${idx}.valorReal`)}
                    label="Valor real"
                    type="number"
                    disabled
                  />

                  <RemoveButton
                    type="button"
                    variant="negative"
                    onClick={() => {
                      remove(idx)
                      compensacionCapitalesRemove(idx)
                      reglaProporcionalRemove(idx)
                      reglaEquidadRemove(idx)
                    }}
                  >
                    <AiOutlineDelete size={20} color={theme?.colors?.white?.main} />
                  </RemoveButton>
                </Inputs>
              </HighlightedBlock>
            )
          })}

          {shouldRenderAddCapitalButton && (
            <AddButton type="button" variant="transparent" onClick={handleAddCapital}>
              <AiOutlineFolderAdd size={20} color={theme?.colors?.secondary?.main} />
              Añadir capital al informe
            </AddButton>
          )}
        </>
      )}

      <Block>
        <Text variant="black" weight="600" size="m">
          Concurrencia de capitales
        </Text>
        <Inputs>
          <StyledInput
            {...register('concurrenciaCapitales_Compania')}
            label="Compañía"
            placeholder="Escribe aquí"
            type="text"
          />

          <StyledInput
            {...register('concurrenciaCapitales_Poliza')}
            label="Poliza"
            placeholder="Escribe aquí"
            type="text"
          />

          <StyledInput
            {...register('concurrenciaCapitales_Capitales')}
            label="Capitales"
            placeholder="Escribe aquí"
            type="text"
          />

          <StyledInput
            {...register('concurrenciaCapitales_Porcentaje')}
            label="Porcentaje %"
            placeholder="Escribe aquí"
            type="text"
          />

          <StyledInput
            {...register('concurrenciaCapitales_GarantiaAfectada')}
            label="Garantía afectada"
            placeholder="Escribe aquí"
            type="text"
          />
        </Inputs>
      </Block>

      {compensacionCapitalesFields?.length > 0 && (
        <>
          <Text variant="black" weight="600" size="m">
            Compensación de capital
          </Text>

          {compensacionCapitalesFields.map((field, idx) => {
            return (
              <HighlightedBlock key={`compensacion-capital-${idx}`}>
                <Inputs>
                  <StyledInput
                    {...register(`compensacionCapitales.${idx}.capital`)}
                    label="Capital"
                    placeholder="Escribe aquí"
                    type="text"
                    disabled
                  />

                  <StyledInput
                    {...register(`compensacionCapitales.${idx}.asegurados`)}
                    label="Asegurados"
                    placeholder="Escribe aquí"
                    type="number"
                    disabled
                  />

                  <StyledInput
                    {...register(`compensacionCapitales.${idx}.valorNuevo`)}
                    label="Valor nuevo"
                    placeholder="Escribe aquí"
                    type="number"
                  />

                  <StyledInput
                    {...register(`compensacionCapitales.${idx}.prima`)}
                    label="Prima"
                    placeholder="Escribe aquí"
                    type="number"
                  />

                  <StyledInput
                    {...register(`compensacionCapitales.${idx}.capitalSobrante`)}
                    label="Capital sobrante"
                    placeholder="Escribe aquí"
                    type="number"
                  />

                  <StyledInput
                    {...register(`compensacionCapitales.${idx}.primaCapitalSobrante`)}
                    label="Prima capital sobrante"
                    placeholder="Escribe aquí"
                    type="number"
                  />

                  <StyledInput
                    {...register(`compensacionCapitales.${idx}.capitalACompensar`)}
                    label="Capital a compensar"
                    placeholder="Escribe aquí"
                    type="number"
                  />
                </Inputs>
              </HighlightedBlock>
            )
          })}
        </>
      )}

      <Block>
        <Text variant="black" weight="600" size="m">
          Regla Proporcional
        </Text>

        <Inputs>
          <Controller
            name="reglaProporcional_DerogacionPorcentaje"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => {
              return (
                <StyledSelect
                  label="Derogación de Regla Proporcional al:"
                  name="reglaProporcional_DerogacionPorcentaje"
                  value={value}
                  onChange={(value) => {
                    onChange(value)
                    handleFormChange()
                  }}
                  onBlur={onBlur}
                  options={[
                    {
                      label: '0%',
                      value: '0%',
                    },
                    {
                      label: '10%',
                      value: '10%',
                    },
                    {
                      label: '15%',
                      value: '15%',
                    },
                    {
                      label: '30%',
                      value: '30%',
                    },
                    {
                      label: '100%',
                      value: '100%',
                    },
                    {
                      label: 'Personalizado',
                      value: 'CUSTOM',
                    },
                  ]}
                />
              )
            }}
          />

          {derrogacionWatch === 'CUSTOM' && (
            <StyledInput
              {...register('reglaProporcional_DerogacionPorcentaje_Custom')}
              label="Derrogación personalizada:"
              placeholder="Escribe aquí"
              type="number"
              min={0}
            />
          )}

          <Controller
            name="reglaProporcional_aplicada"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => {
              return (
                <StyledSelect
                  label="Infraseguro aplicado a:"
                  name="reglaProporcional_aplicada"
                  value={value}
                  onChange={(value) => {
                    onChange(value)
                    handleFormChange()
                  }}
                  onBlur={onBlur}
                  options={[
                    {
                      label: 'Valor Nuevo',
                      value: 'valor_nuevo',
                    },
                    {
                      label: 'Valor Real',
                      value: 'valor_real',
                    },
                  ]}
                />
              )
            }}
          />
        </Inputs>
      </Block>

      {reglaProporcionalFields.map((field, idx) => {
        const { asegurados, valorNuevo, valorReal } = field

        if (!valorNuevo || !valorReal) return null

        if (
          getValues('reglaProporcional_aplicada') === 'valor_nuevo' &&
          parseFloat(asegurados) >= parseFloat(valorNuevo)
        ) {
          return null
        }

        if (
          getValues('reglaProporcional_aplicada') === 'valor_real' &&
          parseFloat(asegurados) >= parseFloat(valorReal)
        ) {
          return null
        }

        return (
          <HighlightedBlock key={`regla-proporcional-capital-${idx}`}>
            <Inputs>
              <StyledInput
                {...register(`reglaProporcional.${idx}.capital`)}
                label="Capital"
                placeholder="Escribe aquí"
                type="text"
                disabled
              />

              <StyledInput
                {...register(`reglaProporcional.${idx}.asegurados`, { deps: [`analisisCapitales.${idx}.asegurados`] })}
                label="Asegurados"
                placeholder="Escribe aquí"
                type="number"
                disabled
              />

              <StyledInput
                {...register(`reglaProporcional.${idx}.valorReal`, { value: valorReal })}
                label="Valor real"
                placeholder="Escribe aquí"
                type="number"
                disabled
              />

              <StyledInput
                {...register(`reglaProporcional.${idx}.valorNuevo`, { value: valorNuevo })}
                label="Valor nuevo"
                placeholder="Escribe aquí"
                type="number"
                disabled
              />

              <StyledInput
                {...register(`reglaProporcional.${idx}.porcentajeTasaAplicada`)}
                label="%"
                placeholder="Escribe aquí"
                type="number"
              />

              <StyledInput
                {...register(`reglaProporcional.${idx}.tasaAplicada`)}
                label="Tasa Aplicada"
                placeholder="Escribe aquí"
                type="number"
              />
            </Inputs>
          </HighlightedBlock>
        )
      })}

      {reglaEquidadFields?.length > 0 && (
        <ReglaEquidadBlock>
          <Text variant="black" weight="600" size="m">
            Regla de Equidad
          </Text>
          <AddButton type="button" variant="transparent" onClick={() => setReglaEquidad((old) => !old)}>
            {reglaEquidad && <BsChevronUp size={20} color={theme?.colors?.secondary?.main} />}
            {!reglaEquidad && <BsChevronDown size={20} color={theme?.colors?.secondary?.main} />}
          </AddButton>
        </ReglaEquidadBlock>
      )}

      {reglaEquidad &&
        reglaEquidadFields.map((field, idx) => {
          return (
            <HighlightedBlock key={`regla-equidad-capital-${idx}`}>
              <Inputs>
                <StyledInput
                  {...register(`reglaEquidad.${idx}.capital`)}
                  label="Capital"
                  placeholder="Escribe aquí"
                  type="text"
                  disabled
                />

                <StyledInput
                  {...register(`reglaEquidad.${idx}.asegurados`)}
                  label="Asegurados"
                  placeholder="Escribe aquí"
                  type="number"
                  disabled
                />

                <StyledInput
                  {...register(`reglaEquidad.${idx}.valorReal`)}
                  label="Valor real"
                  placeholder="Escribe aquí"
                  type="number"
                  disabled
                />

                <StyledInput
                  {...register(`reglaEquidad.${idx}.valorNuevo`)}
                  label="Valor nuevo"
                  placeholder="Escribe aquí"
                  type="number"
                  disabled
                />

                <StyledInput
                  {...register(`reglaEquidad.${idx}.porcentajeTasaAplicable`)}
                  label="%"
                  placeholder="Escribe aquí"
                  type="number"
                />

                <StyledInput
                  {...register(`reglaEquidad.${idx}.tasaAplicable`)}
                  label="Tasa Aplicable"
                  placeholder="Escribe aquí"
                  type="number"
                />
              </Inputs>
            </HighlightedBlock>
          )
        })}

      <Actions isSubmitting={isSubmitting} onBackClick={handleGoBack} />

      <Comments step={STEP} />
    </Container>
  )
}
