import { useCallback, useReducer } from 'react'

const OPEN_MODAL = 'OPEN_MODAL'
const CLOSE_MODAL = 'CLOSE_MODAL'

export const useModal = () => {
  const [state, dispatch] = useReducer(
    (prevState, { type, payload }) => {
      switch (type) {
        case OPEN_MODAL:
          return {
            ...prevState,
            isOpen: true,
            params: payload?.params ?? {},
          }

        case CLOSE_MODAL:
          return {
            ...prevState,
            isOpen: false,
            params: {},
          }

        default:
          return prevState
      }
    },
    {
      isOpen: false,
      params: {},
    },
  )

  const showModal = useCallback((params) => {
    dispatch({ type: OPEN_MODAL, payload: { params } })
  }, [])

  const closeModal = useCallback(() => {
    dispatch({ type: CLOSE_MODAL })
  }, [])

  return [state, { showModal, closeModal }]
}
