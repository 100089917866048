import Logo from '../../../../atoms/logo/Logo'
import styled from 'styled-components/macro'

const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`

export const Splash = () => {
  return (
    <Container>
      <Logo />
    </Container>
  )
}
