import { createContext, useCallback, useContext, useEffect, useReducer } from 'react'
import CookieService from '../cookies'
import RequestHandler from '../request'
import { initialState, reducer, SET_RESULT, STATUS } from './reducer'

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const login = useCallback(async ({ username, password }) => {
    const { fetch, generateHeaders, setAuthToken } = RequestHandler.sharedInstance()
    const response = await fetch('login', {
      method: 'POST',
      headers: generateHeaders({ headers: {}, isAuthenticated: false }),
      body: {
        user: username,
        pass: password,
      },
    })

    try {
      const { token, ...data } = await response.json()
      if (!token) {
        throw new Error('Wrong response')
      }

      setAuthToken({ authToken: token })
      CookieService.setCookie(token)
      dispatch({ type: SET_RESULT, payload: { status: STATUS.AUTHORIZED, user: data } })
      return data
    } catch (error) {
      dispatch({ type: SET_RESULT, payload: { status: STATUS.UNAUTHORIZED, user: null } })
    }
  }, [])

  const logout = useCallback(() => {
    const { setAuthToken } = RequestHandler.sharedInstance()
    CookieService.deleteCookie()
    setAuthToken({ authToken: null })
    dispatch({ type: SET_RESULT, payload: { status: STATUS.UNAUTHORIZED, user: null } })
  }, [])

  useEffect(() => {
    const checkCookieChanged = () => {
      const readCookie = CookieService.readCookie()
      if (document.visibilityState === 'visible' && CookieService.currentCookie !== readCookie) {
        window.location.reload()
      }
    }

    document.addEventListener('visibilitychange', checkCookieChanged)
    return () => {
      document.removeEventListener('visibilitychange', checkCookieChanged)
    }
  }, [])

  useEffect(() => {
    const loginSilently = async () => {
      const token = CookieService.readCookie()
      if (!token) {
        dispatch({ type: SET_RESULT, payload: { status: STATUS.UNAUTHORIZED } })
        return
      }

      const { fetch, generateHeaders, setAuthToken } = RequestHandler.sharedInstance()
      setAuthToken({ authToken: token })

      const response = await fetch('login/me', {
        headers: generateHeaders({ headers: {} }),
      })

      if (response.status !== 200) {
        dispatch({ type: SET_RESULT, payload: { status: STATUS.UNAUTHORIZED } })
        return
      }

      try {
        const { token, ...data } = await response.json()
        setAuthToken({ authToken: token })
        CookieService.setCookie(token)
        dispatch({ type: SET_RESULT, payload: { status: STATUS.AUTHORIZED, user: data } })
      } catch (error) {
        CookieService.deleteCookie()
        dispatch({ type: SET_RESULT, payload: { status: STATUS.UNAUTHORIZED } })
      }
    }

    loginSilently()
  }, [])

  const value = {
    ...state,
    login,
    logout,
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  const ctx = useContext(AuthContext)

  if (!ctx) {
    throw Error('The `useAuth` hook must be called from a descendent of the `AuthProvider`.')
  }

  return ctx
}

export const useIsAdmin = () => {
  const { user } = useAuth()
  return user?.isAdmin
}
