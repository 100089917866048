import React from 'react'
import Button from '../../../atoms/button/Button'
import { ConfirmationModal } from '../../modal/ConfirmationModal'

export const CloneReportModal = ({ isOpen, onCancel, isWorking, onConfirm }) => {
  return (
    <ConfirmationModal isOpen={isOpen}>
      <ConfirmationModal.Title>¿Estás seguro?</ConfirmationModal.Title>
      <ConfirmationModal.Subtitle>
        Si continuas eliminarás cualquier cambio que hayas realizado en el expediente actual
      </ConfirmationModal.Subtitle>

      <ConfirmationModal.Actions>
        <Button onClick={onCancel} disabled={isWorking}>
          Cancelar
        </Button>
        <Button variant="negative" onClick={onConfirm} disabled={isWorking}>
          {isWorking ? 'Copiando...' : 'Reutilizar'}
        </Button>
      </ConfirmationModal.Actions>
    </ConfirmationModal>
  )
}
