import React, { useCallback } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { AiOutlineCopy, AiOutlineDelete, AiOutlineEdit, AiOutlineFolderAdd } from 'react-icons/ai'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import styled, { css, useTheme } from 'styled-components/macro'
import {
  Button,
  Dropdown,
  DropdownOption,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
} from '../../../../../../components'
import { Comments } from '../../../../../../components/molecules/comments/Comments'
import { useAutoSave } from '../../../../../../hooks/useAutoSave'
import { useIsClosed, useSaveReport, useStoredReport } from '../../../../../../modules/reports/hooks/useReports'
import { showBlockedReportdWarning } from '../../../../../../modules/reports/utils'
import Utils from '../../../../../../services/Utils'
import { Headline } from '../../../components/headline'
import Actions from '../../components/actions/Actions'

const Container = styled.form`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const TableWrapper = styled.div`
  /* overflow: auto; */
`

const StyledTableRow = styled(TableRow)`
  background-color: ${({ theme }) => `${theme?.colors?.ntrl_lighter?.main}`};
`

const TotalTableRow = styled(TableRow)`
  & td {
    padding: 10px 20px;
  }
`

const StyledTableCell = styled(TableCell)`
  padding: 10px 20px;
`

const PerjudicadoTableCell = styled(TableCell)`
  padding: 3px 20px;
`

const StyledDropdown = styled(Dropdown)`
  top: 60px;
  right: 20px;
  z-index: 99;
`

const StyledDropdownOption = styled(DropdownOption)`
  display: flex;
  align-items: center;
  gap: 6px;

  ${({ isDangerAction }) =>
    isDangerAction &&
    css`
      color: ${({ theme }) => theme.colors.negative.main};
    `}
`

const AddButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 0;
  height: initial;
  color: ${({ theme }) => theme.colors.secondary.main};
  gap: 6px;
  font-weight: 400;
`

const Summary = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 12px;
`

const StyledInput = styled(Input)`
  width: 200px;
`

const STEP = 'valoracionDanios'
export const ValoracionScreen = () => {
  const navigate = useNavigate()
  const { reportId } = useParams()
  const isClosed = useIsClosed(reportId)
  const { data: report } = useStoredReport(reportId)
  const {
    getValues,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      valoracionDanios_Table: report?.valoracionDanios_Table ?? [],
    },
    mode: 'onBlur',
  })
  const { fields, remove } = useFieldArray({
    control,
    name: 'valoracionDanios_Table',
  })
  const theme = useTheme()
  const { saveData } = useAutoSave()

  const handleRequestAddValoracion = useCallback(
    ({ initialData = {}, isEditionMode = false, idx }) => {
      navigate(generatePath('/creation/:reportId/c/r/valoracion/add', { reportId }), {
        state: { initialData, isEditionMode, idx },
      })
    },
    [navigate, reportId],
  )

  const handleSaveReport = useSaveReport()

  const handleFormChange = useCallback(() => {
    if (isClosed) {
      showBlockedReportdWarning()
      return
    }

    saveData({ id: STEP, reportId, data: getValues() })
  }, [getValues, isClosed, reportId, saveData])

  const handleGoBack = useCallback(() => {
    handleSaveReport({ data: getValues() })
    navigate(-1)
  }, [handleSaveReport, getValues, navigate])

  const onSubmit = (values) => {
    if (isClosed) {
      navigate(generatePath('/creation/:reportId/c/r/propuesta-final', { reportId }))
      return
    }

    return new Promise((resolve, reject) => {
      handleSaveReport({
        data: values,
        onSuccess: () => {
          resolve()
          navigate(generatePath('/creation/:reportId/c/r/propuesta-final', { reportId }))
        },
        onError: () => {
          reject()
        },
      })
    })
  }

  const renderItem = useCallback(
    (item) => {
      const idx = fields.findIndex((i) => i.id === item.id)
      return (
        <TableRow key={item.id}>
          <TableCell>
            <Text variant="black" size="sm">
              {item.Id}
            </Text>
          </TableCell>

          <TableCell>
            <Text variant="black" size="sm">
              {item.Descripcion}
            </Text>
          </TableCell>

          <TableCell>
            <Text variant="black" size="sm">
              {item.RepInd}
            </Text>
          </TableCell>

          <TableCell>
            <Text variant="black" size="sm">
              {item.Unidades}
            </Text>
          </TableCell>

          <TableCell>
            <Text variant="black" size="sm">
              {item.CosteUnidad}
            </Text>
          </TableCell>

          <TableCell>
            <Text variant="black" size="sm">
              {item.ValorNuevo}
            </Text>
          </TableCell>

          <TableCell>
            <Text variant="black" size="sm">
              {item.ValorReal}
            </Text>
          </TableCell>

          <TableCell>
            <Text variant="black" size="sm">
              {item.TotalPropuesta}
            </Text>
          </TableCell>

          <TableCell>
            <StyledDropdown>
              <StyledDropdownOption
                onClick={() => handleRequestAddValoracion({ initialData: item, isEditionMode: true, idx })}
              >
                <AiOutlineEdit color={theme?.colors?.ntrl_dark?.main} size={20} />
                Editar
              </StyledDropdownOption>
              <StyledDropdownOption onClick={() => handleRequestAddValoracion({ initialData: { ...item } })}>
                <AiOutlineCopy color={theme?.colors?.ntrl_dark?.main} size={20} />
                Duplicar
              </StyledDropdownOption>
              <StyledDropdownOption isDangerAction onClick={() => remove(idx)}>
                <AiOutlineDelete color={theme?.colors?.negative?.main} size={20} />
                Eliminar
              </StyledDropdownOption>
            </StyledDropdown>
          </TableCell>
        </TableRow>
      )
    },
    [fields, theme?.colors?.ntrl_dark?.main, theme?.colors?.negative?.main, handleRequestAddValoracion, remove],
  )

  const renderTotalRow = useCallback((data) => {
    const { unidades, valorNuevo, valorReal, total } = data.reduce(
      (summary, item) => {
        summary.unidades += parseInt(item.Unidades)
        summary.valorNuevo = summary.valorNuevo.add(Utils.toDinero(parseFloat(item.ValorNuevo)))
        summary.valorReal = summary.valorReal.add(Utils.toDinero(parseFloat(item.ValorReal)))
        summary.total = summary.total.add(Utils.toDinero(parseFloat(item.TotalPropuesta)))
        return summary
      },
      { unidades: 0, valorNuevo: Utils.toDinero(0), valorReal: Utils.toDinero(0), total: Utils.toDinero(0) },
    )
    return (
      <TotalTableRow>
        <TableCell>
          <Text variant="black" size="sm" weight="600">
            Resumen
          </Text>
        </TableCell>

        <TableCell></TableCell>

        <TableCell></TableCell>

        <TableCell>
          <Text variant="black" size="sm">
            {unidades}
          </Text>
        </TableCell>

        <TableCell></TableCell>

        <TableCell>
          <Text variant="black" size="sm">
            {valorNuevo.convertPrecision(2).toUnit()}
          </Text>
        </TableCell>

        <TableCell>
          <Text variant="black" size="sm">
            {valorReal.convertPrecision(2).toUnit()}
          </Text>
        </TableCell>

        <TableCell>
          <Text variant="black" size="sm">
            {total.convertPrecision(2).toUnit()}
          </Text>
        </TableCell>

        <TableCell></TableCell>
      </TotalTableRow>
    )
  }, [])

  const rows = fields ?? []
  const propios = rows.filter((item) => item.TipoDanio === 'propio')
  const esteticos = rows.filter((item) => item.TipoDanio === 'estetico')
  const perjudicados = rows
    .filter((item) => item.TipoDanio === 'perjudicados')
    .reduce((perjudicados, item) => {
      if (!perjudicados[item.Perjudicado]) {
        perjudicados[item.Perjudicado] = []
      }
      perjudicados[item.Perjudicado].push(item)
      return perjudicados
    }, {})

  const totalMinutar = React.useMemo(() => {
    return fields?.reduce((acc, item) => {
      if (item?.ValorNuevo < 0) {
        return acc
      }
      return acc.add(Utils.toDinero(parseFloat(item?.ValorNuevo)))
    }, Utils.toDinero(0))
  }, [fields])

  return (
    <Container onChange={handleFormChange} onSubmit={handleSubmit(onSubmit)}>
      <Header>
        <Headline size="l" weight="500" variant="black">
          Valoración de daños
        </Headline>

        <Text variant="black">Añade las valoraciones de los daños producidos por el siniestro.</Text>
      </Header>

      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Text variant="black" weight="500" size="sm">
                  Epígrafe
                </Text>
              </TableCell>

              <TableCell>
                <Text variant="black" weight="500" size="sm">
                  Descripción
                </Text>
              </TableCell>

              <TableCell>
                <Text variant="black" weight="500" size="sm">
                  Rep.Ind.
                </Text>
              </TableCell>

              <TableCell>
                <Text variant="black" weight="500" size="sm">
                  Unidades
                </Text>
              </TableCell>

              <TableCell>
                <Text variant="black" weight="500" size="sm">
                  Coste Ud.
                </Text>
              </TableCell>

              <TableCell>
                <Text variant="black" weight="500" size="sm">
                  V. Nuevo
                </Text>
              </TableCell>

              <TableCell>
                <Text variant="black" weight="500" size="sm">
                  V. Real
                </Text>
              </TableCell>

              <TableCell>
                <Text variant="black" weight="500" size="sm">
                  Total
                </Text>
              </TableCell>

              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {propios.length > 0 && (
              <>
                <StyledTableRow>
                  <StyledTableCell colSpan="9">
                    <Text variant="black" weight="600" size="sm">
                      Daños propios
                    </Text>
                  </StyledTableCell>
                </StyledTableRow>

                {propios.map(renderItem)}

                {renderTotalRow(propios)}
              </>
            )}

            {esteticos.length > 0 && (
              <>
                <StyledTableRow>
                  <StyledTableCell colSpan="9">
                    <Text variant="black" weight="600" size="sm">
                      Daños estéticos
                    </Text>
                  </StyledTableCell>
                </StyledTableRow>

                {esteticos.map(renderItem)}

                {renderTotalRow(esteticos)}
              </>
            )}

            {Object.keys(perjudicados).length > 0 && (
              <>
                <StyledTableRow>
                  <StyledTableCell colSpan="9">
                    <Text variant="black" weight="600" size="sm">
                      Daños a perjudicados
                    </Text>
                  </StyledTableCell>
                </StyledTableRow>
                {Object.keys(perjudicados).map((perjudicadoId, idx) => {
                  const rows = perjudicados[perjudicadoId]
                  const perjudicado = report.perjudicados.find((p) => p.perjudicados_id === perjudicadoId)
                  return (
                    <React.Fragment key={perjudicado}>
                      <StyledTableRow>
                        <PerjudicadoTableCell colSpan="9">
                          <Text variant="black" weight="600" size="sm">
                            {`Perjudicado nº ${idx + 1} - ${perjudicado?.perjudicados_nombre ?? 'Desconocido'}`}
                          </Text>
                        </PerjudicadoTableCell>
                      </StyledTableRow>
                      {rows.map(renderItem)}
                      {renderTotalRow(rows)}
                    </React.Fragment>
                  )
                })}
              </>
            )}
            <TableRow>
              {!isClosed && (
                <TableCell colSpan="9">
                  <AddButton type="button" variant="transparent" onClick={handleRequestAddValoracion}>
                    <AiOutlineFolderAdd size={20} color={theme?.colors?.secondary?.main} />
                    Añadir valoración
                  </AddButton>
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableWrapper>

      <Summary>
        <Text variant="black" weight="600" size="sm">
          Total importe valorado para Minutar
        </Text>
        <StyledInput disabled value={totalMinutar.convertPrecision(2).toUnit()} />
      </Summary>

      <Actions isSubmitting={isSubmitting} onBackClick={handleGoBack} />

      <Comments step={STEP} />
    </Container>
  )
}
