import React from 'react'
import ReactMarkdown from 'react-markdown'
import { useChangelog } from '../../modules/app/hooks'
import { Headline } from '../dashboard/creation/components/headline'
import styled from 'styled-components'

const Content = styled.section`
  margin-top: 30px;
`

const ChangelogView = () => {
  const { data: markdown, isLoading } = useChangelog()

  return (
    <>
      <Headline size="l" weight="500" variant="black">
        Listado de versiones
      </Headline>

      <Content>{isLoading ? 'Cargando...' : <ReactMarkdown children={markdown} />}</Content>
    </>
  )
}

export default React.memo(ChangelogView)
