import { createGlobalStyle } from 'styled-components'

export const GlobalCSS = createGlobalStyle`
  * {
    font-family: ${({ theme }) => theme.font.fontFamily.default}
  }

  :root {
    --toastify-color-error: ${({ theme }) => theme?.colors?.negative?.main};
    --toastify-color-warning: ${({ theme }) => theme?.colors?.warning?.main};
    --toastify-color-success: ${({ theme }) => theme?.colors?.positive?.main};
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
    z-index: 999;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }
`
