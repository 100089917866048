import React, { memo } from 'react'
import { generatePath, Outlet, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Text } from '../../../../components'
import Logo from '../../../../components/atoms/logo/Logo'
import { ErrorPage } from '../../../../components/molecules/errorPage/ErrorPage'
import {
  useRelatedReports,
  useReportById,
  useReportCapitales,
  useStoredComments,
  useStoredReport,
} from '../../../../modules/reports/hooks/useReports'

const Container = styled.main`
  height: 100vh;
  overflow: hidden;
`

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  gap: 18px;
`

export const DashboardLayout = memo(({ className, children }) => {
  const navigate = useNavigate()
  const { reportId } = useParams()
  const { data: expediente, isLoading: isLoadingReport } = useReportById(reportId)
  const { data: report, isLoading: isReportLoading } = useStoredReport(reportId)
  const { data: relatedReports, isLoading: isLoadingRelatedReports } = useRelatedReports(reportId)
  const { isLoading: isLoadingComments } = useStoredComments(reportId)
  const { isLoading: isLoadingCapitales } = useReportCapitales(reportId, { enabled: !!report })
  const isLoading =
    isLoadingComments || isReportLoading || isLoadingRelatedReports || isLoadingReport || isLoadingCapitales

  React.useEffect(() => {
    if (isLoading) {
      return
    }

    if (report && expediente) {
      const isExpedienteClosed = expediente?.cerrado === 1
      const isEmpty = Object.keys(report).filter((key) => key?.toLowerCase() !== 'id')?.length === 0

      if (isExpedienteClosed && isEmpty) {
        return navigate(generatePath('/:reportId/closed', { reportId }))
      }

      if (isEmpty && relatedReports?.length !== 0) {
        return navigate(generatePath('/:reportId/clone', { reportId }))
      }
      navigate(generatePath('/creation/:reportId/c/r/informacion-general', { reportId }))
    }
  }, [navigate, isLoading, report, relatedReports?.length, expediente, reportId])

  if (isLoading) {
    return (
      <Loader>
        <Logo />
        <Text variant="black">Cargando...</Text>
      </Loader>
    )
  }

  if (!report) {
    return <ErrorPage message="No hemos podido recuperar el expediente en este momento" />
  }

  return (
    <Container className={className}>
      <Outlet />
    </Container>
  )
})
