import { useCallback } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { AiOutlineDelete, AiOutlineEdit, AiOutlineUsergroupAdd } from 'react-icons/ai'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import styled, { css, useTheme } from 'styled-components/macro'
import {
  Button,
  Dropdown,
  DropdownOption,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
} from '../../../../../../components'
import { Comments } from '../../../../../../components/molecules/comments/Comments'
import { useAutoSave } from '../../../../../../hooks/useAutoSave'
import { useIsClosed, useSaveReport, useStoredReport } from '../../../../../../modules/reports/hooks/useReports'
import { showBlockedReportdWarning } from '../../../../../../modules/reports/utils'
import { Headline } from '../../../components/headline'
import Actions from '../../components/actions/Actions'

const Container = styled.form`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const AddButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 0;
  height: initial;
  color: ${({ theme }) => theme.colors.secondary.main};
  gap: 6px;
  font-weight: 400;
`

const StyledDropdown = styled(Dropdown)`
  top: 60px;
  right: 20px;
  z-index: 99;
`

const StyledDropdownOption = styled(DropdownOption)`
  display: flex;
  align-items: center;
  gap: 6px;

  ${({ isDangerAction }) =>
    isDangerAction &&
    css`
      color: ${({ theme }) => theme.colors.negative.main};
    `}
`

const TableWrapper = styled.div`
  /* overflow: auto; */
`

const STEP = 'responsables'
export const ResponsablesScreen = () => {
  const navigate = useNavigate()
  const { reportId } = useParams()
  const isClosed = useIsClosed(reportId)
  const { data: report } = useStoredReport(reportId)
  const {
    control,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      responsables: report?.responsables ?? [],
    },
    mode: 'onBlur',
  })
  const { fields, remove } = useFieldArray({
    control,
    name: 'responsables',
  })
  const theme = useTheme()

  const handleRequestAddResponsable = useCallback(
    ({ initialData = {}, isEditionMode = false, idx }) => {
      navigate(generatePath('/creation/:reportId/c/r/responsables/add', { reportId }), {
        state: { initialData, isEditionMode, idx },
      })
    },
    [navigate, reportId],
  )

  const handleSaveReport = useSaveReport()

  const { saveData } = useAutoSave()

  const handleFormChange = useCallback(() => {
    if (isClosed) {
      showBlockedReportdWarning()
      return
    }

    saveData({ id: STEP, reportId, data: getValues() })
  }, [getValues, isClosed, reportId, saveData])

  const handleGoBack = useCallback(() => {
    handleSaveReport({ data: getValues() })
    navigate(-1)
  }, [handleSaveReport, getValues, navigate])

  const onSubmit = (values) => {
    if (isClosed) {
      navigate(generatePath('/creation/:reportId/c/r/perjudicados', { reportId }))
      return
    }
    return new Promise((resolve, reject) => {
      handleSaveReport({
        data: values,
        onSuccess: () => {
          resolve()
          navigate(generatePath('/creation/:reportId/c/r/perjudicados', { reportId }))
        },
        onError: () => {
          reject()
        },
      })
    })
  }

  return (
    <Container onChange={handleFormChange} onSubmit={handleSubmit(onSubmit)}>
      <Header>
        <Headline size="l" weight="500" variant="black">
          Responsables
        </Headline>

        <Text variant="black">
          Añade los datos de los responsables, recuerda que son distintos de los datos del asegurado.
        </Text>
      </Header>

      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Text variant="black" weight="500" size="sm">
                  Nombre
                </Text>
              </TableCell>

              <TableCell>
                <Text variant="black" weight="500" size="sm">
                  Teléfono
                </Text>
              </TableCell>

              <TableCell>
                <Text variant="black" weight="500" size="sm">
                  NIF
                </Text>
              </TableCell>

              <TableCell>
                <Text variant="black" weight="500" size="sm">
                  Domicilio
                </Text>
              </TableCell>

              <TableCell>
                <Text variant="black" weight="500" size="sm">
                  Aseguradora
                </Text>
              </TableCell>

              <TableCell>
                <Text variant="black" weight="500" size="sm">
                  Poliza
                </Text>
              </TableCell>

              <TableCell>
                <Text variant="black" weight="500" size="sm">
                  Expediente
                </Text>
              </TableCell>

              <TableCell>
                <Text variant="black" weight="500" size="sm">
                  Cuantía Asegurada
                </Text>
              </TableCell>

              <TableCell>
                <Text variant="black" weight="500" size="sm">
                  Asume daños
                </Text>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {fields.map((item, idx) => {
              return (
                <TableRow key={item.id}>
                  <TableCell>
                    <Text variant="black" size="sm">
                      {item.responsables_nombre}
                    </Text>
                  </TableCell>

                  <TableCell>
                    <Text variant="black" size="sm">
                      {item.responsables_telefono}
                    </Text>
                  </TableCell>

                  <TableCell>
                    <Text variant="black" size="sm">
                      {item.responsables_nif}
                    </Text>
                  </TableCell>

                  <TableCell>
                    <Text variant="black" size="sm">
                      {item.responsables_domicilioAfectado}
                    </Text>
                  </TableCell>

                  <TableCell>
                    <Text variant="black" size="sm">
                      {item.responsables_aseguradora}
                    </Text>
                  </TableCell>

                  <TableCell>
                    <Text variant="black" size="sm">
                      {item.responsables_poliza}
                    </Text>
                  </TableCell>

                  <TableCell>
                    <Text variant="black" size="sm">
                      {item.responsables_expediente}
                    </Text>
                  </TableCell>

                  <TableCell>
                    <Text variant="black" size="sm">
                      {item.responsables_cuantiaAsegurada}
                    </Text>
                  </TableCell>

                  <TableCell>
                    <Text variant="black" size="sm">
                      {item.responsables_asumeDanios ? 'Sí' : 'No'}
                    </Text>
                  </TableCell>

                  <TableCell>
                    {!isClosed && (
                      <StyledDropdown>
                        <StyledDropdownOption
                          onClick={() => handleRequestAddResponsable({ initialData: item, isEditionMode: true, idx })}
                        >
                          <AiOutlineEdit color={theme?.colors?.ntrl_dark?.main} size={20} />
                          Editar
                        </StyledDropdownOption>
                        <StyledDropdownOption isDangerAction onClick={() => remove(idx)}>
                          <AiOutlineDelete color={theme?.colors?.negative?.main} size={20} />
                          Eliminar
                        </StyledDropdownOption>
                      </StyledDropdown>
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
            <TableRow>
              {!isClosed && (
                <TableCell colSpan="9">
                  <AddButton type="button" variant="transparent" onClick={handleRequestAddResponsable}>
                    <AiOutlineUsergroupAdd size={20} color={theme?.colors?.secondary?.main} />
                    Añadir responsable
                  </AddButton>
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableWrapper>

      <Actions isSubmitting={isSubmitting} onBackClick={handleGoBack} />

      <Comments step={STEP} />
    </Container>
  )
}
