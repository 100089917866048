import ReactModal from 'react-modal'
import styled from 'styled-components/macro'

ReactModal.setAppElement('#root')

export const Modal = styled(ReactModal).attrs({
  closeTimeoutMS: 200,
  contentLabel: 'modal',
})`
  &&&.ReactModal__Content {
    width: 80%;
    height: 80%;
    overflow: visible;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 6px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    z-index: 999;
    outline: none;
  }
`
