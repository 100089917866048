import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

export const NavItemLi = styled.li`
  position: relative;
  list-style: none;
`

export const NavItemContent = styled.span`
  all: unset;
  position: relative;
  display: inline-block;
  width: 100%;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  box-sizing: border-box;

  ${({ theme, variant, $isActive, size = 'm' }) => css`
    color: ${theme.colors[variant]?.main ?? theme.colors.black.main};
    text-decoration-line: ${$isActive ? 'underline' : 'none'};
    font-weight: ${$isActive ? 700 : 400};

    &:hover {
      text-decoration-line: ${$isActive ? 'underline' : 'none'};
    }

    ${css(theme.components.navItem.sizes[size])};
  `};
`

export const NavItem = memo(function NavItem({
  variant = 'ntrl_darkest',
  children,
  to,
  onClick,
  size,
  isActive,
  ...rest
}) {
  const setHandleClick = () => {
    if (onClick) {
      return (e) => {
        onClick?.(e)
      }
    }
    return undefined
  }

  const shitchElement = () => {
    if (to) {
      return Link
    }

    if (onClick) {
      return 'button'
    }

    return 'span'
  }

  return (
    <NavItemLi {...rest}>
      <NavItemContent
        as={shitchElement()}
        to={to}
        size={size}
        $isActive={isActive}
        variant={variant}
        onClick={setHandleClick()}
      >
        {children}
      </NavItemContent>
    </NavItemLi>
  )
})
