export const SET_STATUS = 'SET_STATUS'
export const SET_RESULT = 'SET_RESULT'

export const STATUS = {
  CHECKING: 1,
  AUTHORIZED: 2,
  UNAUTHORIZED: 3,
}

export const initialState = {
  status: STATUS.CHECKING,
  user: null,
}

export const reducer = (prevState, { type, payload }) => {
  switch (type) {
    case SET_STATUS:
      return {
        ...prevState,
        status: payload.status,
      }

    case SET_RESULT:
      return {
        ...prevState,
        ...payload,
      }

    default:
      return prevState
  }
}
