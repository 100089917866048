import { transparentize } from 'polished'
import React from 'react'
import styled from 'styled-components/macro'
import { Headline } from '../../../../../screens/dashboard/creation/components/headline'
import Button from '../../../../atoms/button/Button'
import Text from '../../../../atoms/text/Text'

const SaveImageModalOverlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => transparentize(0.6, theme?.colors?.ntrl_lighter?.main)};
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`

const SaveImageModal = styled.div`
  width: 30%;
  aspect-ratio: 16 / 9;
  background-color: ${({ theme }) => theme?.colors?.white?.main};
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 6px;
  padding: 30px 24px;
  box-sizing: border-box;
`

const SaveImageModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const SaveImageModalActions = styled.div`
  display: flex;
  align-items: flex-end;
  align-self: flex-end;
  gap: 12px;
`

export const SaveConfirmModal = React.memo(({ onConfirm, onCancel }) => {
  return (
    <SaveImageModalOverlay>
      <SaveImageModal>
        <SaveImageModalHeader>
          <Headline size="l" weight="500" variant="black">
            ¿Estás seguro?
          </Headline>

          <Text variant="black">Si guardas la imagen no podrás volver a editar los gráficos que hayas añadido</Text>
        </SaveImageModalHeader>

        <SaveImageModalActions>
          <Button bordered type="button" variant="ntrl_dark" onClick={onCancel}>
            Cancelar
          </Button>

          <Button onClick={onConfirm}>Guardar</Button>
        </SaveImageModalActions>
      </SaveImageModal>
    </SaveImageModalOverlay>
  )
})
