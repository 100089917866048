import React, { memo } from 'react'
import { Icon } from '../icon/Icon'

const mapTypeWithIcon = [
  {
    name: 'video',
    condition: ({ type }) => type.includes('video'),
  },
  {
    name: 'picture',
    condition: ({ type }) => type.includes('image'),
  },
  {
    name: 'music',
    condition: ({ type }) => type.includes('audio'),
  },
  {
    name: 'file',
    condition: ({ type }) => type.includes('file') || type.includes('pdf'),
  },
]

export const getFileIcon = ({ type }) => mapTypeWithIcon.find((item) => item.condition({ type }))

export const SwitchFileIcon = memo(function SwitchFileIcon({ type, ...rest }) {
  const iconConf = getFileIcon({ type })

  if (!iconConf) {
    return null
  }

  return (
    <Icon {...rest} name={iconConf.name}>
      {type}
    </Icon>
  )
})
