import React, { createContext } from 'react'

export const BRUSHES = {
  CIRCLE: 'circle',
  RECTANGLE: 'rect',
  ARROW: 'arrow',
}

export const COLORS = ['#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC']

export const ImageEditorContext = createContext()

export const ImageEditorProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    (prevState, { type, payload }) => {
      switch (type) {
        case 'SET_SELECTED_ID':
          return {
            ...prevState,
            selectedId: payload.selectedId,
            selectedColor: payload.selectedColor ?? prevState.selectedColor,
            selectedStrokeWidth: payload.selectedStrokeWidth ?? prevState.selectedStrokeWidth,
          }

        case 'SET_SELECTED_BRUSH':
          return {
            ...prevState,
            selectedBrush: payload.selectedBrush,
          }

        case 'SET_SHAPES':
          return {
            ...prevState,
            shapes: payload.shapes,
          }

        case 'ADD_SHAPE':
          return {
            ...prevState,
            shapes: [...prevState.shapes, payload.shape],
            selectedBrush: null,
          }

        case 'DELETE_SELECTED_SHAPE':
          return {
            ...prevState,
            shapes: prevState.shapes.filter((shape) => shape.id !== prevState.selectedId),
            selectedId: null,
          }

        case 'UPDATE_SHAPE':
          return {
            ...prevState,
            shapes: prevState.shapes.map((shape) => {
              if (shape.id === payload.id) {
                return {
                  ...shape,
                  ...payload.props,
                }
              }
              return shape
            }),
          }

        case 'SET_SELECTED_COLOR':
          return {
            ...prevState,
            selectedColor: payload.selectedColor,
          }

        case 'SET_SELECTED_STROKE_WIDTH':
          return {
            ...prevState,
            selectedStrokeWidth: payload.selectedStrokeWidth,
          }

        case 'SAVE_IMAGE':
          return {
            ...prevState,
            selectedId: null,
            showSaveImageWarning: true,
          }

        case 'CANCEL_SAVE_IMAGE':
          return {
            ...prevState,
            showSaveImageWarning: false,
          }

        default:
          return prevState
      }
    },
    {
      selectedId: null,
      selectedBrush: null,
      selectedColor: COLORS[0],
      selectedStrokeWidth: 3,
      shapes: [],
      showSaveImageWarning: false,
    },
  )

  return <ImageEditorContext.Provider value={[state, dispatch]}>{children}</ImageEditorContext.Provider>
}

export function useImageEditor() {
  const context = React.useContext(ImageEditorContext)
  if (context === undefined) {
    throw new Error('useImageEditor must be used within a ImageEditorProvider')
  }
  return context
}

export default ImageEditorContext
