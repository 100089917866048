import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Editor, Text } from '../../../../../../components'
import { Comments } from '../../../../../../components/molecules/comments/Comments'
import { useAutoSave } from '../../../../../../hooks/useAutoSave'
import { useIsClosed, useSaveReport, useStoredReport } from '../../../../../../modules/reports/hooks/useReports'
import { showBlockedReportdWarning } from '../../../../../../modules/reports/utils'
import { Headline } from '../../../components/headline'
import Actions from '../../components/actions/Actions'

const Container = styled.form`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
`

const ItalicText = styled(Text)`
  font-style: italic;
  margin-bottom: 12px;
`

const STEP = 'descripcionSiniestro'
export const DescripcionScreen = () => {
  const navigate = useNavigate()
  const { reportId } = useParams()
  const isClosed = useIsClosed(reportId)
  const { data: report, isLoading: isLoadingReport } = useStoredReport(reportId)
  const {
    getValues,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      descripcionSiniestro_OrigenYCircunstancias: report?.descripcionSiniestro_OrigenYCircunstancias ?? '',
      descripcionSiniestro_DaniosReclamados: report?.descripcionSiniestro_DaniosReclamados ?? '',
      descripcionSiniestro_ConsideracionesPericiales: report?.descripcionSiniestro_ConsideracionesPericiales ?? '',
      descripcionSiniestro_Conclusiones: report?.descripcionSiniestro_Conclusiones ?? '',
      descripcionSiniestro_DescripcionRiesgo: report?.descripcionSiniestro_DescripcionRiesgo ?? '',
    },
    mode: 'onBlur',
  })

  const handleSaveReport = useSaveReport()

  const { saveData } = useAutoSave()

  const handleFormChange = useCallback(() => {
    if (isClosed) {
      showBlockedReportdWarning()
      return
    }

    saveData({ id: STEP, reportId, data: getValues() })
  }, [getValues, isClosed, reportId, saveData])

  const handleGoBack = useCallback(() => {
    handleSaveReport({ data: getValues() })
    navigate(-1)
  }, [handleSaveReport, getValues, navigate])

  const onSubmit = (values) => {
    if (isClosed) {
      navigate(generatePath('/creation/:reportId/c/r/analisis-de-capitales', { reportId }))
      return
    }

    return new Promise((resolve, reject) => {
      handleSaveReport({
        data: values,
        onSuccess: () => {
          resolve()
          navigate(generatePath('/creation/:reportId/c/r/analisis-de-capitales', { reportId }))
        },
        onError: () => {
          reject()
        },
      })
    })
  }

  const {
    descripcionSiniestro_OrigenYCircunstancias,
    descripcionSiniestro_DaniosReclamados,
    descripcionSiniestro_DescripcionRiesgo,
    descripcionSiniestro_ConsideracionesPericiales,
    descripcionSiniestro_Conclusiones,
  } = getValues()

  if (isLoadingReport) {
    return null
  }
  console.info(descripcionSiniestro_DescripcionRiesgo)
  return (
    <Container onChange={handleFormChange} onSubmit={handleSubmit(onSubmit)}>
      <Header>
        <Headline size="l" weight="500" variant="black">
          Descripción del siniestro
        </Headline>
      </Header>

      <Box>
        <Text variant="black" weight="600">
          Descripción del riesgo
        </Text>
        <ItalicText variant="black" weight="400" size="sm">
          Si no añades ningún texto, no aparecerá en el informe final
        </ItalicText>
        <Editor
          value={descripcionSiniestro_DescripcionRiesgo}
          onChange={(value) => setValue('descripcionSiniestro_DescripcionRiesgo', value)}
        />
      </Box>

      <Box>
        <Text variant="black" weight="600">
          Descripción del siniestro
        </Text>
        <ItalicText variant="black" weight="400" size="sm">
          Si no añades ningún texto, no aparecerá en el informe final
        </ItalicText>
        <Editor
          value={descripcionSiniestro_OrigenYCircunstancias}
          onChange={(value) => setValue('descripcionSiniestro_OrigenYCircunstancias', value)}
        />
      </Box>

      <Box>
        <Text variant="black" weight="600">
          Daños reclamados
        </Text>
        <ItalicText variant="black" weight="400" size="sm">
          Si no añades ningún texto, no aparecerá en el informe final
        </ItalicText>
        <Editor
          value={descripcionSiniestro_DaniosReclamados}
          onChange={(value) => setValue('descripcionSiniestro_DaniosReclamados', value)}
        />
      </Box>

      <Box>
        <Text variant="black" weight="600">
          Consideraciones periciales
        </Text>
        <ItalicText variant="black" weight="400" size="sm">
          Si no añades ningún texto, no aparecerá en el informe final
        </ItalicText>
        <Editor
          value={descripcionSiniestro_ConsideracionesPericiales}
          onChange={(value) => setValue('descripcionSiniestro_ConsideracionesPericiales', value)}
        />
      </Box>

      <Box>
        <Text variant="black" weight="600">
          Conclusiones
        </Text>
        <ItalicText variant="black" weight="400" size="sm">
          Si no añades ningún texto, no aparecerá en el informe final
        </ItalicText>
        <Editor
          value={descripcionSiniestro_Conclusiones}
          onChange={(value) => setValue('descripcionSiniestro_Conclusiones', value)}
        />
      </Box>

      <Actions isSubmitting={isSubmitting} onBackClick={handleGoBack} />

      <Comments step={STEP} />
    </Container>
  )
}
