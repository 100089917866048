import qs from 'qs'
import url from 'url'
import RequestHandler from '../request/index'

class ReportsAPI {
  static getReports() {
    const { fetch, generateHeaders } = RequestHandler.sharedInstance()

    return fetch('expedientes', {
      method: 'GET',
      headers: generateHeaders({ headers: {} }),
    })
  }

  static getReport({ reportId }) {
    const { fetch, generateHeaders } = RequestHandler.sharedInstance()

    return fetch(`expedientes/${reportId}`, {
      method: 'GET',
      headers: generateHeaders({ headers: {} }),
    })
  }

  static getRelatedReports({ reportId }) {
    const { fetch, generateHeaders } = RequestHandler.sharedInstance()

    return fetch(`expedientes/${reportId}/related`, {
      method: 'GET',
      headers: generateHeaders({ headers: {} }),
    })
  }

  static getAnalisisCapitales({ reportId }) {
    const { fetch, generateHeaders } = RequestHandler.sharedInstance()

    return fetch(`expedientes/${reportId}/analisisCapitales`, {
      method: 'GET',
      headers: generateHeaders({ headers: {} }),
    })
  }

  static getCapitales({ reportId }) {
    const { fetch, generateHeaders } = RequestHandler.sharedInstance()

    return fetch(`expedientes/${reportId}/capitales`, {
      method: 'GET',
      headers: generateHeaders({ headers: {} }),
    })
  }

  static updateCapitales({ reportId, capitales }) {
    const { fetch, generateHeaders } = RequestHandler.sharedInstance()

    return fetch(`expedientes/${reportId}/capitales`, {
      method: 'PUT',
      headers: generateHeaders({ headers: {} }),
      body: { capitales },
    })
  }

  static generateCaserReport({ report, extended }) {
    const { fetch, generateHeaders } = RequestHandler.sharedInstance()

    const formData = new FormData()
    Object.keys(report).forEach((key) => {
      const value = report[key]
      if (key === 'attachments') {
        for (let i = 0; i < value.length; i++) {
          formData.append('attachments[]', value[i], value[i].name)
        }
      } else if (typeof value === 'object') {
        formData.append(key, JSON.stringify(value))
      } else {
        formData.append(key, value)
      }
    })

    return fetch(`reports/caser?${qs.stringify({ extended: extended ? 1 : 0 })}`, {
      method: 'POST',
      headers: generateHeaders({ headers: {}, useDefaultHeaders: false }),
      body: formData,
    })
  }

  static getStoredReport({ reportId }) {
    const { fetch, generateHeaders } = RequestHandler.sharedInstance()

    return fetch(`expedientes/${reportId}/reportes`, {
      method: 'GET',
      headers: generateHeaders({ headers: {} }),
    })
  }

  static updateStoredReport({ report }) {
    const { fetch, generateHeaders } = RequestHandler.sharedInstance()

    return fetch(`expedientes/${report.id}/reportes`, {
      method: 'PUT',
      headers: generateHeaders({ headers: {} }),
      body: report,
    })
  }

  static patchStoredReport({ report }) {
    const { fetch, generateHeaders } = RequestHandler.sharedInstance()

    return fetch(`expedientes/${report.id}/reportes`, {
      method: 'PATCH',
      headers: generateHeaders({ headers: {} }),
      body: report,
    })
  }

  static getStoredComments({ reportId }) {
    const { fetch, generateHeaders } = RequestHandler.sharedInstance()

    return fetch(`expedientes/${reportId}/comentarios`, {
      method: 'GET',
      headers: generateHeaders({ headers: {} }),
    })
  }

  static updateStoredComments({ reportId, comments }) {
    const { fetch, generateHeaders } = RequestHandler.sharedInstance()

    return fetch(`expedientes/${reportId}/comentarios`, {
      method: 'PUT',
      headers: generateHeaders({ headers: {} }),
      body: comments,
    })
  }

  static getStoredAnexos({ reportId }) {
    const { fetch, generateHeaders } = RequestHandler.sharedInstance()

    return fetch(`expedientes/${reportId}/anexos`, {
      method: 'GET',
      headers: generateHeaders({ headers: {} }),
    })
  }

  static updateAnexos({ reportId, anexos }) {
    const { fetch, generateHeaders } = RequestHandler.sharedInstance()

    return fetch(`expedientes/${reportId}/anexos`, {
      method: 'PUT',
      headers: generateHeaders({ headers: {} }),
      body: anexos,
    })
  }

  static removeStoredAttachment({ reportId, key, attachmentId }) {
    const { fetch, generateHeaders } = RequestHandler.sharedInstance()

    return fetch(`expedientes/${reportId}/anexos/${key}/${attachmentId}`, {
      method: 'DELETE',
      headers: generateHeaders({ headers: {} }),
    })
  }

  static updateStoredAttachment({ reportId, key, attachmentId, caption }) {
    const { fetch, generateHeaders } = RequestHandler.sharedInstance()

    return fetch(`expedientes/${reportId}/anexos/${key}/${attachmentId}`, {
      method: 'PUT',
      headers: generateHeaders({ headers: {} }),
      body: { caption },
    })
  }

  static addAnexos({ reportId, attachments = [], photos = [], attachmentsCaptions = [], photosCaptions = [] }) {
    const { fetch, generateHeaders } = RequestHandler.sharedInstance()

    const formData = new FormData()
    for (let i = 0; i < attachments.length; i++) {
      formData.append('attachments[]', attachments[i].file, attachments[i].file.name)
    }
    for (let i = 0; i < photos.length; i++) {
      formData.append('photos[]', photos[i].file, photos[i].file.name)
    }

    formData.append('attachmentsCaptions', JSON.stringify(attachmentsCaptions))
    formData.append('photosCaptions', JSON.stringify(photosCaptions))

    return fetch(`expedientes/${reportId}/anexos`, {
      method: 'POST',
      headers: generateHeaders({ headers: {}, useDefaultHeaders: false }),
      body: formData,
    })
  }

  static updateAnexosOrder({ reportId, key, order }) {
    const { fetch, generateHeaders } = RequestHandler.sharedInstance()

    return fetch(`expedientes/${reportId}/anexos/${key}/order`, {
      method: 'PUT',
      headers: generateHeaders({ headers: {} }),
      body: { order },
    })
  }

  static updatePhoto({ reportId, attachmentId, photo }) {
    const { fetch, generateHeaders } = RequestHandler.sharedInstance()

    const formData = new FormData()
    formData.append('photo', photo)

    return fetch(`expedientes/${reportId}/anexos/photos/${attachmentId}`, {
      method: 'PUT',
      headers: generateHeaders({ headers: {}, useDefaultHeaders: false }),
      body: formData,
    })
  }

  static getReportStatus({ reportId }) {
    const { fetch, generateHeaders } = RequestHandler.sharedInstance()

    return fetch(`expedientes/${reportId}/status`, {
      method: 'GET',
      headers: generateHeaders({ headers: {} }),
    })
  }

  static closeReport({ reportId }) {
    const { fetch, generateHeaders } = RequestHandler.sharedInstance()

    return fetch(`expedientes/${reportId}/status/close`, {
      method: 'PUT',
      headers: generateHeaders({ headers: {} }),
    })
  }

  static openReport({ reportId }) {
    const { fetch, generateHeaders } = RequestHandler.sharedInstance()

    return fetch(`expedientes/${reportId}/status/open`, {
      method: 'PUT',
      headers: generateHeaders({ headers: {} }),
    })
  }

  static downloadReport({ reportId, options }) {
    const { _authtoken } = RequestHandler.sharedInstance()
    const tab = window.open(
      url.resolve(
        window.MontesPeritos.config.REACT_APP_BASE_HOST,
        `expedientes/${reportId}/reportes?${qs.stringify({
          pages: {
            ...(options ?? {}),
          },
          format: 'pdf',
          access_token: _authtoken,
        })}`,
      ),
      '_blank',
      'noopener,noreferrer',
    )
    if (!tab) {
      return
    }

    tab.focus()
    tab.onblur = function () {
      tab?.close()
    }
  }

  static cloneReport({ from, to }) {
    const { fetch, generateHeaders } = RequestHandler.sharedInstance()

    return fetch(`expedientes/${to}/clone`, {
      method: 'PUT',
      headers: generateHeaders({ headers: {} }),
      body: {
        from,
      },
    })
  }

  static getData({ reportId, key }) {
    const { fetch, generateHeaders } = RequestHandler.sharedInstance()

    return fetch(`expedientes/${reportId}/data/${key}`, {
      method: 'GET',
      headers: generateHeaders({ headers: {} }),
    })
  }

  static addData({ reportId, key, value }) {
    const { fetch, generateHeaders } = RequestHandler.sharedInstance()

    return fetch(`expedientes/${reportId}/data/${key}`, {
      method: 'POST',
      headers: generateHeaders({ headers: {} }),
      body: value,
    })
  }

  static editData({ reportId, key, subkey, value }) {
    const { fetch, generateHeaders } = RequestHandler.sharedInstance()

    return fetch(`expedientes/${reportId}/data/${key}/${subkey}`, {
      method: 'PUT',
      headers: generateHeaders({ headers: {} }),
      body: {
        value,
      },
    })
  }

  static deleteData({ reportId, key, subkey }) {
    const { fetch, generateHeaders } = RequestHandler.sharedInstance()

    return fetch(`expedientes/${reportId}/data/${key}/${subkey}`, {
      method: 'DELETE',
      headers: generateHeaders({ headers: {} }),
    })
  }

  static deleteAllData({ reportId, key }) {
    const { fetch, generateHeaders } = RequestHandler.sharedInstance()

    return fetch(`expedientes/${reportId}/data/${key}`, {
      method: 'DELETE',
      headers: generateHeaders({ headers: {} }),
    })
  }
}

export default ReportsAPI
