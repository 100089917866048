import { useCallback, useMemo, useState } from 'react'

export const usePagination = (data, options) => {
  const [page, setPage] = useState(0)

  const handlePageChange = useCallback((page) => {
    setPage(page)
  }, [])

  const paginatedData = useMemo(() => {
    return data.slice(page * options?.itemsPerPage, page * options?.itemsPerPage + options?.itemsPerPage)
  }, [page, data, options?.itemsPerPage])

  const emptyRows = useMemo(() => {
    return page > 0 ? Math.max(0, (1 + page) * options?.itemsPerPage - data.length) : 0
  }, [data.length, options?.itemsPerPage, page])

  return {
    paginatedData,
    emptyRows,
    currentPage: page,
    onPageChange: handlePageChange,
  }
}
