import styled from 'styled-components/macro'
import { Modal } from './Modal'
import { Headline } from '../../../screens/dashboard/creation/components/headline'
import Text from '../../atoms/text/Text'

const ConfirmModal = styled(Modal)`
  &&&.ReactModal__Content {
    width: 30%;
    aspect-ratio: 16 / 9;
    height: auto;
  }
`

const ConfirmModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  box-sizing: border-box;
  height: 100%;
`

export const ConfirmationModal = ({ children, ...props }) => {
  return (
    <ConfirmModal {...props}>
      <ConfirmModalContent>{children}</ConfirmModalContent>
    </ConfirmModal>
  )
}

ConfirmationModal.Title = (props) => {
  return <Headline size="l" weight="500" variant="black" {...props} />
}

ConfirmationModal.Subtitle = (props) => {
  return <Text variant="black" {...props} />
}

ConfirmationModal.Actions = styled.div`
  display: flex;
  align-items: flex-end;
  align-self: flex-end;
  gap: 12px;
  flex: 1;
`
