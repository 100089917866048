import { useCallback } from 'react'

import '@ckeditor/ckeditor5-build-classic/build/translations/es'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import CKEditorBuild from 'ckeditor5-custom-build/build/ckeditor'
import { GlobalStyle } from './styles'

export const Editor = ({
  className,
  placeholder = 'Escribe aquí...',
  disablePlugins = false,
  autoFocus = false,
  value,
  onChange,
}) => {
  const handleChange = useCallback(
    (_, editor) => {
      onChange?.(editor.getData())
    },
    [onChange],
  )

  return (
    <>
      <GlobalStyle />

      <CKEditor
        config={{
          language: 'es',
          placeholder,
          removePlugins: ['Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed'],
        }}
        editor={CKEditorBuild}
        data={value}
        onChange={handleChange}
      />
    </>
  )
}
