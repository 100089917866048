import React from 'react'
import styled from 'styled-components'
import Text from '../text/Text'

const Container = styled.article`
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding: 12px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.white.main};
  border: ${({ theme }) => `solid 1px ${theme.colors.ntrl_light.main}`};
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transform: translateY(-4px);
  }
`

const Card = ({ className, variant, children, onClick }) => {
  const element = typeof onClick === 'function' ? 'button' : 'article'
  return (
    <Container className={className} as={element} type={element === 'button' ? 'button' : null} onClick={onClick}>
      {children}
    </Container>
  )
}

Card.Title = styled(Text).attrs({ as: 'h2' })`
  font-family: 'Karla';
  letter-spacing: 0.8px;
  margin: 0;
  color: ${({ theme }) => theme.colors.primary.main};
`
Card.Body = styled(Text).attrs({ variant: 'ntrl_darkest', weight: 500 })``

Card.Actions = styled.div`
  display: flex;
  flex: 1;
  gap: 12px;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 12px;
`

export default Card
