import React from 'react'
import { useLocation } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import { TaskManager } from '../../modules/taskManager'

const AutoSaveContext = React.createContext()

export const AutoSaveProvider = ({ interval = 5000, maxWait = 10000, children }) => {
  const [isSaving, setIsSaving] = React.useState(false)
  const location = useLocation()

  const flushData = useDebouncedCallback(
    async () => {
      console.info('flushData')
      setIsSaving((prev) => !prev)
      await TaskManager.compileTasks()
      setIsSaving((prev) => !prev)
    },
    interval,
    { maxWait: maxWait },
  )

  const saveData = React.useCallback(
    ({ id, reportId, data }) => {
      TaskManager.addTasks({ id, reportId, data })
      flushData()
    },
    [flushData],
  )

  React.useEffect(() => {
    // saveData?.cancel?.()
  }, [location.pathname, saveData])

  return <AutoSaveContext.Provider value={{ isSaving, saveData }}>{children}</AutoSaveContext.Provider>
}

export const useAutoSave = () => {
  const context = React.useContext(AutoSaveContext)

  if (context === undefined) {
    throw new Error('useAutoSave must be used within a AutoSaveProvider')
  }

  return context
}
