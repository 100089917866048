import Text from '../../../components/atoms/text/Text'
import { schema } from './schema'
import { toast } from 'react-toastify'
import { AuthLayout } from '../components/authLayout/AuthLayout'
import { Logo, Box, Input, Button } from '../../../components'
import styled from 'styled-components/macro'
import { DEVICE_SIZE } from '../../../theme/theme'
import { useAuth } from '../../../modules/auth'
import { memo, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

const Container = styled(Box)`
  height: 100%;
  box-sizing: border-box;
  padding: 40px;

  @media (max-width: ${DEVICE_SIZE.MOBILE_L}) {
    padding: 24px;
  }
`

const Content = styled(Box)`
  flex: 1;
  width: 100%;
  max-width: 338px;
  overflow: auto;

  @media (max-width: ${DEVICE_SIZE.MOBILE_L}) {
    justify-content: flex-start;
  }
`

const StyledLogo = styled(Logo)`
  margin-bottom: 12px;
`

const Headline = styled(Text)`
  margin-bottom: 32px;
`

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const SubmitButton = styled(Button)`
  align-self: flex-start;
  margin-top: 8px;

  @media (max-width: ${DEVICE_SIZE.MOBILE_L}) {
    width: 100%;
  }
`

const LoginScreen = () => {
  const auth = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || '/'

  const { register, getValues, formState, handleSubmit } = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  })

  const onSubmit = useCallback(
    async (values) => {
      try {
        await auth.login(values)
        navigate(from, { replace: true })
      } catch {
        toast.error('Oops! No hemos podido acceder a la plataforma. Revisa tus credenciales e inténtalo de nuevo')
      }
    },
    [auth, from, navigate],
  )

  const { isSubmitting, isValid, errors, touchedFields } = formState
  const hasUsername = !!getValues('username')
  const hasPassword = !!getValues('password')

  return (
    <AuthLayout>
      <Container direction="column" hAlign="center">
        <Content direction="column" vAlign="center">
          <StyledLogo size="l" />

          <Headline size="ml" variant="black">
            Entra en la plataforma
          </Headline>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <Input
              {...register('username')}
              label="Usuario"
              placeholder="Escribe tu usuario"
              type="text"
              hasError={hasUsername && touchedFields?.username && errors?.username}
              errorMessage={errors?.username}
            />

            <Input
              {...register('password')}
              label="Contraseña"
              placeholder="Escribe tu contraseña"
              type="password"
              hasError={hasPassword && touchedFields?.password && errors?.password}
              errorMessage={errors?.password}
            />

            <SubmitButton type="submit" disabled={!isValid || isSubmitting}>
              Entrar
            </SubmitButton>
          </Form>
        </Content>
      </Container>
    </AuthLayout>
  )
}

export default memo(LoginScreen)
