import React, { memo } from 'react'
import styled from 'styled-components/macro'
import { Badge, Link } from '../../../../../../../../components'

const StyledLi = styled.li`
  list-style: none;
  cursor: pointer;
`

export const SidebarLink = memo(({ children, count, to, isActive, external, ...props }) => {
  return (
    <StyledLi {...props}>
      <Badge count={count} inline>
        <Link to={to} $isActive={isActive} external={external}>
          {children}
        </Link>
      </Badge>
    </StyledLi>
  )
})

SidebarLink.displayName = 'SidebarLink'
