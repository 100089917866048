import React from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components/macro'
import { Headline } from '../../../screens/dashboard/creation/components/headline'
import Button from '../../atoms/button/Button'
import { Input } from '../../atoms/input/Input'
import Text from '../../atoms/text/Text'
import { DateTime } from 'luxon'
import { useUpdateReportMutation } from '../../../modules/reports/mutations'
import { useParams } from 'react-router-dom'
import { useStoredReport } from '../../../modules/reports/hooks/useReports'

const Container = styled.form`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
`

const Content = styled.article`
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: visible;
`

const Header = styled.div``

const StyledInput = styled(Input)`
  width: 260px;
`

const Actions = styled.nav`
  align-self: flex-end;
  margin-top: 24px;
  display: flex;
  gap: 12px;
`

export const CloseReportModal = React.memo(({ onAccept, onCancel }) => {
  const { reportId } = useParams()
  const { data: report } = useStoredReport(reportId)
  const { register, watch, handleSubmit } = useForm({
    defaultValues: {
      FechaInforme: DateTime.local().toFormat('dd/LL/yyyy') ?? '',
    },
    mode: 'onBlur',
  })
  const watchFecha = watch('FechaInforme')
  const updateStoredReportMutation = useUpdateReportMutation()

  const onSubmit = (values) => {
    updateStoredReportMutation.mutate(
      {
        ...report,
        ...values,
      },
      {
        onSuccess: () => {
          onAccept?.()
        },
        onError: () => {
          console.info('error')
        },
      },
    )
  }

  return (
    <Container
      onSubmit={(evt) => {
        evt.stopPropagation()
        handleSubmit(onSubmit)(evt)
      }}
    >
      <Content>
        <Header>
          <Headline size="l" weight="500" variant="black">
            Vas a bloquear el expediente
          </Headline>

          <Text variant="black">
            A partir de ahora no podrás modificar los datos de este expediente. ¿Quieres continuar?
          </Text>
        </Header>

        <StyledInput {...register('FechaInforme')} label="Fecha del informe" placeholder="Escribe aquí" type="text" />
      </Content>
      <Actions>
        <Button bordered type="button" variant="ntrl_dark" onClick={onCancel}>
          Cancelar
        </Button>
        <Button type="submit" disabled={!watchFecha}>
          Bloquear expediente
        </Button>
      </Actions>
    </Container>
  )
})
